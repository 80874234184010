import { Col } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { ReactComponent as PeopleGatheringInsideAnOfficeHavingTheTimeOfTheirLives } from '@clarity-website/assets/svg/people-gathering-inside-an-office-having-the-time-of-their-lives.svg';
import {
  SupportedGraphicalErrors,
  shouldRenderGraphicalErrorPage,
} from '@clarity-website/reports/report-utils';
import { ReportTemplate } from '@clarity-website/reports/useFetchRawReport';

interface Props {
  reportTemplate: ReportTemplate | undefined;
  noRowsError: boolean | undefined;
}

const HEADER_COPY = {
  [SupportedGraphicalErrors.INCONSISTENT_BADGER_TABLE]:
    'No Inconsistent Badgers!',
  [SupportedGraphicalErrors.ZERO_BADGER_TABLE]: 'No Zero Badgers!',
};

const BODY_STRONG_COPY = {
  [SupportedGraphicalErrors.INCONSISTENT_BADGER_TABLE]: 'Inconsistent Badgers',
  [SupportedGraphicalErrors.ZERO_BADGER_TABLE]: 'Zero Badgers',
};

export default function GraphicalErrorPage({
  reportTemplate,
  noRowsError,
}: Props) {
  const { whichGraphicalErrorToShow } = shouldRenderGraphicalErrorPage(
    reportTemplate,
    noRowsError,
  );

  if (whichGraphicalErrorToShow) {
    return (
      <Col
        alignItems="center"
        justifyContent="center"
        height={800}
        backgroundColor="primary10"
        gridGap="S700"
        data-testid="graphical-error-page"
        data-which-graphical-error={whichGraphicalErrorToShow}
      >
        <Col gridGap="S100" alignItems="center">
          <Text fontSize="T500">{HEADER_COPY[whichGraphicalErrorToShow]}</Text>
          <Text fontSize="T300">
            You have no{' '}
            <strong>{BODY_STRONG_COPY[whichGraphicalErrorToShow]}</strong> for
            your applied filters in this timespan
          </Text>
        </Col>
        <Col>
          <PeopleGatheringInsideAnOfficeHavingTheTimeOfTheirLives />
        </Col>
      </Col>
    );
  }
  return null;
}
