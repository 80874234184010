import { prefetchAppConfiguration } from '@clarity-website/app/ConfigurationContext';
import useAppConfig from '@clarity-website/config/useAppConfig';
import useDomainConfigs from '@clarity-website/config/useDomainConfigs';
import useClarityClient from '@clarity-website/lib/clients/useClarityClient';
import {
  prefetchAttsAndMets,
  prefetchFavoriteReports,
} from '@clarity-website/reports/ReportStateHooks';
import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
// Optimistically fetch data for users
export default function usePrefetchData() {
  const queryClient = useQueryClient();
  const clarityClient = useClarityClient();
  const { data: config } = useAppConfig();
  const { data: domainConfigs } = useDomainConfigs();
  const stage = config?.Stage.toLowerCase() || 'test';

  useEffect(() => {
    prefetchAppConfiguration(queryClient, clarityClient, stage);
    prefetchAttsAndMets(queryClient, domainConfigs);
    prefetchFavoriteReports(queryClient);
  }, [queryClient, clarityClient, stage, domainConfigs]);
}
