import {
  DomainConfigApiResponse,
  DomainConfigs,
  createDomainConfigs,
} from '@amzn/pi-clarity-common/config/domain-configs';
import ClarityClient from '@clarity-website/lib/clients/ClarityClient';
import useClarityClient from '@clarity-website/lib/clients/useClarityClient';
import { oneDayInMs } from '@clarity-website/utils/cache-time-constants';
import { useQuery } from '@tanstack/react-query';

const configKey = 'getDomainConfigsKey';
export const MAINTENANCE_SERVICE_GET_DOMAIN_CONFIGS_PATH =
  '/get-domain-configs';

export const getDomainConfigs = (
  maintenanceClient: ClarityClient = new ClarityClient(),
): Promise<DomainConfigs> =>
  maintenanceClient
    .rest<DomainConfigApiResponse>({
      path: MAINTENANCE_SERVICE_GET_DOMAIN_CONFIGS_PATH,
    })
    .then(({ items }) => {
      return createDomainConfigs(items);
    })
    .catch((error) => {
      console.error(error);
      return createDomainConfigs();
    });

export default function useDomainConfigs() {
  const maintenanceClient = useClarityClient();
  return useQuery([configKey], () => getDomainConfigs(maintenanceClient), {
    cacheTime: oneDayInMs,
  });
}
