export const serializeArrayValues = (attr: any) => {
  if (!attr) return attr;
  return Object.entries(attr).reduce((acc: { [key: string]: any }, [k, v]) => {
    if (Array.isArray(v)) {
      acc[k] = JSON.stringify(v);
    } else {
      acc[k] = v;
    }
    return acc;
  }, {});
};
