export const ReauthorizeExceptions = {
  ExpiredTokenException: 'ExpiredTokenException',
};

export const RetryableAmplifyErrors = {
  TooManyRequestsException: 'TooManyRequestsException',
};

export class CustomError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = this.constructor.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }
  }
}
export class FetchNetworkError extends CustomError {
  constructor(public status: number, public statusText: string) {
    super(statusText);
    this.status = status;
    this.statusText = statusText;
  }
}

export class InternalError extends CustomError {
  constructor(public status: number, public statusText: string) {
    super(statusText);
    this.status = status;
    this.statusText = statusText;
  }
}
export class NoRetryError extends CustomError {}
export class TimeoutError extends CustomError {
  constructor() {
    super('request has timed out.');
  }
}

export interface AmplifyAuthError extends Error {
  code: string;
}

export const isAmplifyError = (
  error: AmplifyAuthError | unknown,
): error is AmplifyAuthError => {
  const coercedError = error as AmplifyAuthError;
  return coercedError.code !== undefined;
};
