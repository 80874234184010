import ClarityClient from '@clarity-website/lib/clients/ClarityClient';
import gql from 'graphql-tag.macro';

const getUISettingQuery = gql`
  query getUISetting($key: String!) {
    getUISetting(key: $key)
  }
`;

const setUISettingQuery = gql`
  mutation setUISetting($key: String!, $setting: UISettingInput!) {
    setUISetting(key: $key, setting: $setting)
  }
`;

export enum PreferenceType {
  String = 'STRING',
  Float = 'FLOAT',
  Boolean = 'BOOLEAN',
}

export const getUISetting = (client: ClarityClient, uiKey: string) => {
  return client
    .gql({
      request: getUISettingQuery,
      variables: { key: uiKey },
    })
    .then((res) => res.getUISetting);
};

function createSetUISettingFn(preferenceType: string) {
  return function (
    client: ClarityClient,
    uiKey: string,
    setting: string | number | boolean | undefined,
  ) {
    return client.gql({
      request: setUISettingQuery,
      variables: {
        key: uiKey,
        setting: {
          preferenceType,
          ...(preferenceType === PreferenceType.String && {
            stringPreference: setting,
          }),
          ...(preferenceType === PreferenceType.Float && {
            floatPreference: setting,
          }),
          ...(preferenceType === PreferenceType.Boolean && {
            booleanPreference: setting,
          }),
        },
      },
    });
  };
}

export const setStringUISetting = createSetUISettingFn(PreferenceType.String);

export const setNumberUISetting = createSetUISettingFn(PreferenceType.Float);
