import leaderBenchmarkDictionary from '@clarity-website/science/benchmark/leader-benchmark-dictionary';
import leaderOdmDictionary from '@clarity-website/science/odm/leader-odm-dictionary';

export interface AppConfig {
  logLevel?: string;
  attributeLogicalNameToId: { [id: string]: string };
  BannerEndpoint?: {
    url: string;
    region: string;
  };
  NotificationEndpoint: { url: string; region: string };
  ScienceEndpoint: { url: string };
  ClarityAssist: {
    region: string;
    url: string;
  };
  Stage: string;
  MaintenancePortal: { userGroup: string[] };
  ViewAs: {
    ldapGroups: string[];
    enabled: boolean;
  };
  Analytics?: {
    countriesToDisable: string[];
    CustomizedPinpoint: {
      region: string;
      appId: string;
      roleSessionName: string;
      roleArn: string;
      durationSeconds: number;
    };
  };
  PXTTelemetry?: {
    endpoint: string;
    namespace: string;
  };
  RumConfig?: {
    endpoint: string;
    applicationId: string;
    applicationRegion: string;
  };
  Xray?: {
    enabled: boolean;
    enableLogging?: boolean;
    config: {
      region: string;
      serviceId: string;
      maxAttempts?: number;
    };
  };
  Auth: {
    identityPoolId: string;
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    mandatorySignIn: boolean;
    oauth: {
      redirectSignIn: string;
      redirectSignOut: string;
      domain: string;
      scope: string[];
      responseType: string;
    };
    cookieStorage: {
      domain: string;
      path: string;
      expires: number;
      secure: boolean;
    };
  };
  scienceConfig?: {
    leaderOdmMetrics: { [key: string]: string };
    leaderOdmAttributes: { [key: string]: string };
    leaderBenchmarkMetrics: string[];
  };
  leaderSearchAttrId: string;
  reportDownloadDisclaimerVersion: number;
  voaSentimentMetrics: string[];
  rowLevelGroupByAttrIds: string[];
  differentNoRowErrorAttrIds: string[];
  OfflineDownloads: {
    baseUrl: string;
  };
}

const stageConfig: Record<string, Record<string, AppConfig>> = {
  beta: {
    'us-west-2': {
      logLevel: 'ERROR',
      RumConfig: {
        endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
        applicationId: '42cb28e3-a8bb-47de-a649-d8063d8a5cc0',
        applicationRegion: 'us-west-2',
      },
      Auth: {
        identityPoolId: 'us-west-2:2ca4ba12-cd3f-4da4-8db8-0240efdb6fba',
        region: 'us-west-2',
        userPoolId: 'us-west-2_BjlqEJrdc',
        userPoolWebClientId: '1glbqmksc03pg9j1nk859tfset',
        mandatorySignIn: true,
        oauth: {
          redirectSignIn: 'https://beta.clarity.people-insight.a2z.com/',
          redirectSignOut: 'https://beta.clarity.people-insight.a2z.com/',
          domain:
            '145279382993-us-west-2-clarity.auth.us-west-2.amazoncognito.com',
          scope: ['email', 'profile', 'openid'],
          responseType: 'code',
        },
        cookieStorage: {
          domain: '.beta.clarity.people-insight.a2z.com',
          path: '/',
          expires: 365,
          secure: true,
        },
      },
      Analytics: {
        countriesToDisable: ['DEU'],
        CustomizedPinpoint: {
          region: 'us-west-2',
          appId: 'e80a7859b52b44e7982ed54a6bb7b129',
          roleSessionName: 'clarityClickStream',
          roleArn:
            'arn:aws:iam::969510070238:role/PiDevMetricsLogIngestionL-ClarityPinpointAccessRol-12S4FEZ4V5ICS',
          durationSeconds: 3600,
        },
      },
      PXTTelemetry: {
        endpoint: 'https://gamma.telemetry.pxt.amazon.dev/',
        namespace: 'clarity-beta',
      },
      Xray: {
        enabled: true,
        enableLogging: false,
        config: {
          region: 'us-west-2',
          serviceId: 'clarity-frontend',
        },
      },
      MaintenancePortal: {
        userGroup: ['clarity-beta-mpuser'],
      },
      ViewAs: {
        enabled: true,
        ldapGroups: ['clarity-dev-view-as-user'],
      },
      ClarityAssist: {
        region: 'us-west-2',
        url: 'https://beta.clarity-assist.peopleinsight.amazon.dev/graphql',
      },
      Stage: 'Beta',
      BannerEndpoint: {
        url: 'https://pcyi6wtvkjdrhm2mcpegmly56y.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
      },
      NotificationEndpoint: {
        url: 'https://lkvavn53pzakno4gezhoqu747q.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
      },
      ScienceEndpoint: {
        url: 'https://beta.ds2.peopleinsight.amazon.dev',
      },
      attributeLogicalNameToId: {
        locationRegion: 'A0005',
        locationBusinessLine: 'A0002',
        locationOrg: 'A0003',
        locationSite: 'A0006',
        locationGeo: 'A0001',
      },
      leaderSearchAttrId: 'A_1647367982_6',
      reportDownloadDisclaimerVersion: 2,
      voaSentimentMetrics: [
        'M1686773611', //VOA negative comments %
        'M1686773536', //VOA neutral comments %
        'M1686773450', //VOA positive comments %
        'M1686773336', //VOA neutral comments count
        'M1686773266', //VOA negative comments count
        'M1686773194', //VOA positive comments count
        'M0290', //VOA total comments count (updated name from VOA Total Items)
      ],
      rowLevelGroupByAttrIds: ['A1683588817'],
      differentNoRowErrorAttrIds: [],
      scienceConfig: {
        leaderOdmMetrics: leaderOdmDictionary.ODM_LEADER_METRIC_DICTIONARY_BETA,
        leaderOdmAttributes:
          leaderOdmDictionary.ODM_LEADER_ATTRIBUTE_DICTIONARY_BETA,
        leaderBenchmarkMetrics:
          leaderBenchmarkDictionary.BENCHMARK_LEADER_METRIC_DICTIONARY_BETA,
      },
      OfflineDownloads: {
        baseUrl:
          'https://offline-downloads.beta.clarity.people-insight.a2z.com/download-report/',
      },
    },
    'us-east-1': {
      logLevel: 'ERROR',
      Auth: {
        identityPoolId: 'us-west-2:2ca4ba12-cd3f-4da4-8db8-0240efdb6fba',
        region: 'us-west-2',
        userPoolId: 'us-west-2_BjlqEJrdc',
        userPoolWebClientId: '1glbqmksc03pg9j1nk859tfset',
        mandatorySignIn: true,
        oauth: {
          redirectSignIn: 'https://beta.clarity.people-insight.a2z.com/',
          redirectSignOut: 'https://beta.clarity.people-insight.a2z.com/',
          domain:
            '145279382993-us-west-2-clarity.auth.us-west-2.amazoncognito.com',
          scope: ['email', 'profile', 'openid'],
          responseType: 'code',
        },
        cookieStorage: {
          domain: '.beta.clarity.people-insight.a2z.com',
          path: '/',
          expires: 365,
          secure: true,
        },
      },
      Analytics: {
        countriesToDisable: ['DEU'],
        CustomizedPinpoint: {
          region: 'us-west-2',
          appId: 'e80a7859b52b44e7982ed54a6bb7b129',
          roleSessionName: 'clarityClickStream',
          roleArn:
            'arn:aws:iam::969510070238:role/PiDevMetricsLogIngestionL-ClarityPinpointAccessRol-12S4FEZ4V5ICS',
          durationSeconds: 3600,
        },
      },
      Xray: {
        enabled: true,
        enableLogging: false,
        config: {
          region: 'us-west-2',
          serviceId: 'clarity-frontend',
        },
      },
      MaintenancePortal: {
        userGroup: ['clarity-beta-mpuser'],
      },
      ViewAs: {
        enabled: true,
        ldapGroups: ['clarity-dev-view-as-user'],
      },
      Stage: 'Beta',
      NotificationEndpoint: {
        url: 'https://lkvavn53pzakno4gezhoqu747q.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
      },
      ScienceEndpoint: {
        url: 'https://beta.ds2.peopleinsight.amazon.dev',
      },
      ClarityAssist: {
        region: 'us-west-2',
        url: 'https://beta.clarity-assist.peopleinsight.amazon.dev/graphql',
      },
      attributeLogicalNameToId: {
        locationRegion: 'A0005',
        locationBusinessLine: 'A0002',
        locationOrg: 'A0003',
        locationSite: 'A0006',
        locationGeo: 'A0001',
      },
      leaderSearchAttrId: 'A_1647367982_6',
      reportDownloadDisclaimerVersion: 2,
      voaSentimentMetrics: [
        'M1686773611', //VOA negative comments %
        'M1686773536', //VOA neutral comments %
        'M1686773450', //VOA positive comments %
        'M1686773336', //VOA neutral comments count
        'M1686773266', //VOA negative comments count
        'M1686773194', //VOA positive comments count
        'M0290', //VOA total comments count (updated name from VOA Total Items)
      ],
      rowLevelGroupByAttrIds: ['A1683588817'],
      differentNoRowErrorAttrIds: [],
      scienceConfig: {
        leaderOdmMetrics: leaderOdmDictionary.ODM_LEADER_METRIC_DICTIONARY_BETA,
        leaderOdmAttributes:
          leaderOdmDictionary.ODM_LEADER_ATTRIBUTE_DICTIONARY_BETA,
        leaderBenchmarkMetrics:
          leaderBenchmarkDictionary.BENCHMARK_LEADER_METRIC_DICTIONARY_BETA,
      },
      OfflineDownloads: {
        baseUrl:
          'https://offline-downloads.beta.clarity.people-insight.a2z.com/download-report/',
      },
    },
  },
  gamma: {
    'us-east-1': {
      logLevel: 'ERROR',
      RumConfig: {
        endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
        applicationId: '806bc949-8833-44ef-99af-2bb9eafead88',
        applicationRegion: 'us-east-1',
      },
      Auth: {
        identityPoolId: 'us-east-1:7ea84d3c-7436-47b3-bba1-0b4dde9979ce',
        region: 'us-east-1',
        userPoolId: 'us-east-1_2vIXLxUCl',
        userPoolWebClientId: 'v2pp2sou9i757v5mckfj7t8gg',
        mandatorySignIn: true,
        oauth: {
          redirectSignIn: 'https://gamma.clarity.people-insight.a2z.com/',
          redirectSignOut: 'https://gamma.clarity.people-insight.a2z.com/',
          domain:
            '638318975377-us-east-1-clarity.auth.us-east-1.amazoncognito.com',
          scope: ['email', 'profile', 'openid'],
          responseType: 'code',
        },
        cookieStorage: {
          domain: '.gamma.clarity.people-insight.a2z.com',
          path: '/',
          expires: 365,
          secure: true,
        },
      },
      PXTTelemetry: {
        endpoint: 'https://gamma.telemetry.pxt.amazon.dev/',
        namespace: 'clarity-gamma',
      },
      Xray: {
        enabled: true,
        enableLogging: false,
        config: {
          region: 'us-east-1',
          serviceId: 'clarity-frontend',
        },
      },
      MaintenancePortal: {
        userGroup: ['clarity-gamma-mpuser'],
      },
      ViewAs: {
        enabled: true,
        ldapGroups: ['clarity-dev-view-as-user'],
      },
      Stage: 'Gamma',
      BannerEndpoint: {
        url: 'https://yj3w7yvucjdbjilplk5wqlf3lu.appsync-api.us-east-1.amazonaws.com/graphql',
        region: 'us-east-1',
      },
      NotificationEndpoint: {
        url: 'https://iko2makh7reffcq3ulhkbj4aua.appsync-api.us-east-1.amazonaws.com/graphql',
        region: 'us-east-1',
      },
      ScienceEndpoint: {
        url: 'https://gamma.ds2.peopleinsight.amazon.dev',
      },
      ClarityAssist: {
        region: 'us-east-1',
        url: 'https://gamma.clarity-assist.peopleinsight.amazon.dev/graphql',
      },
      attributeLogicalNameToId: {
        locationRegion: 'A0005',
        locationBusinessLine: 'A0002',
        locationOrg: 'A0003',
        locationSite: 'A0006',
        locationGeo: 'A0001',
      },
      leaderSearchAttrId: 'A_1647367982_4',
      reportDownloadDisclaimerVersion: 2,
      voaSentimentMetrics: [
        'M1687450829', // VOA negative comments %
        'M1687450904', // VOA neutral comments %
        'M1687450994', // VOA positive comments %
        'M1687451070', // VOA neutral comments count
        'M1687451136', // VOA negative comments count
        'M1687451201', // VOA positive comments count
        'M0290', //VOA total comments count (updated name from VOA Total Items)
      ],
      rowLevelGroupByAttrIds: ['A1683604693'],
      differentNoRowErrorAttrIds: ['A1696032667'],
      scienceConfig: {
        leaderOdmMetrics:
          leaderOdmDictionary.ODM_LEADER_METRIC_DICTIONARY_GAMMA,
        leaderOdmAttributes:
          leaderOdmDictionary.ODM_LEADER_ATTRIBUTE_DICTIONARY_GAMMA,
        leaderBenchmarkMetrics:
          leaderBenchmarkDictionary.BENCHMARK_LEADER_METRIC_DICTIONARY_GAMMA,
      },
      OfflineDownloads: {
        baseUrl:
          'https://offline-downloads.gamma.clarity.people-insight.a2z.com/download-report/',
      },
    },
  },
  preprod: {
    'us-east-1': {
      RumConfig: {
        endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
        applicationId: '6cabea8a-7c62-42f8-b896-22ff403cbcea',
        applicationRegion: 'us-east-1',
      },
      Auth: {
        identityPoolId: 'us-east-1:8ed814a4-20a1-4a5a-90ee-bb732fe4501a',
        region: 'us-east-1',
        userPoolId: 'us-east-1_4f1tw8tka',
        userPoolWebClientId: '5piu7tbrs5d08542t94hraq40l',
        mandatorySignIn: true,
        oauth: {
          redirectSignIn: 'https://preprod.clarity.people-insight.a2z.com/',
          redirectSignOut: 'https://preprod.clarity.people-insight.a2z.com/',
          domain:
            '730335498295-us-east-1-clarity.auth.us-east-1.amazoncognito.com',
          scope: ['email', 'profile', 'openid'],
          responseType: 'code',
        },
        cookieStorage: {
          domain: '.preprod.clarity.people-insight.a2z.com',
          path: '/',
          expires: 365,
          secure: true,
        },
      },
      Analytics: {
        countriesToDisable: ['DEU'],
        CustomizedPinpoint: {
          region: 'us-east-1',
          appId: 'd57d7f6a353e408db761e0fa3b0bb8e0',
          roleSessionName: 'clarityClickStream',
          roleArn:
            'arn:aws:iam::969510070238:role/PiDevMetricsLogIngestionL-ClarityPinpointAccessRol-FSLQOY4G5KWT',
          durationSeconds: 3600,
        },
      },
      PXTTelemetry: {
        endpoint: 'https://gamma.telemetry.pxt.amazon.dev/',
        namespace: 'clarity-preprod',
      },
      Xray: {
        enabled: true,
        config: {
          region: 'us-east-1',
          serviceId: 'clarity-frontend',
        },
      },
      MaintenancePortal: {
        userGroup: ['clarity-preprod-mpuser'],
      },
      ViewAs: {
        enabled: false,
        ldapGroups: ['clarity-prod-view-as-user'],
      },
      Stage: 'PreProd',
      BannerEndpoint: {
        url: 'https://owvijheoszbzpkuem4xolez6lm.appsync-api.us-east-1.amazonaws.com/graphql',
        region: 'us-east-1',
      },
      NotificationEndpoint: {
        url: 'https://vwl6ecdiuvaglgmffhqsgvzyv4.appsync-api.us-east-1.amazonaws.com/graphql',
        region: 'us-east-1',
      },
      ScienceEndpoint: {
        url: 'https://gamma.ds2.peopleinsight.amazon.dev',
      },
      ClarityAssist: {
        region: 'us-east-1',
        url: 'https://preprod.clarity-assist.peopleinsight.amazon.dev/graphql',
      },
      attributeLogicalNameToId: {
        locationRegion: 'A0005',
        locationBusinessLine: 'A0002',
        locationOrg: 'A0003',
        locationSite: 'A0006',
        locationGeo: 'A0001',
      },
      leaderSearchAttrId: 'A_1647367982_4',
      reportDownloadDisclaimerVersion: 2,
      voaSentimentMetrics: [
        //TODO are these fine to copy from Prod
        'M1688758347', // VOA negative comments %
        'M1688675013', // VOA neutral comments %
        'M1688757699', // VOA positive comments %
        'M1688757778', // VOA neutral comments count
        'M1688757845', // VOA negative comments count
        'M1688757937', // VOA positive comments count
        'M0290', //VOA total comments count (updated name from VOA Total Items)
      ],
      rowLevelGroupByAttrIds: ['A1683604693'],
      differentNoRowErrorAttrIds: ['A1696032667'],
      scienceConfig: {
        leaderOdmMetrics: leaderOdmDictionary.ODM_LEADER_METRIC_DICTIONARY_PROD,
        leaderOdmAttributes:
          leaderOdmDictionary.ODM_LEADER_ATTRIBUTE_DICTIONARY_PROD,
        leaderBenchmarkMetrics:
          leaderBenchmarkDictionary.BENCHMARK_LEADER_METRIC_DICTIONARY_PROD,
      },
      OfflineDownloads: {
        baseUrl:
          'https://offline-downloads.preprod.clarity.people-insight.a2z.com/download-report/',
      },
    },
  },
  prod: {
    'us-east-1': {
      RumConfig: {
        endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
        applicationId: 'b6792481-e706-432c-80f3-dd6151d48de9',
        applicationRegion: 'us-east-1',
      },
      Auth: {
        identityPoolId: 'us-east-1:b169a4fe-5363-46af-8f53-5e22a3e58605',
        region: 'us-east-1',
        userPoolId: 'us-east-1_XnyolTnKU',
        userPoolWebClientId: 'qa3ukd0shf3pghf6i34hei4qf',
        mandatorySignIn: true,
        oauth: {
          redirectSignIn: 'https://clarity.people-insight.a2z.com/',
          redirectSignOut: 'https://clarity.people-insight.a2z.com/',
          domain:
            '359207982569-us-east-1-clarity.auth.us-east-1.amazoncognito.com',
          scope: ['email', 'profile', 'openid'],
          responseType: 'code',
        },
        cookieStorage: {
          domain: '.clarity.people-insight.a2z.com',
          path: '/',
          expires: 365,
          secure: true,
        },
      },
      Analytics: {
        countriesToDisable: ['DEU'],
        CustomizedPinpoint: {
          region: 'us-east-1',
          appId: '1e4249cb60e548b291d4fa8da31d306c',
          roleSessionName: 'clarityClickStream',
          roleArn:
            'arn:aws:iam::969510070238:role/PiDevMetricsLogIngestionL-ClarityPinpointAccessRol-FSLQOY4G5KWT',
          durationSeconds: 3600,
        },
      },
      PXTTelemetry: {
        endpoint: 'https://prod.telemetry.pxt.amazon.dev/',
        namespace: 'clarity-prod',
      },
      Xray: {
        enabled: true,
        config: {
          region: 'us-east-1',
          serviceId: 'clarity-frontend',
        },
      },
      MaintenancePortal: {
        userGroup: ['clarity-prod-mpuser'],
      },
      ViewAs: {
        enabled: false,
        ldapGroups: ['clarity-prod-view-as-user'],
      },
      Stage: 'Prod',
      BannerEndpoint: {
        url: 'https://255dack5vrb5zbuoptsaqoxism.appsync-api.us-east-1.amazonaws.com/graphql',
        region: 'us-east-1',
      },
      NotificationEndpoint: {
        url: 'https://z3v6ya3dsrflffu6mbgwpvxyxa.appsync-api.us-east-1.amazonaws.com/graphql',
        region: 'us-east-1',
      },
      ScienceEndpoint: {
        url: 'https://prod.ds2.peopleinsight.amazon.dev',
      },
      ClarityAssist: {
        region: 'us-east-1',
        url: 'https://prod.clarity-assist.peopleinsight.amazon.dev/graphql',
      },
      attributeLogicalNameToId: {
        locationRegion: 'A0005',
        locationBusinessLine: 'A0002',
        locationOrg: 'A0003',
        locationSite: 'A0006',
        locationGeo: 'A0001',
      },
      leaderSearchAttrId: 'A_1647367982_4',
      reportDownloadDisclaimerVersion: 2,
      voaSentimentMetrics: [
        'M1688758347', // VOA negative comments %
        'M1688675013', // VOA neutral comments %
        'M1688757699', // VOA positive comments %
        'M1688757778', // VOA neutral comments count
        'M1688757845', // VOA negative comments count
        'M1688757937', // VOA positive comments count
        'M0290', //VOA total comments count (updated name from VOA Total Items)
      ],
      rowLevelGroupByAttrIds: ['A1683604693'],
      differentNoRowErrorAttrIds: ['A1696032667'],
      scienceConfig: {
        leaderOdmMetrics: leaderOdmDictionary.ODM_LEADER_METRIC_DICTIONARY_PROD,
        leaderOdmAttributes:
          leaderOdmDictionary.ODM_LEADER_ATTRIBUTE_DICTIONARY_PROD,
        leaderBenchmarkMetrics:
          leaderBenchmarkDictionary.BENCHMARK_LEADER_METRIC_DICTIONARY_PROD,
      },
      OfflineDownloads: {
        baseUrl:
          'https://offline-downloads.clarity.people-insight.a2z.com/download-report/',
      },
    },
  },
};

export default stageConfig;
