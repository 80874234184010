import { useXRay } from '@clarity-website/common/useXRay';
import { DEFAULT_NEW_TAB_ID } from '@clarity-website/reports/tab/tab-util';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useTraceReportFetch(name: string, reportId?: string) {
  const { beginSegment, endSegment } = useXRay();
  const { pathname } = useLocation();
  const http = useMemo(
    () => ({
      request: {
        url: window.location.host + pathname,
        method: 'GET',
      },
    }),
    [pathname],
  );
  const [segment, setSegment] = useState(
    beginSegment(name, http, { reportId }),
  );
  const [segmentEnded, setSegmentEnded] = useState<boolean>(false);

  useEffect(() => {
    // Report id is not changed, no need to create a new segment
    if (reportId === segment.metadata.reportId) {
      return;
    }

    if (segmentEnded) {
      // If the segment has ended, create a new segment using the new metadata
      setSegment(beginSegment(name, http, { reportId }));
      setSegmentEnded(false);
    } else {
      // If segment is still ongoing, just update metadata
      segment.name = name;
      segment.http = http;
      segment.metadata = { reportId } as { [key: string]: string };
      setSegment(segment);
    }
  }, [beginSegment, http, reportId, name, segment, segmentEnded]);
  const wrapper = () => {
    if (!segmentEnded && reportId && reportId !== DEFAULT_NEW_TAB_ID) {
      endSegment(segment);
      setSegmentEnded(true);
    }
  };

  return {
    segment,
    endSegment: wrapper,
  };
}
