type value = string | number | boolean | Record<any, any>;

type ObjectStorageServiceCallback = (this: any, key: string, value: any) => any;

// ObjectStorageService mimics the Storage API, but allows for a broader range of
// objects to be stored.
export interface ObjectStorageService {
  getObject<T>(key: string, reviver?: ObjectStorageServiceCallback): T | null;
  setObject(
    key: string,
    value: value,
    replacer?: ObjectStorageServiceCallback,
  ): void;
  removeObject(key: string): void;
}

// A function that transforms objects with `@type: "set"` into sets
export const setsReviver: ObjectStorageServiceCallback = (_, value) => {
  if (value?.['@type'] === 'set' && value.values) {
    return new Set(value.values);
  }
  return value;
};

// A function that transforms sets into { @type: 'set', values: [...set] }, as JSON.stringify turns sets into {}
export const setsReplacer: ObjectStorageServiceCallback = (_, value) => {
  if (value instanceof Set) {
    return {
      '@type': 'set',
      values: [...value],
    };
  }
  return value;
};

// objectStorageService implements ObjectStorageService using JSON-encoded
// strings in localStorage.
function objectStorageService(prefix: string): ObjectStorageService {
  const storage = window.localStorage;
  return {
    getObject: (key, reviver) => {
      const res = storage.getItem(prefix + key);
      return res ? JSON.parse(res, reviver) : null;
    },
    setObject: (key, value, replacer): void => {
      return storage.setItem(prefix + key, JSON.stringify(value, replacer));
    },
    removeObject: (key: string): void => {
      return storage.removeItem(prefix + key);
    },
  };
}

export default objectStorageService;
