import { atom } from 'jotai';

export interface DynamicModalStateReportProp {
  name?: string;
  domains?: string[];
  id?: string;
}
type Props = {
  report: DynamicModalStateReportProp | null;
  isOpen: boolean;
  hasSelectedLeader: boolean;
};
export const dynamicModalState = atom<Props>({
  isOpen: false,
  report: null,
  hasSelectedLeader: false,
});
