export const getOriginUrl = () =>
  __webpack_public_path__ && __webpack_public_path__ !== '/'
    ? __webpack_public_path__
    : window.location.href;

export const isLoadingFromHRSCDomain = (): boolean => {
  const scriptOrigin = new URL(getOriginUrl()).origin;
  return (
    scriptOrigin.includes('hrsc.pxt.amazon.dev') ||
    scriptOrigin.includes('localhost:3009')
  ); // port 3009 is dedicated port for Clarity MFE for HRSC
};

export const getAPIUrlPrefix = (): string => {
  try {
    const scriptOrigin = new URL(getOriginUrl()).origin;
    if (isLoadingFromHRSCDomain()) {
      return `${scriptOrigin}/clarity-api`;
    }

    // just return origin if script is not loaded from hrsc origin (for Clarity Website)
    return scriptOrigin;
  } catch {
    // the scriptOrigin is not a valid URL (very unlikely)
    // use current window location origin
    // this will make sure clarity app works
    return new URL('https://clarity.people-insight.a2z.com/').origin;
  }
};
