import { atom } from 'jotai';

export interface User {
  // employeeId: string;
  firstName: string;
  lastName: string;
  firstLoginDate: Date;
  login: string;
  country: string;
  site: string;
  jobLevel: string;
  jobTitle: string;
  groups: string[];
  adGroups: string;
  posixGroups: string;
  hasMaintenanceUserAccess: boolean;
  hasViewAsUserAccess: boolean;
}

export interface UserState {
  loading: boolean;
  stage: string | undefined;
  user: User | undefined;
}

const initialState: UserState = {
  loading: true,
  stage: undefined,
  user: undefined,
};

export const currentUserStateAtom = atom<UserState>(initialState);
