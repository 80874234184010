import {
  WidgetMetricCategory,
  WidgetSubject,
  WidgetType,
} from '@amzn/claritygqllambda';
import { Item } from '@clarity-website/common/types/ButtonDropdown-types';
import { Page } from '@clarity-website/pages/home/config/constants';
import { WidgetView } from '@clarity-widgets/components/common/useWidgetView';
import {
  MetricWidgetRequiredSchema,
  WidgetMenuOptions,
} from '@clarity-widgets/components/metric/MetricWidget';
import { ComputedSchema } from '@clarity-widgets/hooks/useGetSchema';

export const createMetricWidgetPath = (
  metric: WidgetMetricCategory | Page,
  widgetSubject: WidgetSubject,
  widgetType: WidgetType,
) => `${metric}/${widgetSubject}/${widgetType}`;

export const getOpsSubject = (schema: MetricWidgetRequiredSchema) => {
  if (schema.hasSiteWidgets) {
    return WidgetSubject.Site;
  }
  if (schema.hasRegionWidgets) {
    return WidgetSubject.Region;
  }

  throw new Error('Ops page requires either site or region widgets');
};

export const createOverviewWidgetEntry = (
  metricCategory: WidgetMetricCategory | Page,
  schema: ComputedSchema,
): WidgetEntry => {
  const widgetSubject = getOpsSubject(schema);
  return {
    path: `${metricCategory}/${widgetSubject}/${WidgetType.Overview}`,
    widgetType: WidgetType.Overview,
    widgetSubject,
    metricCategory: metricCategory as WidgetMetricCategory,
  };
};

export type WidgetEntry = {
  path: string;
  widgetType: WidgetType;
  widgetSubject: WidgetSubject;
  metricCategory: WidgetMetricCategory;
};

export interface HeatmapSort extends Item {
  sort: 'asc' | 'dsc';
  keyOn: 'direct' | 'onTrack';
}

export const heatmapSortingOptions: HeatmapSort[] = [
  { id: 'asc-direct', sort: 'asc', keyOn: 'direct', label: 'By alias: A - Z' },
  {
    id: 'dsc-direct',
    sort: 'dsc',
    keyOn: 'direct',
    label: 'By alias: Z - A',
  },
  {
    id: 'dsc-onTrack',
    sort: 'dsc',
    keyOn: 'onTrack',
    label: 'By goals on track: descending',
  },
  {
    id: 'asc-onTrack',
    sort: 'asc',
    keyOn: 'onTrack',
    label: 'By goals on track: ascending',
  },
];

export const allWidgetMenuOptions: WidgetMenuOptions[] = [
  {
    id: WidgetView.chartAndTable,
    view: WidgetView.chartAndTable,
    label: 'Chart/table view',
  },
  { id: WidgetView.chart, view: WidgetView.chart, label: 'Chart view' },
  { id: WidgetView.table, view: WidgetView.table, label: 'Table view' },
];

export const chartAndTableWidgetMenuOptions: WidgetMenuOptions[] = [
  { view: WidgetView.chart, id: WidgetView.chart, label: 'Chart view' },
  { view: WidgetView.table, id: WidgetView.table, label: 'Table view' },
];
