import { DisplayStyleType } from '@amzn/claritygqllambda';
import {
  DataAndStructure,
  ProcessedData,
  ReactTableColumn,
  ReactTableColumnMeta,
} from '@clarity-website/react-table-data-handler/common/types';
import CommonTableMethods from '@clarity-website/reports/CommonTableMethods';
import { LOCAL_STORAGE_WIDTHS } from '@clarity-website/reports/table/reportConfigs';
import {
  getColumnWidthsFromLocalStorage,
  getColumnWidthsFromReportTemplate,
} from '@clarity-website/reports/table/tableSettingsPersistence';
import { ReportTemplate as RawReportTemplate } from '@clarity-website/reports/useFetchRawReport';
import { Column } from '@tanstack/react-table';

const defaultColWidthMetricOnRow = 160;
const defaultColWidthMetricOnColumn = 225;
const largeColWidth = 280;
const minColWidth = 80;
const HEADER_LENGTH_ADJUSTER = 2; // column headers share column width with the filter button, so they require more space
const LETTER_SIZE_COEFFICIENT = 10; // Width of "W" in the px of the column header font, to approximate text width
const PADDING_ADJUSTER = 12; // Column cells have 16px left and right paddings that reduce space available for text

export const getDefaultColWidth = (displayStyle: DisplayStyleType) =>
  displayStyle === DisplayStyleType.MetricOnRow
    ? defaultColWidthMetricOnRow
    : defaultColWidthMetricOnColumn;

export const getDefaultColumnWidths = (
  reportTemplate: RawReportTemplate,
  dataAndStructure: DataAndStructure,
  windowWidth = 0,
  primaryLeaderLoginColumns?: string[],
) => {
  const persistedWidthValues =
    getColumnWidthsFromLocalStorage(reportTemplate) ||
    getColumnWidthsFromReportTemplate(reportTemplate) ||
    LOCAL_STORAGE_WIDTHS?.[reportTemplate?.id];

  const columnsWithDataLengths = getFlatColumnsWithDataLengths(
    dataAndStructure.structure,
    dataAndStructure.data.slice(0, 100),
  );

  const columnWidthsInColumnSizingState = columnsWithDataLengths.reduce<
    Record<string, number>
  >((acc, column) => {
    return {
      ...acc,
      [column.id]:
        persistedWidthValues?.[column.id] ??
        getColumnWidth(column, primaryLeaderLoginColumns),
    };
  }, {});

  return adjustColumnWidthWithAvailableSpace(
    columnWidthsInColumnSizingState,
    windowWidth,
    primaryLeaderLoginColumns,
  );
};

const commonTableMethods = CommonTableMethods();

interface ColumnContentLengthsData {
  id: string;
  headerLength: number;
  dataLengths: number[];
}

function getFlatColumnsWithDataLengths(
  columns: ReactTableColumn[],
  data: ProcessedData,
): ColumnContentLengthsData[] {
  return columns.flatMap((column) => {
    if (column.columns?.length) {
      return getFlatColumnsWithDataLengths(column.columns, data);
    }

    if (!column.id) {
      return [];
    }

    return {
      id: column.id,
      headerLength: column.header.length,
      dataLengths: getColumnDataLengths(column, data),
    };
  });
}

function getColumnDataLengths(column: ReactTableColumn, data: ProcessedData) {
  return data.map((row) => {
    const { value, valueType } = column.accessorFn?.(row) || {};
    const displayValue = commonTableMethods.getDisplayComponent(
      value,
      valueType,
    );
    if (typeof displayValue !== 'string') {
      return 2;
    }
    return displayValue.length;
  });
}

function findMedian(arr: number[]) {
  const sorted = arr.sort((a, b) => a - b);

  const medianIndex = Math.ceil(sorted.length / 2);

  const leftMedianValue = sorted[medianIndex - 1];
  const rightMedianValue = sorted[sorted.length - medianIndex];

  return (leftMedianValue + rightMedianValue) / 2;
}

function getApproxLength(
  column: ColumnContentLengthsData,
  primaryLeaderLoginColumns?: string[],
) {
  const headerLength = column.headerLength + HEADER_LENGTH_ADJUSTER;
  const medianLength = findMedian(column.dataLengths);

  // For PrimaryLeaderLogin column width should be limited by data content only
  if (primaryLeaderLoginColumns?.includes(column.id)) {
    return medianLength + 1;
  }

  if (headerLength <= medianLength) {
    return medianLength;
  }

  /*
    If header's length is bigger than median length, width is based on header length.
  */
  return headerLength;
}

function getColumnWidth(
  column: ColumnContentLengthsData,
  primaryLeaderLoginColumns?: string[],
) {
  const approxLength = getApproxLength(column, primaryLeaderLoginColumns);

  const approxWidth =
    Math.round(approxLength * LETTER_SIZE_COEFFICIENT) + PADDING_ADJUSTER;

  if (approxWidth > largeColWidth) return largeColWidth;
  if (approxWidth < minColWidth) return minColWidth;
  return approxWidth;
}

/*
  If there is available space to the right of the table, this function distributes it between the columns up to column's recommended maximum.
*/
function adjustColumnWidthWithAvailableSpace(
  columnWidthsInColumnSizingState: Record<string, number>,
  availableWidthForColumns: number,
  primaryLeaderLoginColumns?: string[],
) {
  const columnWidthsAsArray = Object.entries(columnWidthsInColumnSizingState);

  const allColumnsWidthsSum = columnWidthsAsArray.reduce(
    (acc, [, width]) => acc + width,
    0,
  );

  // Amount of space to be divided between columns
  let availableSpace = availableWidthForColumns - allColumnsWidthsSum;

  if (availableSpace <= 0) {
    return columnWidthsInColumnSizingState;
  }

  // Initially wider columns should be given additional width first, since their content is overall longer
  return columnWidthsAsArray
    .filter(([id]) => !primaryLeaderLoginColumns?.includes(id)) // PrimaryLeaderLogin column should keep initially allocated width
    .sort(([, width1], [, width2]) => width2 - width1)
    .reduce((result, [id, width], idx, arr) => {
      const availableColumnWidth = largeColWidth - width;

      // Free space is divided equally between remaining columns
      const additionalWidthPerColumn = availableSpace / (arr.length - idx);

      if (availableColumnWidth > additionalWidthPerColumn) {
        availableSpace -= additionalWidthPerColumn;
        return {
          ...result,
          [id]: result[id] + additionalWidthPerColumn,
        };
      }

      availableSpace -= availableColumnWidth;
      return {
        ...result,
        [id]: result[id] + availableColumnWidth,
      };
    }, columnWidthsInColumnSizingState);
}

export function getColumnMeta<T>(
  column?: Column<T>,
): ReactTableColumnMeta | undefined {
  return column?.columnDef.meta;
}
