import {
  DisplayStyleType,
  MetadataType,
  PeriodType,
} from '@amzn/claritygqllambda';
import { MetadataLayout } from '@clarity-website/react-table-data-handler/common/types';

export const DISPLAY_STYLE_HASH_KEYS = {
  // metadataOnRow#metadataOnColumn
  MetricOnColumn: `${MetadataType.Attribute}#${[
    MetadataType.Timespan,
    MetadataType.Metric,
  ]}`,
  AttributeOnColumn: `${MetadataType.Attribute}#${[
    MetadataType.Timespan,
    MetadataType.Attribute,
  ]}`,
  MetricOnRow: `${[MetadataType.Attribute, MetadataType.Metric]}#${
    MetadataType.Timespan
  }`,
  AttrAndTimespanOnRow: `${[MetadataType.Attribute, MetadataType.Timespan]}`,
  BackendPivoted: 'BackendPivoted',
};

export const METRIC_ON_ROW_COLUMN_ID = 'metrics';
export const TIMESPAN_ON_ROW_COLUMN_ID = 'timespan';

export const defaultMetadataLayout: MetadataLayout = {
  [DisplayStyleType.MetricOnColumn]: {
    rows: [MetadataType.Attribute],
    columns: [MetadataType.Timespan, MetadataType.Metric],
  },
  Attribute_On_Column: {
    rows: [MetadataType.Attribute],
    columns: [MetadataType.Timespan, MetadataType.Attribute],
  },
  [DisplayStyleType.MetricOnRow]: {
    rows: [MetadataType.Attribute, MetadataType.Metric],
    columns: [MetadataType.Timespan],
  },
  [DisplayStyleType.RawData]: {
    rows: [MetadataType.Attribute, MetadataType.Timespan],
    columns: [],
  },
  [DisplayStyleType.ReactTable]: {
    rows: [],
    columns: [],
  },
};

export const PARTIAL_SUBTOTAL_REGEX = /^Sum total for/;

export const TIMESPAN_PREFIX_PERIOD_TYPE_MAP: { [key: string]: PeriodType } = {
  i: PeriodType.Intraday,
  d: PeriodType.Daily,
  w: PeriodType.Weekly,
  m: PeriodType.Monthly,
  pm: PeriodType.Pm,
  t3m: PeriodType.T3m,
  t6m: PeriodType.T6m,
  ttm: PeriodType.Ttm,
  q: PeriodType.Quarterly,
  ytd: PeriodType.Ytd,
  y: PeriodType.Yearly,
  py: PeriodType.Py,
  pq: PeriodType.Pq,
};
