import useClarityClient from '@clarity-website/lib/clients/useClarityClient';
import {
  getUISetting,
  setStringUISetting,
} from '@clarity-website/queries/UISettingQueries';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { atom, useAtom, useSetAtom } from 'jotai';

const UI_KEY = 'profile#dashboard#selectedLeader';
export const selectedLeaderKey = 'selectedLeader';

export const selectedLeaderAtom = atom('');

export function useGetSelectedLeader() {
  const [selectedLeader, setSelectedLeader] = useAtom(selectedLeaderAtom);
  const client = useClarityClient();
  useQuery<string>([selectedLeaderKey], () => getUISetting(client, UI_KEY), {
    suspense: true,
    onSuccess(data) {
      setSelectedLeader(data);
    },
  });
  return { selectedLeader };
}

export function useSetSelectedLeader() {
  const client = useClarityClient();
  const queryClient = useQueryClient();
  const setSelectedLeader = useSetAtom(selectedLeaderAtom);

  return useMutation(
    (selectedLeader: string) =>
      setStringUISetting(client, UI_KEY, selectedLeader),
    {
      onMutate: async (nextLeader) => {
        await queryClient.cancelQueries([selectedLeaderKey]);
        // optimistic update
        queryClient.setQueryData([selectedLeaderKey], nextLeader);
        // rollback function in case of error
        setSelectedLeader(nextLeader);
      },
    },
  );
}
