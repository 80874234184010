import { Spinner } from '@amzn/stencil-react-components/spinner';
import styled from 'styled-components';

export default function Loader() {
  return (
    <Container>
      <Spinner />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
