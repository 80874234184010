import { Credentials } from '@aws-amplify/core';
import { useUserState } from '@clarity-website/common/UserProvider';
import useAppConfig from '@clarity-website/config/useAppConfig';
import { getRegionFromCountry } from '@clarity-website/rum-utils';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { useEffect, useState } from 'react';

export let rumClient: AwsRum;

// The maximum time (in milliseconds) a route change may take.
// If a route change does not complete before the timeout, no timing data is recorded for the route change.
// If your application's route changes may take longer than the default timeout (i.e., more than 10 second),
// you should increase the value of the timeout.
// See https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md#configuration-options.
const ROUTE_CHANGE_TIMEOUT = 60 * 1000;

export const APPLICATION_VERSION = '1.0.0';

export function useInitRum() {
  const { data: config } = useAppConfig();
  const rumConfig = config?.RumConfig;
  const [enable, setEnable] = useState(false);
  const { user } = useUserState();

  useEffect(() => {
    if (user) {
      rumService.addCustomAttributes({
        login: user.login,
        // Track country as VPN might give us a false indication of location
        country: user.country,
        region: getRegionFromCountry(user.country),
      });
    }
  }, [user]);

  useEffect(() => {
    if (enable || !rumConfig) return;
    try {
      const rumExcludedUrls = [
        /https:\/\/cognito-.*/,
        /https:\/\/midway-auth\.amazon.com.*/,
        /https:\/\/xray.*.amazonaws.com\/TraceSegments/,
        /https:\/\/sentry.amazon.com\/SSO*/,
        /https:\/\/is-it-down.amazon.com\/sso\/login*/,
      ];

      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        sessionEventLimit: 0,
        sessionLengthSeconds: 360000,
        routeChangeTimeout: ROUTE_CHANGE_TIMEOUT,
        endpoint: `https://dataplane.rum.${rumConfig.applicationRegion}.amazonaws.com`,
        telemetries: [
          ['performance', { eventLimit: 50 }],
          'errors',
          [
            'http',
            {
              addXRayTraceIdHeader: false,
              urlsToExclude: rumExcludedUrls,
              recordAllRequests: true,
            },
          ],
        ],
        allowCookies: true,
        enableXRay: false,
        disableAutoPageView: true,
      };

      rumClient = new AwsRum(
        rumConfig.applicationId,
        APPLICATION_VERSION,
        rumConfig.applicationRegion,
        config,
      );
      rumClient.setAwsCredentials(Credentials.get());
      setEnable(true);
    } catch (error) {
      console.error(`RUM error: ${error}`);
      setEnable(false);
    }
  }, [enable, rumConfig]);
}

export const rumService = {
  //TODO setting the basics for RUM custom events, they can be used as per change of requirements
  logError: (e: Error | string) => {
    if (rumClient) {
      rumClient.recordError(`[ERROR]: ${e}`);
    }
  },

  logWarning: (message: string) => {
    if (rumClient) {
      rumClient.recordError(`[WARNING]: ${message}`);
    }
  },

  logInfo: (message: string) => {
    if (rumClient) {
      rumClient.recordError(`[INFO]: ${message}`);
    }
  },

  addCustomAttributes(attributes: { [key: string]: string | number } = {}) {
    console.info('Adding Custom Rum Attributes: ', attributes);
    rumClient && rumClient.addSessionAttributes(attributes);
  },

  recordEvent(
    eventName: string,
    attributes: { [key: string]: string | number } = {},
  ) {
    rumClient &&
      console.info(`Recording Custom Rum Event: (${eventName}): `, attributes);
    rumClient && rumClient.recordEvent(eventName, attributes);
  },

  recordPageView(
    pageId: string,
    pageTags: string[] = [],
    pageAttributes: { [key: string]: string } = {},
  ) {
    console.info(
      `Recording Custom Rum Report Page View`,
      pageId,
      pageTags,
      pageAttributes,
    );
    rumClient?.recordPageView({
      pageId,
      pageTags,
      pageAttributes,
    });
  },
};
