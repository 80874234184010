/*
  Modified upon original AWSPinpointProvider.ts file in aws-amplify package.
  Original file path: aws-amplify/analytics/src/Providers/AWSPinpointProvider.ts
  Add one step "_getSTSCredentials" to assume role of target destination AWS account
  in order to ingest Clickstream data cross-accountly to AWS Pinpoint Service
 */

/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */
import { AnalyticsProvider } from '@aws-amplify/analytics';
import Cache from '@aws-amplify/cache';
import {
  ClientDevice,
  Credentials,
  Hub,
  JS,
  ConsoleLogger as Logger,
} from '@aws-amplify/core';
import type { PutEventsRequest } from '@aws-sdk/client-pinpoint';
import { Pinpoint } from '@aws-sdk/client-pinpoint';
import { STS, Credentials as STSCredentials } from '@aws-sdk/client-sts';
import { v1 as uuid } from 'uuid';

type PromiseHandlers = {
  resolve: (val?: unknown) => any;
  reject: (val?: unknown) => any;
};

interface CustomizedPinpointProviderConfig {
  disabled: boolean;
  region: string;
  appId: string;
  endpointId: string;
  bufferSize: number;
  flushSize: number;
  flushInterval: number;
  resendLimit: number;
  credentials: STSCredentials;
  clientContext: ClientContext;
  endpoint: EndPoint;
  roleArn: string;
  roleSessionName: string;
  durationSeconds: number;
  identityId: string;
}

interface Buffer {
  params: Event;
  handlers: PromiseHandlers;
}

interface Event {
  eventName: string;
  timestamp: number;
  event: {
    eventId: string;
    name: string;
    session: {
      Id: string;
      StartTimestamp: string;
      Duration?: number;
      StopTimestamp?: string;
    };
    immediate: boolean;
    attributes: {
      [key: string]: string;
    };
    metrics: {
      [key: string]: number;
    };
  };
  config: CustomizedPinpointProviderConfig;
  credentials: STSCredentials;
  resendLimit?: number;
}

interface ClientInfo {
  platform?: string;
  make?: string;
  model?: string;
  version?: string;
  appVersion?: string;
  language?: string;
  timezone?: string;
}

interface ClientContext {
  clientId: string;
  appTitle: string;
  appVersionName: string;
  appVersionCode: string;
  appPackageName: string;
}
interface EndPoint {
  attributes: Record<string, unknown>;
  userAttributes: Record<string, unknown>;
  demographic: Record<string, unknown>;
  location: Record<string, unknown>;
  metrics: Record<string, unknown>;
  userId: string;
}

const AMPLIFY_SYMBOL = (
  typeof Symbol !== 'undefined' && typeof Symbol.for === 'function'
    ? Symbol.for('amplify_default')
    : '@@amplify_default'
) as symbol;

const dispatchAnalyticsEvent = (event: string, data: any) => {
  Hub.dispatch('analytics', { event, data }, 'Analytics', AMPLIFY_SYMBOL);
};

const logger = new Logger('CustomizedPinpointProvider');
const RETRYABLE_CODES = [429, 500];
const ACCEPTED_CODES = [202];

// events buffer
const BUFFER_SIZE = 1000;
const FLUSH_SIZE = 100;
const FLUSH_INTERVAL = 5 * 1000; // 5s
const RESEND_LIMIT = 5;
const EXPIRATION_TIME_BUFFER = 5 * 1000; // 5s

// params: { event: {name: , .... }, timeStamp, config, resendLimit }
export default class CustomizedPinpointProvider implements AnalyticsProvider {
  static category = 'Analytics';
  static providerName = 'CustomizedPinpoint';

  private _config: CustomizedPinpointProviderConfig;
  private pinpointClient!: Pinpoint;
  private _sessionId?: string;
  private _sessionStartTimestamp?: number;
  private _buffer: Buffer[];
  private _clientInfo: ClientInfo;
  private _timer?: number;
  private _endpointGenerating = true;

  constructor(config?: CustomizedPinpointProviderConfig) {
    this._buffer = [];
    this._config = Object.assign({}, config, {
      bufferSize: BUFFER_SIZE,
      flushSize: FLUSH_SIZE,
      flushInterval: FLUSH_INTERVAL,
      resendLimit: RESEND_LIMIT,
    });
    this._clientInfo = ClientDevice.clientInfo();
  }

  private _setupTimer() {
    if (this._timer) {
      clearInterval(this._timer);
    }
    const { flushSize, flushInterval } = this._config;
    this._timer = window.setInterval(() => {
      const size =
        this._buffer.length < flushSize ? this._buffer.length : flushSize;
      for (let i = 0; i < size; i += 1) {
        const { params, handlers } = this._buffer.shift()!;
        this._send(params, handlers);
        // If this is the first request sent by Analytics module, we should stop sending remaining requests
        // to prevent race condition of updating one endpoint when it's being created in the backend
        if (this._endpointGenerating) break;
      }
    }, flushInterval);
  }

  /**
   * @private
   * @param params - params for the event recording
   * Put events into buffer
   */
  private _putToBuffer(params: Event, handlers: PromiseHandlers) {
    const { bufferSize } = this._config;
    if (this._buffer.length < bufferSize) {
      this._buffer.push({ params, handlers });
    } else {
      logger.debug('exceed analytics events buffer size');
      return handlers.reject(
        new Error('Exceed the size of analytics events buffer'),
      );
    }
  }

  /**
   * get the category of the plugin
   */
  getCategory(): string {
    return CustomizedPinpointProvider.category;
  }

  /**
   * get provider name of the plugin
   */
  getProviderName(): string {
    return CustomizedPinpointProvider.providerName;
  }

  /**
   * configure the plugin
   * @param {Object} config - configuration
   */
  public configure(
    config: CustomizedPinpointProviderConfig,
  ): Record<string, unknown> {
    logger.debug('configure Analytics', config);
    const conf = config ? config : {};
    this._config = Object.assign({}, this._config, conf);

    if (this._config['appId'] && !this._config['disabled']) {
      if (!this._config['endpointId']) {
        const cacheKey = this.getProviderName() + '_' + this._config['appId'];
        this._getEndpointId(cacheKey)
          .then((endpointId) => {
            logger.debug('setting endpoint id from the cache', endpointId);
            this._config['endpointId'] = endpointId;
            dispatchAnalyticsEvent('pinpointProvider_configured', null);
          })
          .catch((e) => {
            logger.debug('Failed to generate endpointId', e);
          });
      } else {
        dispatchAnalyticsEvent('pinpointProvider_configured', null);
      }
      this._setupTimer();
    } else {
      if (this._timer) {
        clearInterval(this._timer);
      }
    }
    return this._config as any;
  }

  /**
   * record an event
   * @param {Object} params - the params of an event
   */
  public async record(params: Event, handlers: PromiseHandlers) {
    if (!params.event.name) {
      throw new Error("Missing required key 'name' in event");
    }
    const timestamp = new Date().getTime();
    const credentials =
      this._config.credentials &&
      timestamp + FLUSH_INTERVAL + EXPIRATION_TIME_BUFFER <
        (this._config.credentials?.Expiration?.getTime() ||
          new Date().getTime())
        ? this._config.credentials
        : await this._getCredentials().then(
            (credentials: typeof Credentials) => {
              return this._getSTSCredentials(credentials);
            },
          );
    if (!credentials || !this._config['appId'] || !this._config['region']) {
      logger.debug(
        'cannot send events without credentials, applicationId or region',
      );
      return handlers.reject(
        new Error('No credentials, applicationId or region'),
      );
    }
    // attach the session and eventId
    this._generateSession(params);
    params.event.eventId = uuid();

    Object.assign(params, { timestamp, config: this._config, credentials });
    if (params.event.immediate) {
      return this._send(params, handlers);
    }
    this._putToBuffer(params, handlers);
  }

  private _generateSession(params: Event) {
    this._sessionId = this._sessionId || uuid();
    const { event } = params;

    switch (event.name) {
      case '_session.start':
        // refresh the session id and session start time
        this._sessionStartTimestamp = new Date().getTime();
        this._sessionId = uuid();
        event.session = {
          Id: this._sessionId,
          StartTimestamp: new Date(this._sessionStartTimestamp).toISOString(),
        };
        break;
      case '_session.stop': {
        const stopTimestamp = new Date().getTime();
        this._sessionStartTimestamp =
          this._sessionStartTimestamp || new Date().getTime();
        this._sessionId = this._sessionId || uuid();
        event.session = {
          Id: this._sessionId,
          Duration: stopTimestamp - this._sessionStartTimestamp,
          StartTimestamp: new Date(this._sessionStartTimestamp).toISOString(),
          StopTimestamp: new Date(stopTimestamp).toISOString(),
        };
        this._sessionId = undefined;
        this._sessionStartTimestamp = undefined;
        break;
      }
      default:
        this._sessionStartTimestamp =
          this._sessionStartTimestamp || new Date().getTime();
        this._sessionId = this._sessionId || uuid();
        event.session = {
          Id: this._sessionId,
          StartTimestamp: new Date(this._sessionStartTimestamp).toISOString(),
        };
        break;
    }
  }

  private async _send(params: Event, handlers: PromiseHandlers) {
    const { event } = params;

    switch (event.name) {
      case '_update_endpoint':
        return this._updateEndpoint(params, handlers);
      default:
        return this._record(params, handlers);
    }
  }

  private _generateBatchItemContext(params: Event): PutEventsRequest {
    const { event, timestamp, config } = params;
    const { name, attributes, metrics, eventId, session } = event;
    const { appId, endpointId } = config;

    return {
      ApplicationId: appId,
      EventsRequest: {
        BatchItem: {
          [endpointId]: {
            Endpoint: {},
            Events: {
              [eventId]: {
                EventType: name,
                Timestamp: new Date(timestamp).toISOString(),
                Attributes: attributes,
                Metrics: metrics,
                Session: session,
              },
            },
          },
        },
      },
    };
  }

  private async _pinpointPutEvents(params: Event, handlers: PromiseHandlers) {
    const {
      event: { eventId },
      config: { endpointId },
    } = params;
    const eventParams = this._generateBatchItemContext(params);
    logger.debug('pinpoint put events with params', eventParams);
    try {
      const data = await this.pinpointClient.putEvents(eventParams);
      const { StatusCode, Message } =
        data.EventsResponse?.Results?.[endpointId]?.EventsItemResponse?.[
          eventId
        ] || {};

      if (ACCEPTED_CODES.includes(StatusCode!)) {
        this._endpointGenerating = false;
        logger.debug('record event success. ', data);
        return handlers.resolve(data);
      }
      if (RETRYABLE_CODES.includes(StatusCode!)) {
        this._retry(params, handlers);
      } else {
        logger.error(
          `Event ${eventId} is not accepted, the error is ${Message}`,
        );
        return handlers.reject(data);
      }
    } catch (err) {
      if (err) {
        logger.error('record event failed. ', err);
        logger.warn(
          'If you have not updated your Pinpoint IAM Policy' +
            ' with the Action: "mobiletargeting:PutEvents" yet, please do it.' +
            ' This action is not necessary for now' +
            ' but in order to avoid breaking changes in the future,' +
            ' please update it as soon as possible.',
        );
        return handlers.reject(err);
      }
    }
  }

  private _retry(params: Event, handlers: PromiseHandlers) {
    const {
      config: { resendLimit },
    } = params;
    // For backward compatibility
    params.resendLimit =
      typeof params.resendLimit === 'number' ? params.resendLimit : resendLimit;
    if (params.resendLimit-- > 0) {
      logger.debug(
        `resending event ${params.eventName} with ${params.resendLimit} retry times left`,
      );
      this._putToBuffer(params, handlers);
    } else {
      logger.debug(`retry times used up for event ${params.eventName}`);
    }
  }

  private async _record(params: Event, handlers: PromiseHandlers) {
    // credentials updated
    const { config, credentials } = params;
    this._initClients(config, credentials);
    return this._pinpointPutEvents(params, handlers);
  }

  private async _updateEndpoint(params: Event, handlers: PromiseHandlers) {
    // credentials updated
    const { config, credentials, event } = params;
    const { appId, endpointId } = config;

    this._initClients(config, credentials);

    const request = this._endpointRequest(
      config,
      JS.transferKeyToLowerCase(
        event,
        [],
        ['attributes', 'userAttributes', 'Attributes', 'UserAttributes'],
      ),
    );
    const updateParams = {
      ApplicationId: appId,
      EndpointId: endpointId,
      EndpointRequest: request,
    };

    logger.debug('updateEndpoint with params: ', updateParams);

    try {
      const data = await this.pinpointClient.updateEndpoint(updateParams);
      logger.debug('updateEndpoint success', data);
      this._endpointGenerating = false;
      return handlers.resolve(data);
    } catch (err) {
      if (err instanceof Error) {
        logger.debug('updateEndpoint failed', err);
        if (
          err.message.startsWith('Exceeded maximum endpoint per user count')
        ) {
          this._removeUnusedEndpoints(appId, request.User.UserId)
            .then(() => {
              logger.debug('Remove the unused endpoints successfully');
              this._retry(params, handlers);
            })
            .catch((e) => {
              logger.warn(`Failed to remove unused endpoints with error: ${e}`);
              logger.warn(
                `Please ensure you have updated your Pinpoint IAM Policy ` +
                  `with the Action: "mobiletargeting:GetUserEndpoints" ` +
                  `in order to get endpoints info of the user`,
              );
              return handlers.reject(err);
            });
        } else {
          return handlers.reject(err);
        }
      }
    }
  }

  private async _removeUnusedEndpoints(appId: string, userId: string) {
    try {
      const data = await this.pinpointClient.getUserEndpoints({
        ApplicationId: appId,
        UserId: userId,
      });
      const endpoints = data?.EndpointsResponse?.Item;
      logger.debug(
        `get endpoints associated with the userId: ${userId} with data`,
        endpoints,
      );
      if (endpoints) {
        let endpointToBeDeleted = endpoints[0];
        for (let i = 1; i < endpoints.length; i++) {
          const timeStamp1 = Date.parse(endpointToBeDeleted['EffectiveDate']!);
          const timeStamp2 = Date.parse(endpoints[i]['EffectiveDate']!);
          // delete the one with invalid effective date
          if (isNaN(timeStamp1)) break;
          if (isNaN(timeStamp2)) {
            endpointToBeDeleted = endpoints[i];
            break;
          }

          if (timeStamp2 < timeStamp1) {
            endpointToBeDeleted = endpoints[i];
          }
        }
        // update the endpoint's user id with an empty string
        const updateParams = {
          ApplicationId: appId,
          EndpointId: endpointToBeDeleted['Id']!,
          EndpointRequest: {
            User: {
              UserId: '',
            },
          },
        };
        try {
          const data = await this.pinpointClient.updateEndpoint(updateParams);
          logger.debug(
            'The old endpoint is updated with an empty string for user id',
          );
          return data;
        } catch (err) {
          if (err) {
            logger.debug('Failed to update the endpoint', err);
            throw err;
          }
        }
      }
    } catch (err) {
      if (err) {
        logger.debug(
          `Failed to get endpoints associated with the userId: ${userId} with error`,
          err,
        );
        throw err;
      }
    }
  }

  /**
   * @private
   * @param config
   * Init the clients
   */
  private async _initClients(
    config: CustomizedPinpointProviderConfig,
    credentials: STSCredentials,
  ) {
    logger.debug('init clients', credentials);

    if (
      this.pinpointClient &&
      this._config.credentials &&
      this._config.credentials.SessionToken === credentials.SessionToken
    ) {
      logger.debug('no change for aws credentials, directly return from init');
      return;
    }

    this._config.credentials = credentials;
    const { region } = config;
    logger.debug('init clients with credentials', credentials);
    if (credentials && credentials.AccessKeyId && credentials.SecretAccessKey) {
      this.pinpointClient = new Pinpoint({
        region,
        credentials: {
          accessKeyId: credentials.AccessKeyId,
          secretAccessKey: credentials.SecretAccessKey,
          sessionToken: credentials.SessionToken,
          expiration: credentials.Expiration,
        },
      });
    } else {
      console.log('No credentials found in PinpointProvider');
    }
  }

  private async _getEndpointId(cacheKey: string) {
    // try to get from cache
    let endpointId = await Cache.getItem(cacheKey);
    logger.debug(
      'endpointId from cache',
      endpointId,
      'type',
      typeof endpointId,
    );
    if (!endpointId) {
      endpointId = uuid();
      Cache.setItem(cacheKey, endpointId);
    }
    return endpointId;
  }

  /**
   * EndPoint request
   * @return {Object} - The request of updating endpoint
   */
  private _endpointRequest(
    config: CustomizedPinpointProviderConfig,
    event: any,
  ) {
    const { identityId } = config;
    const clientInfo = this._clientInfo || {};
    const clientContext = config.clientContext || {};
    // for now we have three different ways for default endpoint configurations
    // clientInfo
    // clientContext (deprecated)
    // config.endpoint
    const defaultEndpointConfig = config.endpoint || {};
    const demographicByClientInfo = {
      appVersion: clientInfo.appVersion,
      make: clientInfo.make,
      model: clientInfo.model,
      modelVersion: clientInfo.version,
      platform: clientInfo.platform,
    };
    // for backward compatibility
    const {
      clientId,
      appTitle,
      appVersionName,
      appVersionCode,
      appPackageName,
      ...demographicByClientContext
    } = clientContext;
    let channelType: string | undefined;
    if (event.address) {
      channelType = clientInfo.platform === 'android' ? 'GCM' : 'APNS';
    } else {
      channelType = undefined;
    }
    const tmp = {
      channelType,
      requestId: uuid(),
      effectiveDate: new Date().toISOString(),
      ...defaultEndpointConfig,
      ...event,
      attributes: {
        ...defaultEndpointConfig.attributes,
        ...event.attributes,
      },
      demographic: {
        ...demographicByClientInfo,
        ...demographicByClientContext,
        ...defaultEndpointConfig.demographic,
        ...event.demographic,
      },
      location: {
        ...defaultEndpointConfig.location,
        ...event.location,
      },
      metrics: {
        ...defaultEndpointConfig.metrics,
        ...event.metrics,
      },
      user: {
        userId: event.userId || defaultEndpointConfig.userId || identityId,
        userAttributes: {
          ...defaultEndpointConfig.userAttributes,
          ...event.userAttributes,
        },
      },
    };

    // eliminate unnecessary params
    const {
      userId,
      userAttributes,
      name,
      session,
      eventId,
      immediate,
      ...ret
    } = tmp;
    return JS.transferKeyToUpperCase(
      ret,
      [],
      ['metrics', 'userAttributes', 'attributes'],
    );
  }

  /**
   * @private
   * check if current credentials exists
   */
  private _getCredentials() {
    return Credentials.get()
      .then((credentials: any) => {
        if (!credentials) return null;
        logger.debug('set credentials for analytics', credentials);
        return credentials;
      })
      .catch((err: any) => {
        logger.debug('ensure credentials error', err);
        return null;
      });
  }

  /**
   * @private
   * assumeRole to get access to Pinpoint in another AWS Account
   */
  private _getSTSCredentials(credentials: typeof Credentials) {
    if (!credentials) {
      return null;
    }

    const params = {
      RoleArn: this._config.roleArn,
      RoleSessionName: this._config.roleSessionName,
      DurationSeconds: this._config.durationSeconds,
    };

    const sts = new STS({
      credentials: Credentials.shear(credentials),
      region: this._config.region,
    });
    return sts
      .assumeRole(params)
      .then((data) => {
        logger.debug(
          'set credentials for analytics: assumeRole for AWSPinpoint',
          data.Credentials,
        );
        this._config.identityId = Credentials.shear(credentials).identityId;
        if (
          !data.Credentials ||
          !data.Credentials.AccessKeyId ||
          !data.Credentials.SecretAccessKey ||
          !data.Credentials.SessionToken ||
          !data.Credentials.Expiration
        ) {
          console.log(
            'No credentials returned from assumeRole in PinpointProvider',
          );
          return;
        }
        this._config.credentials = {
          AccessKeyId: data.Credentials.AccessKeyId,
          SecretAccessKey: data.Credentials.SecretAccessKey,
          SessionToken: data.Credentials.SessionToken,
          Expiration: data.Credentials.Expiration,
        };

        return this._config.credentials;
      })
      .catch(function (err: any) {
        logger.debug('ensure credentials error', err);
        return null;
      });
  }
}
