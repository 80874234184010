import { MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import Message, {
  BaseMessage,
  CustomMessage,
} from '@clarity-website/message-banners/Message';
import {
  MessageBannerStateActions,
  messageBannerWriteReducer,
} from '@clarity-website/message-banners/MessageBannerState';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReducer } from 'jotai/utils';
import { useCallback } from 'react';

export const messageGroupAtom = atomWithReducer({}, messageBannerWriteReducer);

const autoIncrementingId = {
  _id: 1,
  get id() {
    return `message-banner-id-${this._id++}`;
  },
};

export type MessageBannerIds = {
  messageGroupId: string;
  id: string;
};

export function useMessageBannerDispatchers() {
  const dispatch = useSetAtom(messageGroupAtom);

  const addMessage = useCallback(
    (message: Message) => {
      const baseMessage: BaseMessage = {
        id: autoIncrementingId.id,
        ...message,
      };
      dispatch({ type: MessageBannerStateActions.ADD, payload: baseMessage });
      return baseMessage.id;
    },
    [dispatch],
  );

  // callers of this method should be aware that they are adding a complex/legacy banner
  // the custom message has metadata which is props of another existing MessageBanner
  const addComplexMessage = useCallback(
    (message: CustomMessage) => {
      const baseMessage: BaseMessage = {
        id: autoIncrementingId.id,
        text: '',
        dismissible: false,
        type: MessageBannerType.Informational,
        autoDismissAfter: undefined,
        ...message,
      };
      dispatch({ type: MessageBannerStateActions.ADD, payload: baseMessage });
      return baseMessage.id;
    },
    [dispatch],
  );

  const removeMessage = useCallback(
    (messageIds: MessageBannerIds) => {
      dispatch({
        type: MessageBannerStateActions.REMOVE,
        payload: {
          messageGroupId: messageIds.messageGroupId,
          id: messageIds.id,
        },
      });
    },
    [dispatch],
  );

  const clearMessages = useCallback(
    (messageGroupId: string) => {
      dispatch({
        type: MessageBannerStateActions.CLEAR,
        payload: messageGroupId,
      });
    },
    [dispatch],
  );
  return {
    addMessage,
    addComplexMessage,
    removeMessage,
    clearMessages,
  };
}

export default function useGetMessageGroup() {
  const message = useAtomValue(messageGroupAtom);

  return useCallback(
    (messageGroupId: string): BaseMessage[] => {
      return message[messageGroupId] || [];
    },
    [message],
  );
}
