import defaultTheme, { StencilTheme } from '@amzn/stencil-react-theme-default';
import { css } from 'styled-components';

export const sidebarClosed = 86;
export const sidebarOpen = 272;

const theme: StencilTheme & {
  sizes: { sidebarClosed: string; navbarHeight: string; sidebarOpen: string };
  clarityCustom: { editFlyoutSubHeaderPropertyBackground: string };
} = {
  ...defaultTheme,
  sizes: {
    sidebarClosed: `${sidebarClosed}px`,
    navbarHeight: '86px',
    sidebarOpen: `${sidebarOpen}px`,
  },
  clarityCustom: {
    editFlyoutSubHeaderPropertyBackground: defaultTheme.color.primary05,
  },
};

export const stencilFocus = css`
  :focus {
    outline: 2px solid ${theme.color.primary80};
    outline-offset: 2px;
  }
`;

export const stencilFocusInset = css`
  :focus {
    outline: 2px solid ${theme.color.primary80};
    outline-offset: -2px;
  }
`;

export const stencilHover = css`
  cursor: pointer;
  border: 2px solid transparent;
  padding-top: 5px;
  :hover {
    border-color: ${theme.color.primary70};
    outline-offset: -10px;
  }
`;

export default theme;
