import { currentUserStateAtom } from '@clarity-website/common/userProviderAtoms';
import { Segment, XRay, XRayConfig } from '@clarity-website/common/XRay';
import useAppConfig from '@clarity-website/config/useAppConfig';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

export function useXRay() {
  const { data: config } = useAppConfig();
  const { user } = useAtomValue(currentUserStateAtom);

  const xray = useMemo(() => new XRay(config?.Xray as XRayConfig), [config]);
  const beginSegment = useCallback(
    (name: string, http = {}, metadata = {}) =>
      xray.beginSegment(name, user?.login || '', http, metadata),
    [user?.login, xray],
  );
  const endSegment = useCallback(
    (segment: Segment) => xray.endSegment(segment),
    [xray],
  );

  return {
    beginSegment,
    endSegment,
  };
}
