import {
  ColumnMetadata,
  CompressedDataEntry,
  RowDataV2,
  ValueType,
  ValueTypeShort,
} from '@amzn/claritygqllambda';
import { MaskValues } from '@clarity-website/reports/report-types';
import {
  UnprocessedRawRow,
  UnprocessedRawRowValues,
} from '@clarity-website/reports/useFetchRawReport';

const ValueTypeMap: Record<string, ValueType> = {
  [ValueTypeShort.BasisPoints]: ValueType.BasisPoints,
  [ValueTypeShort.Currency]: ValueType.Currency,
  [ValueTypeShort.DateTime]: ValueType.DateTime,
  [ValueTypeShort.Float]: ValueType.Float,
  [ValueTypeShort.Integer]: ValueType.Integer,
  [ValueTypeShort.Percentage]: ValueType.Percentage,
  [ValueTypeShort.String]: ValueType.String,
  [ValueTypeShort.Lock]: ValueType.Lock,
  [ValueTypeShort.Missing]: ValueType.Missing,
  [ValueTypeShort.PrivacyLock]: ValueType.Lock,
  [ValueTypeShort.PermissionLockValue]: ValueType.Lock,
};

const ValueMap: Record<string, string> = {
  [ValueTypeShort.PrivacyLock]: MaskValues.PRIVACY_LOCK,
  [ValueTypeShort.PermissionLockValue]: MaskValues.Locked,
};

function decompressColumnData(
  dataEntry: CompressedDataEntry,
): UnprocessedRawRowValues {
  const [dataEntryValueType, dataEntryValue] =
    typeof dataEntry === 'string'
      ? [dataEntry, ValueMap[dataEntry] || '']
      : Object.entries(dataEntry)[0];
  const value = dataEntryValue.toString();
  const valueType = ValueTypeMap[dataEntryValueType] || ValueType.String;
  return { value, valueType };
}

export function decompressReportData(
  rows: RowDataV2[],
  columnMetadata: ColumnMetadata[],
): UnprocessedRawRow[] {
  return rows.map(({ T: timespan, D: data }) => {
    const decompressedColumns: UnprocessedRawRow = {};
    Object.entries(data).forEach(([column, value]) => {
      const [columnIdx, suffix] = column.split('_');
      let columnName = column;
      if (isNumber(columnIdx)) {
        const columnId = columnMetadata[parseInt(column, 10)]?.id;
        if (columnId) {
          columnName = suffix ? `${columnId}_${suffix}` : columnId;
        }
      }
      decompressedColumns[columnName] = decompressColumnData(value);
    });

    return {
      timespan: { value: timespan, valueType: ValueType.String },
      ...decompressedColumns,
    };
  });
}

function isNumber(value: string): boolean {
  return !isNaN(parseInt(value, 10));
}
