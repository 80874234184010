import { ResultStatusType } from '@amzn/claritygqllambda';
import { Button } from '@amzn/stencil-react-components/button';
import { Col, Row, View } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import griffSerious from '@clarity-website/assets/svg/griffSerious.svg';
import GraphicalErrorPage from '@clarity-website/common/GraphicalErrorPage';
import useAppConfig from '@clarity-website/config/useAppConfig';
import useDomainConfigs from '@clarity-website/config/useDomainConfigs';
import {
  CLARITY_WIKI_ACCESS_PAGE_URL,
  NO_ACCESS_SIM_TEMPLATE_URL,
} from '@clarity-website/pages/home/config/constants';
import {
  shouldRenderGraphicalErrorPage,
  shouldRenderNoEmployeesErrorMessage,
} from '@clarity-website/reports/report-utils';
import { ReportTemplate } from '@clarity-website/reports/useFetchRawReport';
import { ConditionalWrapper } from '@clarity-website/utils/component-utils';
import { ReactChildren } from '@clarity-website/utils/utility-types';
import { ResultStatus } from '@clarity-widgets/widget-types';
import styled from 'styled-components';

export const GENERAL_ERROR_MESSAGE =
  'Try reloading the page, if your issue persists, please submit a ticket.';

interface Props {
  error?: Error;
  title?: string;
  description?: string;
  link?: string;
  children?: ReactChildren;
  reset?: () => void;
  resultStatus?: ResultStatus | ResultStatusType;
  openReportFlyout?: () => void;
  reportTemplate?: ReportTemplate;
  noRowsError?: boolean;
  tooManyRowsError?: boolean;
  exportTableExcel?: () => void;
  exportTableCSV?: () => void;
}

function ErrorPage({
  title,
  description,
  link,
  reset,
  children,
  resultStatus,
  openReportFlyout,
  reportTemplate,
  noRowsError,
  tooManyRowsError,
  exportTableExcel,
  exportTableCSV,
}: Props) {
  const ticket = (
    <a href={link} target="_blank" rel="noopener noreferrer">
      ticket
    </a>
  );

  const { data: config } = useAppConfig();
  const { data: domainConfigs } = useDomainConfigs();
  const isRowLevelDomain =
    reportTemplate &&
    domainConfigs?.config[reportTemplate.domain]?.isRowLevelDomain;
  const renderHeading = () => {
    if (resultStatus === ResultStatus.NoAccessError) {
      if (isRowLevelDomain) {
        return (
          <Title>
            You do not have access to this employee row level data report
          </Title>
        );
      }
      return <Title>No access</Title>;
    }
    if (resultStatus === ResultStatus.CustomError) {
      return <Title>{title || 'Custom error'}</Title>;
    }
    if (resultStatus === ResultStatus.OrphanedResourcesError) {
      return <Title>Orphan Resources</Title>;
    }
    if (noRowsError) {
      return <Title>We're missing this data</Title>;
    }
    if (tooManyRowsError) {
      return (
        <Title>This table exceeds our 20000 row limit to view in Clarity</Title>
      );
    }
    return (
      <>
        <StyledImg src={griffSerious} alt="error" />
        <Title>Oh no...</Title>
      </>
    );
  };

  const renderDescription = () => {
    if (resultStatus === ResultStatus.NoAccessError) {
      if (isRowLevelDomain) {
        return (
          <span>
            To learn why you don't have access to this data, please&nbsp;
            <Link href={CLARITY_WIKI_ACCESS_PAGE_URL} target="_blank">
              click here
            </Link>
            <br />
            If you think your access has been incorrectly limited, please&nbsp;
            <Link
              href={
                (reportTemplate &&
                  domainConfigs?.config[reportTemplate.domain]
                    ?.simTicketTemplatesMap?.RequestAccess) ||
                NO_ACCESS_SIM_TEMPLATE_URL
              }
              target="_blank"
            >
              submit a SIM
            </Link>
          </span>
        );
      }
      return children || description;
    }
    if (resultStatus === ResultStatus.SizeError) {
      return (
        <span>
          This report is too large to load. Try applying some&nbsp;
          <Link href="#" onClick={openReportFlyout}>
            filters, timespan, attributes, or metrics
          </Link>
          &nbsp;to narrow down your report.
        </span>
      );
    }
    if (tooManyRowsError) {
      return (
        <Col gridGap="S200">
          <Row justifyContent="center">
            You may still&nbsp;
            <Link href="#" onClick={exportTableExcel}>
              export to Excel
            </Link>
            &nbsp;or&nbsp;
            <Link href="#" onClick={exportTableCSV}>
              export to CSV
            </Link>
            .
          </Row>
          <Row justifyContent="center">
            To view the data in Clarity, please apply more&nbsp;
            <Link href="#" onClick={openReportFlyout}>
              filters
            </Link>
            , or reduce the number of&nbsp;
            <Link href="#" onClick={openReportFlyout}>
              timespans, attributes, or metrics
            </Link>
            .
          </Row>
        </Col>
      );
    }
    if (noRowsError) {
      return (
        <span>
          This type of data is not yet available for the selection for filters
          chosen
        </span>
      );
    }
    return (
      <span>
        {children || description || 'Something went wrong!'}
        <TicketWrapper>{link ? ticket : null}</TicketWrapper>
      </span>
    );
  };

  let content;
  if (
    shouldRenderNoEmployeesErrorMessage(
      reportTemplate,
      noRowsError,
      config?.differentNoRowErrorAttrIds,
    )
  ) {
    content = (
      <>
        <Title>No Results Returned</Title>
        <Description>
          <span>You don't have any eligible employees for this category</span>
        </Description>
      </>
    );
  } else {
    content = (
      <>
        {renderHeading()}
        <Description>{renderDescription()}</Description>
        {reset ? (
          <Button onClick={reset}>Click here to try again!</Button>
        ) : null}
      </>
    );
  }

  const { shouldRenderGraphicalError } = shouldRenderGraphicalErrorPage(
    reportTemplate,
    noRowsError,
  );

  if (shouldRenderGraphicalError) {
    return (
      <GraphicalErrorPage
        reportTemplate={reportTemplate}
        noRowsError={noRowsError}
      />
    );
  }

  return (
    <ConditionalWrapper
      predicate={!!reportTemplate}
      wrapper={(children) => (
        <View padding="S700" width="100%">
          {children}
        </View>
      )}
    >
      <Container data-testid="error-page" gridGap="S300">
        {content}
      </Container>
    </ConditionalWrapper>
  );
}

const Container = styled(Col)`
  display: flex;
  flex: 1;
  background: white;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
`;

const Title = styled.h1`
  display: flex;
  justify-content: center;
  color: ${({ theme: { color } }) => color.neutral90};
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.33px;
  margin: 12px 0;
`;

const Description = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  color: ${({ theme: { color } }) => color.neutral90};
  font-size: 24px;
  letter-spacing: -0.44px;
`;

const StyledImg = styled.img`
  max-width: 200px;
`;

const TicketWrapper = styled.span`
  margin-left: 5px;
`;

export default ErrorPage;
