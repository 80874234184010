import ErrorPage from '@clarity-website/common/ErrorPage';
import Loader from '@clarity-website/common/Loader';
import { useAnalytics } from '@clarity-website/common/useAnalytics';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

const DefaultContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Boiler plate code to wrap Suspense queries with, to show either an Error page or a loader
export default function QueryBoundary({
  fallback = (
    <DefaultContainer>
      <Loader />
    </DefaultContainer>
  ),
  children,
  errorContainer: ErrorContainer = DefaultContainer,
  errorScope,
}: {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  errorContainer?: React.FC<{ children: React.ReactNode }>;
  errorScope: string;
}) {
  const { record } = useAnalytics();
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          onError={(error) => {
            record({
              name: 'Frontend exception',
              attributes: {
                scope: errorScope,
                name: error.name,
                message: error.message,
                stack: error.stack,
                cause: error.cause,
              },
            });
          }}
          fallbackRender={({ resetErrorBoundary }) => (
            <ErrorContainer>
              <ErrorPage reset={resetErrorBoundary} />
            </ErrorContainer>
          )}
        >
          <Suspense fallback={fallback}>{children}</Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
