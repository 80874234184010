import { analyticsDomainAtom, record } from '@clarity-website/common/Analytics';
import { ANALYTICS_SCOPE } from '@clarity-website/common/AnalyticsScope';
import { serializeArrayValues } from '@clarity-website/utils/object-utils';
import { atom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

interface ScopedAnalyticsAttributes {
  domain: string;
}

const scopedAnalyticsAttributes = atom<ScopedAnalyticsAttributes>((get) => {
  const domain = get(analyticsDomainAtom);

  return {
    domain,
  };
});

// Contains a collection of all scoped attributes
export function useAnalytics() {
  const attributes = useAtomValue(scopedAnalyticsAttributes, ANALYTICS_SCOPE);
  const attributesAsString = useMemo(
    () =>
      Object.entries(attributes)
        .map(([key, value]) => `${key}:${value}`)
        .join(','),
    [attributes],
  );

  const recordWithScopedAttributes = useCallback(
    (evt: any) =>
      record({
        ...evt,
        attributes: {
          ...attributes,
          ...serializeArrayValues(evt.attributes),
        },
      }),
    [attributes],
  );

  return {
    attributes,
    attributesAsString,
    record: recordWithScopedAttributes,
  };
}
