import { Auth } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import { getViewAsHeaders } from '@clarity-website/common/viewAs';
import { Segment, XRay } from '@clarity-website/common/XRay';
import { getAppConfig } from '@clarity-website/config/useAppConfig';
import { GqlRequestParams } from '@clarity-website/lib/clients/ClarityClient';
import {
  FetchNetworkError,
  InternalError,
  NoRetryError,
  ReauthorizeExceptions,
  TimeoutError,
} from '@clarity-website/lib/clients/exceptions';
import {
  createNetworkSubsegment,
  trackResponseInSegment,
} from '@clarity-website/lib/clients/xray';
import { getHRSCCognitoIdTokenFromCookie } from '@clarity-website/utils/hrsc-auth-utils';
import { isLoadingFromHRSCDomain } from '@clarity-website/utils/hrsc-general-utils';
import { retryBackoff } from 'backoff-rxjs';
import { GraphQLClient } from 'graphql-request';
import type {
  ClientError as GraphQLError,
  Variables,
} from 'graphql-request/dist/types';
import { from, lastValueFrom, of, throwError } from 'rxjs';
import { catchError, mergeMap, timeout } from 'rxjs/operators';

export type RestRequestParams = {
  path: string;
  init?: RequestInit | undefined;
  abortController?: AbortController;
  timeout?: number;
  retries?: number;
  traceId?: string;
};

type ClarityClientMethodParams = {
  path: string;
  payload: Record<string, any>;
  abortController?: AbortController;
  timeout?: number;
  retries?: number;
};

export type ErrorResponse = {
  errorCode: number;
  message: string;
  waitTimeInSec?: number;
};

export type ErrorOutput = {
  hasError: boolean;
  error?: ErrorResponse;
};

export type PostRequestParams = ClarityClientMethodParams;
export type DeleteRequestParams = Omit<ClarityClientMethodParams, 'payload'>;
export type GetRequestParams = Omit<ClarityClientMethodParams, 'payload'>;

export type FetchRequestParams = {
  input: string;
  init?: RequestInit | undefined;
  abortController?: AbortController;
  enableCors?: boolean;
  timeout?: number;
  retries?: number;
  disableAuthRetries?: boolean;
  traceId?: string;
};
export default class ClientMethods {
  static getJWT() {
    return Auth.currentSession().then((res) => res.getIdToken().getJwtToken());
  }

  static getToken(): Promise<string> {
    if (isLoadingFromHRSCDomain()) {
      return new Promise<string>((resolve) => {
        resolve(getHRSCCognitoIdTokenFromCookie());
      });
    }

    return lastValueFrom(
      of(null).pipe(
        mergeMap(() => ClientMethods.getJWT()),
        catchError((error) => {
          const message = ClientMethods.getErrorMessage(error);
          console.error('Auth error:', message);
          return ClientMethods.reauthenticateThenRetry(message);
        }),
        retryBackoff({
          initialInterval: 150,
          maxRetries: 2,
          resetOnSuccess: true,
        }),
      ),
    );
  }

  static reauthenticateThenRetry(message?: string) {
    const href = window.location.href;
    const config = getAppConfig();
    Amplify.configure(config);
    const authPromise = Auth.federatedSignIn({
      customProvider: '',
      customState: href,
    });
    return of(authPromise).pipe(
      mergeMap(() => throwError(() => new Error(message))),
    );
  }

  static retry(message?: string) {
    return throwError(() => new Error(message));
  }

  static getErrorMessage(
    error:
      | string
      | FetchNetworkError
      | TimeoutError
      | GraphQLError
      | InternalError,
  ): string {
    if (typeof error === 'string') {
      return error;
    }
    if (ClientMethods.isGraphQLError(error)) {
      return error.response.errors?.[0]?.message || '';
    }
    return error?.message;
  }

  static isFetchResponse(res: Response | unknown): res is Response {
    return (res as Response).ok !== undefined;
  }

  static isGraphQLError(error: GraphQLError | unknown): error is GraphQLError {
    return (
      error instanceof Error && (error as GraphQLError).response !== undefined
    );
  }

  static isOkGraphQLError(
    error: GraphQLError | unknown,
  ): error is GraphQLError {
    return (
      ClientMethods.isGraphQLError(error) &&
      error.response.status >= 200 &&
      error.response.status <= 299
    );
  }

  static shouldReauthenticate(
    status: number,
    message: string,
    disableAuthRetries: boolean,
  ) {
    return !disableAuthRetries && message in ReauthorizeExceptions;
  }

  static validatePath(uri: string) {
    if (!(uri.charAt(0) === '/')) {
      throw new Error('Paths must be absolute');
    }
  }

  static async makeFetchRequest({
    input,
    init,
    abortController,
    enableCors = false,
    traceId = '',
  }: FetchRequestParams): Promise<Response> {
    const token = await ClientMethods.getToken();
    return fetch(input, {
      ...init,
      signal: abortController?.signal,
      ...(enableCors && { mode: 'cors' }),
      credentials: 'omit',
      referrerPolicy: 'origin',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...getViewAsHeaders(),
        ...(traceId && { 'X-Amzn-Trace-Id': traceId }),
        ...(init || {}).headers,
      },
    });
  }

  static makeFetchRequestWithoutAuth({
    input,
    init,
    abortController,
    enableCors = false,
  }: FetchRequestParams): Promise<Response> {
    return fetch(input, {
      ...init,
      signal: abortController?.signal,
      ...(enableCors && { mode: 'cors' }),
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
        ...(init || {}).headers,
      },
    });
  }

  static getShouldReauthenticate(
    error: string | FetchNetworkError | TimeoutError | GraphQLError,
    disableAuthRetries: boolean,
  ): boolean {
    if (ClientMethods.isGraphQLError(error)) {
      const { status, message: statusText } = error.response;
      return ClientMethods.shouldReauthenticate(
        status,
        statusText,
        disableAuthRetries,
      );
    }
    if (error instanceof FetchNetworkError) {
      const { status, statusText } = error || {};
      return ClientMethods.shouldReauthenticate(
        status,
        statusText,
        disableAuthRetries,
      );
    }
    return false;
  }

  static async makeGqlRequest<T = any>(
    client: GraphQLClient,
    params: GqlRequestParams,
  ) {
    const token = await ClientMethods.getToken();
    client.setHeaders({
      authorization: `Bearer ${token}`,
      ...getViewAsHeaders(),
    });
    return client.request<T, Variables>(params.request, params.variables);
  }

  static retryBehavior<T = any>(
    makeRequest: () => Promise<Response>,
    errorHandler: (res: any) => ErrorOutput,
    timeoutMs = 30000,
    retries = 5,
    disableAuthRetries = false,
    segment?: Segment,
  ): Promise<T> {
    return lastValueFrom(
      of(null).pipe(
        mergeMap(() =>
          from(makeRequest()).pipe(
            mergeMap((res) => {
              if (ClientMethods.isFetchResponse(res)) {
                // Track response metadata in xray
                trackResponseInSegment(res, segment);
                if (!res.ok) {
                  return throwError(
                    () => new FetchNetworkError(res.status, res.statusText),
                  );
                }
                return from(res.json());
              }

              const errorResponse = errorHandler(res);
              const error = errorResponse.error;

              if (errorResponse.hasError && error && error.errorCode) {
                return throwError(
                  () => new InternalError(error.errorCode, error.message),
                );
              }
              return of(res);
            }),
          ),
        ),
        timeout({
          each: timeoutMs,
          with: () => throwError(() => new TimeoutError()),
        }),
        catchError(
          (
            error:
              | string
              | FetchNetworkError
              | TimeoutError
              | GraphQLError
              | InternalError,
          ) => {
            const message = ClientMethods.getErrorMessage(error);
            console.error('Error in client: ', message);

            /**
             * Passthrough non-retryable graphql errors (validations, shield, etc) thrown by GraphQlClient so that backoff logic isnt executed
             */
            if (this.isOkGraphQLError(error)) {
              return of(error);
            }

            const shouldReauthenticate = ClientMethods.getShouldReauthenticate(
              error,
              disableAuthRetries,
            );

            return shouldReauthenticate
              ? ClientMethods.reauthenticateThenRetry(message)
              : ClientMethods.retry(message);
          },
        ),
        retryBackoff({
          initialInterval: 150,
          maxRetries: retries,
          resetOnSuccess: true,
        }),
      ),
    );
  }

  static async fetch<T = any>({
    timeout,
    retries,
    disableAuthRetries,
    ...params
  }: FetchRequestParams) {
    try {
      const segment =
        params.traceId && params.traceId !== ''
          ? createNetworkSubsegment(params)
          : undefined;
      const request = () => ClientMethods.makeFetchRequest(params);
      const response = await ClientMethods.retryBehavior<T>(
        request,
        ClientMethods.handleRestError,
        timeout,
        retries,
        disableAuthRetries,
        segment,
      );

      // Trace network subsegment only if request is being traced further up the stack
      if (segment) {
        XRay.trackSegment(segment);
      }

      return response;
    } catch (error) {
      console.error('Fatal client error:', error);
      throw new NoRetryError(`Oh no! Something went wrong!`);
    }
  }

  static async fetchWithoutAuth<T = any>({
    timeout,
    ...params
  }: FetchRequestParams) {
    try {
      const request = () => ClientMethods.makeFetchRequestWithoutAuth(params);
      const response = await ClientMethods.retryBehavior<T>(
        request,
        ClientMethods.handleRestError,
        timeout,
      );
      return response;
    } catch (error) {
      console.error('Fatal client error:', error);
      throw new NoRetryError(`Oh no! Something went wrong!`);
    }
  }

  static async gql<T = any>(client: GraphQLClient, params: GqlRequestParams) {
    try {
      const request = () => ClientMethods.makeGqlRequest(client, params);
      const response = await ClientMethods.retryBehavior<T>(
        request,
        ClientMethods.handleGraphQLError,
      );
      /**
       * rethrow ok errors (gql shield, serverside 200 errors) that were passed through successfully in the retryBehavior observable
       * so that consumers of @tanstack/react-query onError handler can expect correct GraphQLClient behavior
       */
      if (this.isGraphQLError(response)) {
        throw response;
      }

      return response;
    } catch (error) {
      console.error('Fatal client error:', error);
      const message = ClientMethods.getErrorMessage(error as Error);
      throw new NoRetryError(
        `Oh no! Something went wrong${message ? `: ${message}` : '.'}`,
      );
    }
  }

  static handleGraphQLError(response: any): ErrorOutput {
    for (const key in response) {
      const result = response[key];
      if (result && Object.hasOwn(result, 'error') && result.error) {
        return { hasError: true, error: result.error };
      }
    }

    return { hasError: false };
  }

  static handleRestError(response: any): ErrorOutput {
    if (response && Object.hasOwn(response, 'error') && response.error) {
      return { hasError: true, error: response.error };
    }

    return { hasError: false };
  }
}
