import { StencilProvider } from '@amzn/stencil-react-components/context';
import App from '@clarity-website/app/App';
import Analytics, { record } from '@clarity-website/common/Analytics';
import ClarityThemeProvider from '@clarity-website/common/ClarityThemeProvider';
import UserProvider from '@clarity-website/common/UserProvider';
import reportWebVitals from '@clarity-website/reportWebVitals';
import * as serviceWorkerRegistration from '@clarity-website/serviceWorkerRegistration';
import { MediaQueryProvider } from '@clarity-website/utils/UseMedia';
import { oneHourInMs } from '@clarity-website/utils/cache-time-constants';
import theme from '@clarity-website/utils/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { enableMapSet } from 'immer';
import { Provider as JotaiProvider } from 'jotai';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

enableMapSet();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: oneHourInMs,
      retry: (_retryCount, _error) => {
        return false;
      },
    },
  },
});
ReactDOM.render(
  <StrictMode>
    <ClarityThemeProvider>
      <StencilProvider theme={theme}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <JotaiProvider>
              <Analytics>
                <MediaQueryProvider>
                  <UserProvider>
                    <App />
                    <ReactQueryDevtools initialIsOpen={false} />
                  </UserProvider>
                </MediaQueryProvider>
              </Analytics>
            </JotaiProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </StencilProvider>
    </ClarityThemeProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(record);

serviceWorkerRegistration.register();
