import { Spinner } from '@amzn/stencil-react-components/spinner';
import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

function PageLoader(args: any): ReactElement {
  const [showLoader, updateShowLoader] = useState<boolean>(false);
  useEffect((): (() => void) => {
    const timeout = setTimeout((): void => updateShowLoader(true), 150);
    return function cleanup(): void {
      clearTimeout(timeout);
    };
  }, [showLoader, updateShowLoader]);
  return <Container {...args}>{showLoader && <Spinner />}</Container>;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default PageLoader;
