import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

function getSize(): Dimensions {
  return {
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    outerWidth: window.outerWidth,
    outerHeight: window.outerHeight,
    screen: {
      availHeight: window.screen.availHeight,
      availWidth: window.screen.availWidth,
      width: window.screen.width,
      height: window.screen.height,
    },
  };
}

export interface Dimensions {
  innerWidth: number;
  innerHeight: number;
  outerWidth: number;
  outerHeight: number;
  screen: {
    availHeight: number;
    availWidth: number;
    width: number;
    height: number;
  };
}

const windowSizeAtom = atom<Dimensions>(getSize());
export const readOnlyWindowSizeAtom = atom<Dimensions>((get) =>
  get(windowSizeAtom),
);

export default function useWindowSize(): Dimensions {
  const setWindowSize = useSetAtom(windowSizeAtom);

  useEffect((): (() => void) => {
    const handleResize = (): void => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, [setWindowSize]);

  return useAtomValue(readOnlyWindowSizeAtom);
}
