import { DisplayStyleType } from '@amzn/claritygqllambda';
import { ReportTemplate } from '@clarity-website/reports/useFetchRawReport';
import objectStorageService, {
  setsReplacer,
  setsReviver,
} from '@clarity-website/utils/object-storage-service';
import {
  ColumnFiltersState,
  ColumnSizingState,
  SortingState,
} from '@tanstack/react-table';

/*
  Structure for the table settings in the local storage:
  key: report-settings<report-template-id>
  value: {
    "metric_On_Column": {
      columnWidths: {
        "<column-id>": number
      },
      sorting: [
        { id: <column-id>, desc: boolean },
      ],
      columnFilters: [
        { id: <column-id>,
          value: {
            displayValues?: string[];
            condition?: {
              operator: string;
              operand1?: string;
              operand2?: string;
            }; },
      ]
    },
    "metric_On_Row": {
      ...
    },
  }
*/

export const COLUMN_FILTERS_VERSION = '2';

type DisplayStyleSettings = {
  columnWidths: ColumnSizingState;
  sorting: SortingState;
  columnFilters: ColumnFiltersState;
};

type ReportSettings = Partial<Record<DisplayStyleType, DisplayStyleSettings>>;

const reportsSettingsStorage = objectStorageService(`report-settings`);

function getLocalStorageObject(key: string) {
  // setsReviver has to be added to correctly retrieve sets for ColumnFilters
  return reportsSettingsStorage.getObject<ReportSettings>(key, setsReviver);
}

function getDisplayStyleSettings(
  { id, displayStyle }: ReportTemplate,
  version?: string,
) {
  const key = version ? `${id}#${version}` : id;
  return getLocalStorageObject(key)?.[displayStyle];
}

export const getSortingFromLocalStorage = (reportTemplate: ReportTemplate) =>
  getDisplayStyleSettings(reportTemplate)?.sorting;

export const getColumnWidthsFromLocalStorage = (
  reportTemplate: ReportTemplate,
) => getDisplayStyleSettings(reportTemplate)?.columnWidths;

export const getColumnFiltersFromLocalStorage = (
  reportTemplate: ReportTemplate,
) =>
  getDisplayStyleSettings(reportTemplate, COLUMN_FILTERS_VERSION)
    ?.columnFilters;

export function updateTableSettingsInLocalStorage(
  newValue: Partial<DisplayStyleSettings>,
  { id, displayStyle }: ReportTemplate,
  version?: string,
) {
  const key = version ? `${id}#${version}` : id;
  const reportSettings = getLocalStorageObject(key);
  const updatedSettings = {
    ...reportSettings,
    [displayStyle]: { ...reportSettings?.[displayStyle], ...newValue },
  };
  // setsReplacer has to be added to correctly store sets in ColumnFilters
  reportsSettingsStorage.setObject(key, updatedSettings, setsReplacer);
}

/**
 * Transform DisplayStyleSettingsInput to DisplayStyleSettings columnWidths
 * @param reportTemplate
 * @returns DisplayStyleSettings
 */
export const getColumnWidthsFromReportTemplate = (
  reportTemplate: ReportTemplate,
): ColumnSizingState | undefined => {
  const displayStyleSettingInput = reportTemplate?.displayStyleSettings?.find(
    (displayStyleSettings) =>
      displayStyleSettings.displayStyle === reportTemplate.displayStyle,
  );
  const columnWidths =
    displayStyleSettingInput?.columnWidths.reduce<ColumnSizingState>(
      (acc, { columnId, width }) => {
        acc[columnId] = width;
        return acc;
      },
      {},
    );

  return columnWidths;
};
