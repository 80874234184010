import { DisplayStyleType, ReportType } from '@amzn/claritygqllambda';
import {
  EmptyReportTemplateInterface,
  ReportGroupSheet,
} from '@clarity-website/reports/report-types';

export const INSIGHTS_TAB_ID = 'insights-tab';
export const DEFAULT_NEW_TAB_ID = 'new-tab';
export const TAB_ID_SEARCH_KEY = 'tab';
export const TAB_INDEX_SEARCH_KEY = 'tabIndex';
export const TAB_NAME_SEARCH_KEY = 'name';
const DEFAULT_TAB_NAME_PREFIX = 'Sheet';
export const DEFAULT_TAB_NAME = `${DEFAULT_TAB_NAME_PREFIX} 1`;
export const MAX_SHEET_NAME_LENGTH = 15;
export const MAX_TAB_COUNT = 8;

export const createReportTemplate = (
  domain: string,
  id: string,
  name: string,
): EmptyReportTemplateInterface => ({
  id,
  name,
  selectedTimespans: [],
  filters: [],
  filtersV2: '',
  selectedGroupingAttributeIds: [],
  selectedMetricIds: [],
  reportType: ReportType.Custom,
  displayStyle: DisplayStyleType.MetricOnColumn,
  domain,
});

export const generateNewTabName = (tabs: ReportGroupSheet[]) => {
  const idx =
    tabs.reduce((acc, nxt) => {
      const regExp = new RegExp(`^${DEFAULT_TAB_NAME_PREFIX}\\s\\d+$`);
      return regExp.test(nxt.name)
        ? parseInt(nxt.name.split(' ')[1]) || acc
        : acc;
    }, 0) + 1;

  return truncateName(`${DEFAULT_TAB_NAME_PREFIX}`, `${idx}`);
};

export const generateCopySheetName = (
  originTabId: string,
  originTabName: string,
  tabs: ReportGroupSheet[],
) => {
  const preReport = tabs.reduce(
    (acc, nxt) => {
      const regExp = new RegExp(`\\s[(]\\d+[)]$`);
      const testRes = regExp.exec(nxt.name);
      if (!testRes) {
        return acc;
      }
      const preLength = nxt.name.length - testRes[0].length;
      const preName = nxt.name.substr(0, preLength);
      if (preName === originTabName) {
        const preIdx = parseInt(testRes[0].split(/[()]/)[1]);
        if (preIdx) {
          return {
            preReportId: nxt.id,
            preIdx,
          };
        }
      }
      return acc;
    },
    {
      preReportId: originTabId,
      preIdx: 1,
    },
  );
  return {
    preReportId: preReport.preReportId,
    newReportName: `${originTabName} copy`,
  };
};

const truncateName = (prefix: string, index: string) =>
  `${
    prefix.length > MAX_SHEET_NAME_LENGTH - index.length
      ? prefix.substring(0, MAX_SHEET_NAME_LENGTH - index.length - 4) + '...'
      : prefix
  } ${index}`;

export const getNextReportId = (tabs: ReportGroupSheet[], reportId: string) => {
  const idx = tabs.findIndex((tab) => tab.id === reportId);
  const newTabs = tabs.filter((tab) => tab.id !== reportId);
  if (idx < 0) {
    return { newTabs, nextTabId: newTabs[0]?.id };
  }
  if (idx < newTabs.length) {
    return { newTabs, nextTabId: newTabs[idx]?.id };
  }
  return {
    newTabs,
    nextTabId: newTabs[newTabs.length - 1 < 0 ? 0 : newTabs.length - 1]?.id,
  };
};
