import { Cookies } from 'react-cookie';

export const USER_AUTHORIZATION_TOKEN_KEY = 'USER_AUTHORIZATION_TOKEN';

/**
 * Returns the HRSC Cognito ID token by reading it directly from Cookie directly.
 * If cookie is not set for USER_AUTHORIZATION_TOKEN, will return an empty string.
 * @returns {Promise<string>}
 * */
export const getHRSCCognitoIdTokenFromCookie = (): string => {
  const cookies = new Cookies();
  return cookies.get(USER_AUTHORIZATION_TOKEN_KEY) ?? '';
};
