import produce from 'immer';

export enum LayoutTypes {
  TOGGLE_DESKTOP_OPEN,
  TOGGLE_MOBILE_OPEN,
  HOVER_IN,
  HOVER_OUT,
  MOBILE_CLICK_OUT,
}

export interface ActionType {
  type: LayoutTypes;
}

export interface LayoutStateInterface {
  desktopOpen: boolean;
  mobileOpen: boolean;
  hoverActive: boolean;
}

export const initialLayoutState = {
  desktopOpen: false,
  hoverActive: false,
  mobileOpen: false,
};

export function layoutReducer(
  state: LayoutStateInterface,
  action: ActionType,
): LayoutStateInterface {
  return produce(state, (draft): void => {
    switch (action.type) {
      case LayoutTypes.TOGGLE_DESKTOP_OPEN:
        draft.desktopOpen = !draft.desktopOpen;
        break;
      case LayoutTypes.TOGGLE_MOBILE_OPEN:
        draft.mobileOpen = !draft.mobileOpen;
        break;
      case LayoutTypes.HOVER_IN: {
        draft.desktopOpen = true;
        draft.hoverActive = true;
        break;
      }
      case LayoutTypes.HOVER_OUT: {
        draft.desktopOpen = false;
        draft.hoverActive = false;
        break;
      }
      case LayoutTypes.MOBILE_CLICK_OUT: {
        draft.mobileOpen = false;
        break;
      }
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  });
}
