export enum SAVE_TYPE {
  COPY = 'saveACopy',
  SAVE = 'saveChanges',
  RENAME_GROUP = 'renameGroup',
  UPDATE_SHEET = 'updateSheet',
  COPY_SHEET = 'copySheet',
  ADD_SHEET = 'addSheet',
  TRANSPOSE = 'transpose',
  REORDER_TABLES = 'reorderTablesInSheets',
}

export interface ReportMutationAction {
  onSuccess?: () => void;
  onSettled?: () => void;
  onError?: (error: Error) => void;
}

export interface ReportSheetOutput {
  id: string;
  name: string;
  tableIds: string[];
  isActive?: boolean;
}
