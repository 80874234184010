import useAppConfig from '@clarity-website/config/useAppConfig';
import ClarityClient from '@clarity-website/lib/clients/ClarityClient';
import useClarityClient from '@clarity-website/lib/clients/useClarityClient';
import { useGetUserMemberships } from '@clarity-website/pages/search-reports/hooks/UserPermissionHooks';
import PageLoader from '@clarity-website/utils/PageLoader';
import { QueryClient, useQuery } from '@tanstack/react-query';
import gql from 'graphql-tag.macro';
import { intersection } from 'ramda';
import {
  Dispatch,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

interface Props {
  children?: any;
}

export interface FeatureFlag {
  featureName: string;
  enabled: boolean;
  metadata: {
    allowList: string[];
  };
}

export interface ConfigurationState {
  featureFlags: Map<string, FeatureFlag>;
}

interface ConfigurationContextAndDispatchInitialState {
  configurationState: ConfigurationState;
  setConfigurationState: Dispatch<ConfigurationState>;
}

interface GetFeatureFlagConfigurationResponse {
  getFeatureFlagConfiguration: FeatureFlag[];
}

const getFeatureFlagConfigurationQueryKey = 'getFeatureFlagConfiguration';

// Keep configuration profile transformed data from AWS AppConfig outside of the
// query so it can be combined with other configuration profiles before being
// returned to the client
const featureFlagConfiguration = new Map<string, FeatureFlag>();

export const GET_FEATURE_FLAG_CONFIGURATION_QUERY = gql`
  query getFeatureFlagConfiguration(
    $applicationName: String!
    $environmentName: String!
    $configurationProfileName: String!
  ) {
    getFeatureFlagConfiguration(
      applicationName: $applicationName
      environmentName: $environmentName
      configurationProfileName: $configurationProfileName
    ) {
      featureName
      enabled
      metadata {
        allowList
      }
    }
  }
`;

const AppConfigurationContext =
  createContext<ConfigurationContextAndDispatchInitialState>({
    configurationState: {
      featureFlags: new Map<string, FeatureFlag>(),
    },
    setConfigurationState: () => void {},
  });

const getFeatureFlagConfiguration = async (
  client: ClarityClient,
  stage: string,
): Promise<GetFeatureFlagConfigurationResponse> => {
  return client.gql<{
    getFeatureFlagConfiguration: FeatureFlag[];
  }>({
    request: GET_FEATURE_FLAG_CONFIGURATION_QUERY,
    variables: {
      applicationName: `PiClarityGraphQLLambdaApplication-${stage}`,
      environmentName: `PiClarityGraphQLLambdaEnvironment-${stage}`,
      configurationProfileName: `PiClarityGraphQLLambdaFeatureFlags-${stage}`,
    },
  });
};

export function prefetchAppConfiguration(
  queryClient: QueryClient,
  clarityClient: ClarityClient,
  stage: string,
) {
  queryClient.prefetchQuery([getFeatureFlagConfigurationQueryKey], () =>
    getFeatureFlagConfiguration(clarityClient, stage),
  );
}

function ConfigurationContext({ children }: Props): ReactElement {
  const { data: config } = useAppConfig();
  const stage = config?.Stage.toLowerCase() || 'test';
  const client = useClarityClient();

  const [configurationState, setConfigurationState] =
    useState<ConfigurationState>({
      featureFlags: new Map<string, FeatureFlag>(),
    });

  useQuery<GetFeatureFlagConfigurationResponse>(
    [getFeatureFlagConfigurationQueryKey],
    () => getFeatureFlagConfiguration(client, stage),
    {
      onSuccess: (data) => {
        const { getFeatureFlagConfiguration: flags = [] } = data || {};
        if (flags.length) {
          flags.reduce(
            (flagList: Map<string, FeatureFlag>, flag: FeatureFlag) => {
              flagList.set(flag.featureName, flag);
              return flagList;
            },
            featureFlagConfiguration,
          );
        }
      },
      onError: (error) => {
        console.error('Error fetching feature flags: ', error);
      },
      useErrorBoundary: () => false,
      suspense: true,
    },
  );

  useEffect(() => {
    setConfigurationState({
      featureFlags: featureFlagConfiguration,
    });
  }, []);

  return (
    <AppConfigurationContext.Provider
      value={{
        configurationState,
        setConfigurationState,
      }}
    >
      {children}
    </AppConfigurationContext.Provider>
  );
}

interface WithFeatureFlagProps {
  name: string;
  children: ReactElement;
}

export function WithFeatureFlag({ name, children }: WithFeatureFlagProps) {
  const { isFeatureFlagToggleOn } = useFeatureFlagToggles(name);

  /* If featureFlag does not exist on the backend (undefined), feature shouldn't be blocked from rendering */
  return isFeatureFlagToggleOn ? children : null;
}

type WithFeatureFlagAllowListOrElseProps = {
  // feature flag name
  featureFlagName: string;
  // Component to render in case the user have access to the feature, and the feature is enabled
  accessAllowedComponent: ReactElement;
  // Component to render in case the user doesn't have access to the feature or if the feature is not enabled
  accessDeniedComponent: ReactElement | null;
};

export function WithFeatureFlagAllowListOrElse({
  featureFlagName,
  accessAllowedComponent,
  accessDeniedComponent,
}: WithFeatureFlagAllowListOrElseProps) {
  const { isLoading } = useGetUserMemberships();
  const { isFeatureFlagToggleOnAndOnAllowList } =
    useFeatureFlagToggles(featureFlagName);

  if (isLoading) return <PageLoader />;

  return isFeatureFlagToggleOnAndOnAllowList
    ? accessAllowedComponent
    : accessDeniedComponent;
}

export const useFeatureFlagToggles = (featureFlagName: string) => {
  const {
    configurationState: { featureFlags },
  } = useConfiguration();
  const { data: userGroupsData } = useGetUserMemberships();

  const flag = featureFlags.get(featureFlagName);

  const teams =
    userGroupsData?.getUserGroups?.map((actor) => actor.actor) ?? [];

  const featureAllowedList = flag?.metadata?.allowList ?? [];

  const isFeatureFlagToggleOn = flag === undefined || flag.enabled;
  const isFeatureFlagToggleOnAndOnAllowList =
    flag?.enabled && intersection(teams, featureAllowedList).length > 0;

  return { isFeatureFlagToggleOn, isFeatureFlagToggleOnAndOnAllowList };
};

// Create the initial context
// This should be used only in components wrapped in the ConfigurationContext
// to use the AppConfig profiles
export const useConfiguration = () => useContext(AppConfigurationContext);

export default ConfigurationContext;
