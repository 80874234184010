import { BaseMessage } from '@clarity-website/message-banners/Message';

// actions
export enum MessageBannerStateActions {
  ADD = 'add',
  REMOVE = 'remove',
  CLEAR = 'clear',
}

export const messageBannerWriteReducer = (
  messageGroup: {
    [messageGroupId: string]: BaseMessage<any>[];
  },
  action: { type: MessageBannerStateActions; payload: any },
) => {
  const messageGroupId = action.payload.messageGroupId;

  switch (action.type) {
    case MessageBannerStateActions.ADD:
      if (action.payload) {
        return {
          ...messageGroup,
          [messageGroupId]: addMessageToGroup(
            action.payload,
            messageGroup[messageGroupId],
          ),
        };
      }
      return messageGroup;
    case MessageBannerStateActions.REMOVE:
      if (messageGroup[messageGroupId]) {
        return {
          ...messageGroup,
          [messageGroupId]: [
            ...messageGroup[messageGroupId].filter(
              (m) => m.id !== action.payload.id,
            ),
          ],
        };
      }
      return messageGroup;
    case MessageBannerStateActions.CLEAR:
      return { ...messageGroup, [action.payload]: [] };
    default:
      throw new Error('unknown action type');
  }
};

function addMessageToGroup(message: BaseMessage, messageGroup?: BaseMessage[]) {
  if (!messageGroup) {
    return [message];
  }
  const msgIndex = messageGroup.findIndex((msg) => msg.id === message.id);
  if (msgIndex !== -1) {
    return [
      ...messageGroup.slice(0, msgIndex),
      message,
      ...messageGroup.slice(msgIndex + 1),
    ];
  }
  return [...messageGroup, message];
}
