import clock from '@clarity-website/assets/svg/clock.svg';
import column from '@clarity-website/assets/svg/column.svg';
import filterInactive from '@clarity-website/assets/svg/filter-inactive.svg';
import row from '@clarity-website/assets/svg/row.svg';
import { Item } from '@clarity-website/reports/filters/filter-types';

export interface FilterInterface {
  name: string;
  icon: any;
  title: string;
  filterValues: Map<string, Item>;
  isMultiselect: boolean;
  grouped: boolean;
}

export interface FilterConfigInterface {
  [key: string]: FilterInterface;
  filters: FilterInterface;
  timespan: FilterInterface;
  attributes: FilterInterface;
  metrics: FilterInterface;
  metricColumns: FilterInterface;
}

export enum FilterKeys {
  FILTERS = 'filters',
  TIMESPAN = 'timespan',
  ATTRIBUTES = 'attributes',
  METRICS = 'metrics',
  METRIC_COLUMNS = 'metricColumns',
}

export enum FilterResultType {
  BROWSE = 'browse',
  SEARCH = 'search',
}

export enum FilterSortType {
  DESCENDING = 'Descending',
  ASCENDING = 'Ascending',
}

const filterMap: FilterConfigInterface = {
  filters: {
    name: 'Filters',
    isMultiselect: true,
    icon: filterInactive,
    title: 'Filter your report data',
    filterValues: new Map(),
    grouped: false,
  },
  timespan: {
    name: 'Timespans',
    isMultiselect: false,
    icon: clock,
    title: 'Add a timespan',
    filterValues: new Map(),
    grouped: true,
  },
  attributes: {
    name: 'Slice breakout (Attributes)',
    isMultiselect: false,
    icon: row,
    title: 'Add an attribute',
    filterValues: new Map(),
    grouped: false,
  },
  metrics: {
    name: 'Metrics',
    isMultiselect: false,
    icon: column,
    title: 'Add metrics',
    filterValues: new Map(),
    grouped: false,
  },
  metricColumns: {
    name: 'Employee row level metrics',
    isMultiselect: false,
    icon: row,
    title: 'Add an employee row level metric attribute',
    filterValues: new Map(),
    grouped: false,
  },
};

export type FilterConfigKeys = string;

export default filterMap;
