import { EventAttributes } from '@aws-amplify/analytics/lib-esm/types';

class AnalyticsStorage {
  private globalAttributes: EventAttributes;

  constructor() {
    this.globalAttributes = {};
  }

  setGlobalAttributes(attributes: EventAttributes) {
    this.globalAttributes = attributes;
  }

  getGlobalAttributes(): EventAttributes {
    return this.globalAttributes;
  }
}

// This is a singleton class instantiation for tracking the current analytics attributes.
// The reason for this object is that we store the analytics data in jotai as an atom (global React state)
// and with the current version of jotai (v1) the values cannot be accessed from outside the React tree.
// We use the Analytics component to synchronize the current attributes to this singleton object so it can be
// referenced outside the React tree when triggering analytics events.
const storage = new AnalyticsStorage();
export default storage;
