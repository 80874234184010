import cssReset from '@clarity-website/utils/css-reset';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  ${cssReset}
  html, body, input, button {
    font-family: "Amazon Ember", Helvetica, sans-serif;
  }

  html, body {
    height: 100%;
    width: 100%;
    font-size: 16px;
    overflow: hidden;
  }

  #root {
    height: 100%;
    width: 100%;
  }
`;
