export const RenderIf = ({
  predicate,
  children,
  placeholder,
}: {
  predicate: boolean;
  children: React.ReactNode;
  placeholder?: JSX.Element;
}): JSX.Element | null => {
  if (predicate) {
    return children as NonNullable<JSX.Element>;
  }
  if (placeholder) {
    return placeholder;
  }
  return null;
};

export const ConditionalWrapper = ({
  predicate,
  wrapper,
  children,
}: {
  predicate: boolean;
  wrapper: (children?: any) => any;
  children?: any;
}): any => (predicate && wrapper ? wrapper(children) : children);
