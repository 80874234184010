import { useLocation } from 'react-router-dom';

const routesMap: Record<string, string> = {
  'kpi-summary': 'KPI summary',
  'public-reports': 'Standard reports',
  'your-reports': 'Your reports',
  favorites: 'Favorites',
  help: 'Help & Support',
  metric_library: 'Metric library',
  faqs: 'FAQs',
  'whats-new': "What's new",
  'all-reports': 'All reports',
  maintenance: 'Maintenance portal',
};

export default function AppTitle() {
  const { pathname } = useLocation();
  const paths = pathname
    .split('/')
    .map((path) => routesMap[path] || '')
    .filter((path) => path !== '');
  const title = ['Clarity', ...paths].join(' - ');
  return <title>{title}</title>;
}
