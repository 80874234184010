// Based on https://github.com/aws-samples/eb-java-scorekeep/blob/xray-cognito/public/app/xray.js
import { Credentials } from '@aws-amplify/core';
import {
  PutTraceSegmentsCommand,
  XRayClient,
  XRayClientConfig,
} from '@aws-sdk/client-xray';
import { PutTraceSegmentsCommandInput } from '@aws-sdk/client-xray/dist-types/commands/PutTraceSegmentsCommand';
import { getAppConfig } from '@clarity-website/config/useAppConfig';
import { getUnixTime } from 'date-fns';
const crypto = window.crypto;

const DEFAULT_RETRY_ATTEMPTS = 3;
const DEFAULT_REGION = 'us-west-2';
const DEFAULT_SERVICE_ID = 'clarity-frontend';

const DEFAULT_CONFIG = {
  enabled: false,
  enableLogging: false,
  config: {
    retryAttempts: DEFAULT_RETRY_ATTEMPTS,
    region: DEFAULT_REGION,
    serviceId: DEFAULT_SERVICE_ID,
  },
};

export interface XRayConfig {
  enabled: boolean;
  enableLogging: boolean;
  config: XRayClientConfig;
}

export interface Segment {
  trace_id?: string;
  id: string;
  parent_id?: string;
  start_time: number;
  end_time?: number;
  name: string;
  in_progress: boolean;
  user?: string;
  http: {
    request?: { [key: string]: string | number };
    response?: { [key: string]: string | number };
  };
  annotations?: { [key: string]: string };
  metadata: { [key: string]: string };
  subsegments: Segment[];
  type?: 'subsegment';
}

export class XRay {
  private static cachedConfig: XRayConfig;
  private readonly client: XRayClient | undefined;
  private enabled = false;

  constructor({ enabled, enableLogging, config }: XRayConfig = DEFAULT_CONFIG) {
    if (enableLogging) {
      config.logger = console;
    }

    const mergedConfig: XRayClientConfig = Object.assign(
      {},
      {
        credentials: Credentials.get(),
      },
      DEFAULT_CONFIG.config,
      config,
    );

    this.client = new XRayClient(mergedConfig);
    this.enabled = enabled;
  }

  static getTraceHeader(segment: Segment): string {
    return 'Root=' + segment.trace_id + ';Parent=' + segment.id + ';Sampled=1';
  }

  static generateTraceId(): string {
    return '1-' + XRay.getHexTime() + '-' + XRay.getHexId(24);
  }

  static getHexId(length: number): string {
    const bytes = new Uint8Array(length);
    crypto.getRandomValues(bytes);
    let hex = '';
    for (let i = 0; i < bytes.length; i++) {
      hex += bytes[i].toString(16);
    }
    return hex.substring(0, length);
  }

  static getHexTime(): string {
    return Math.round(XRay.getEpochTime()).toString(16);
  }

  static getEpochTime(): number {
    return getUnixTime(new Date());
  }

  static parseFieldFromTraceHeader(traceHeader: string, field: string): string {
    const parts = traceHeader.split(';');
    return parts.reduce((result, curr) => {
      if (curr.startsWith(field)) {
        const [, value] = curr.split('=');
        return value;
      }

      return result;
    }, '');
  }

  static traceHeaderToTraceId(traceHeader: string): string {
    return this.parseFieldFromTraceHeader(traceHeader, 'Root=');
  }

  static traceHeaderToSegmentId(traceHeader: string): string {
    return this.parseFieldFromTraceHeader(traceHeader, 'Parent=');
  }

  static createSegment(
    name: string,
    user: string,
    http = {},
    metadata = {},
  ): Segment {
    const traceId = XRay.generateTraceId();
    const id = XRay.getHexId(16);
    const startTime = XRay.getEpochTime();

    return {
      trace_id: traceId,
      id,
      start_time: startTime,
      name,
      in_progress: true,
      user,
      http,
      metadata,
      subsegments: [],
    };
  }

  static createSubsegment(
    parentTraceHeader: string,
    name: string,
    user: string,
    http = {},
    metadata = {},
  ): Segment {
    const parentTraceId = XRay.traceHeaderToTraceId(parentTraceHeader);
    const parentId = XRay.traceHeaderToSegmentId(parentTraceHeader);
    const segment = XRay.createSegment(name, user, http, metadata);
    // Overwrite the trace id/id with values from the parent trace
    segment.trace_id = parentTraceId;
    segment.parent_id = parentId;
    segment.type = 'subsegment';

    return segment;
  }

  static trackSegment(segment: Segment) {
    if (XRay.cachedConfig) {
      return new XRay(this.cachedConfig).endSegment(segment);
    }

    // Fire and forget implementation
    const config = getAppConfig();
    XRay.cachedConfig = config.Xray as XRayConfig;
    new XRay(XRay.cachedConfig).endSegment(segment);
  }

  beginSegment(name: string, user: string, http = {}, metadata = {}): Segment {
    // Don't send pending segments to XRay
    return XRay.createSegment(name, user, http, metadata);
  }

  endSegment(segment: Segment) {
    // Prevent the same segment from being sent multiple times
    if (!segment.in_progress) {
      return;
    }

    segment.end_time = segment.end_time || XRay.getEpochTime();
    segment.in_progress = false;
    this.putDocuments([JSON.stringify(segment)]);
  }

  putDocuments(documents: string[]) {
    if (!this.enabled || !this.client) {
      return;
    }

    const input: PutTraceSegmentsCommandInput = {
      TraceSegmentDocuments: documents,
    };
    const command = new PutTraceSegmentsCommand(input);

    // Fire and forget
    this.client
      .send(command)
      .then(() => {
        console.debug('Successfully sent XRay PutTraceSegments.');
      })
      .catch((err) => {
        console.error('Failed to send XRay PutTraceSegments: ', err);
      });
  }
}
