import { Atom, Provider } from 'jotai';
import React from 'react';

export const ANALYTICS_SCOPE = 'analytics';

interface Props {
  initialValues?: Iterable<readonly [Atom<unknown>, unknown]>;
  children: React.ReactNode;
}

// This component wraps child components in a jotai provider with a specified analytics scope.
// This enables us to put a new analytics provider in different React subtrees
// enabling a global analytics state specific to the React subtree where the scope is rendered.
// Current use cases involve storing the analytics domain and sidebar focus (provided as initial value to the provider)
// allowing different subtrees rendered at the same time to contain a different set of attributes.
export function AnalyticsScope({
  children,
  initialValues = [],
}: Props): React.ReactElement {
  return (
    <Provider scope={ANALYTICS_SCOPE} initialValues={initialValues}>
      {children}
    </Provider>
  );
}
