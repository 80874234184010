import { AttributeValue } from '@amzn/claritygqllambda/dist/generated/graphql';
import {
  ONBOARDING_MODAL_VIEWS,
  OnboardingAction,
  OnboardingState,
} from '@clarity-website/pages/home/onboarding/hooks/useOnboardingState';
import { MutableRefObject } from 'react';

export enum ONBOARDING_SELECTION_DISPLAY_TYPE {
  TAG,
  PILL,
}

export interface DisplayEntityData {
  text: string;
  selected?: boolean;
  onClick?: (index: number) => void;
}

export interface SiteEntity {
  site: string;
  selected?: boolean;
  onClick?: (index: number) => void;
}

export interface RegionEntity {
  key: string;
  formattedName: string;
  rowValues: AttributeValue[];
  selected?: boolean;
  onClick?: (index: number) => void;
}

export interface MetricCategoryEntity {
  metricCategory: string;
  selected?: boolean;
  onClick?: (index: number) => void;
}

export interface LeaderDataPayload {
  leaders?: Employee[];
  metricCategories?: string[];
}

export interface OnboardingViewPayload {
  view: ONBOARDING_MODAL_VIEWS;
  currentStepIndex?: number;
  dataset?: ONBOARDING_DATASETS;
}

export enum ONBOARDING_DATASETS {
  SITE = 'operations',
  LEADER = 'leader',
}

export enum CHANGE_TYPE {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface OnboardingSetStateProps {
  setOnboardingState: React.Dispatch<OnboardingAction>;
}

export interface OnboardingStateProps extends OnboardingSetStateProps {
  onboardingState: OnboardingState;
}
export interface OnboardingAndSetStartTimeStateProps
  extends OnboardingStateProps {
  widgetMonitoringStartTime: MutableRefObject<number>;
}

export interface OnboardingAndStartTimeStateProps
  extends OnboardingSetStateProps {
  widgetMonitoringStartTime: MutableRefObject<number>;
}

export interface Employee {
  username: string;
  fullName?: string;
}

export enum USER_ROLES {
  HR = 'HR',
  Leader = 'Leader',
  Manager = 'Manager',
}

export enum DASHBOARD_ID {
  OPS = 'ops',
  CORP = 'corp',
}
