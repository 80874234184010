export const TOP_TIER_RETENTION = 'M_1660927829_1';
export const UNREGRETTED_ATTRITION = 'M_1660927829_2';
export const D1MO = 'M1689692204';
export const D1MO_MONTHLY = 'M1689693091';
export const WOMEN_L8_STEM = 'M_1664259510_2';
export const LATINO_L4_L7 = 'M_1664259510_10';
export const LATINO_L8 = 'M_1664259510_6';
export const BLACK_L4_L7 = 'M_1664259510_8';
export const BLACK_L8 = 'M_1664259510_4';

export const PROJECTED_TTR = 'M_1660927829_25';
export const PROJECTED_URA = 'M_1660927829_12';

export const REGRETTED_ATTRITION = 'M_1647367982_71';
export const TRANSFER_OUT = 'M_1647367982_67';

export const widgetDisableChartFillForMetrics = new Set<string>([
  REGRETTED_ATTRITION,
  TRANSFER_OUT,
]);

export const widgetGoalTextForMetrics: Record<string, string> = {
  [D1MO]: 'Goal rate %',
};

export const widgetTooltipDescriptionTemplates: Record<string, string> = {
  [TOP_TIER_RETENTION]: 'Retained',
  [UNREGRETTED_ATTRITION]: 'Attrited',
  [D1MO]: 'Cumulative completion',
  [D1MO_MONTHLY]: 'Monthly completion',
  [REGRETTED_ATTRITION]: 'Attrited',
  [TRANSFER_OUT]: 'Transfers out',
};

export const metricIdToHoverTitle = new Map<string, string>([
  [TOP_TIER_RETENTION, 'Top Tier Retention'],
  [UNREGRETTED_ATTRITION, 'Unregretted Attrition'],
  [D1MO, 'D1MO completion within 30 days % (Goal)'],
  [D1MO_MONTHLY, 'D1MO monthly completion % (Goal)'],
  [REGRETTED_ATTRITION, 'Regretted Attrition %'],
  [TRANSFER_OUT, 'Transfer out %'],
]);

export const projectedTooltipText: Record<string, string> = {
  [PROJECTED_TTR]: 'Projected top tier retention',
  [PROJECTED_URA]: 'Projected unregretted attrition',
};

export const projectedGapToGoalText: Record<string, string> = {
  [PROJECTED_TTR]: 'Projected top tier retention',
  [PROJECTED_URA]: 'Projected unregretted attrition',
};

export const PERFORMANCE_YEAR_METRICS = [
  TOP_TIER_RETENTION,
  UNREGRETTED_ATTRITION,
  PROJECTED_TTR,
  PROJECTED_URA,
  D1MO,
];

export const LEADER_MONTHLY_METRICS = [REGRETTED_ATTRITION, TRANSFER_OUT];
