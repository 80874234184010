const BENCHMARK_LEADER_METRIC_DICTIONARY_PROD = [
  'M_1647367982_71',
  'M_1647367982_67',
  'M_1660927829_1',
  'M_1660927829_2',
  'M1689692204',
];

const BENCHMARK_LEADER_METRIC_DICTIONARY_GAMMA = [
  'M_1647367982_71',
  'M_1647367982_67',
  'M_1660927829_1',
  'M_1660927829_2',
  'M1689692204',
];

const BENCHMARK_LEADER_METRIC_DICTIONARY_BETA = [
  'M1674506560',
  'M_1647367982_67',
  'M_1660927829_1',
  'M_1660927829_2',
  'M1689692204',
];

const LEADER_BENCHMARK_STAGE_VALUES = {
  BENCHMARK_LEADER_METRIC_DICTIONARY_PROD,
  BENCHMARK_LEADER_METRIC_DICTIONARY_GAMMA,
  BENCHMARK_LEADER_METRIC_DICTIONARY_BETA,
};

export default LEADER_BENCHMARK_STAGE_VALUES;
