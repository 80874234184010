import { ValueType } from '@amzn/claritygqllambda';

export type FormattableNumbers = Extract<
  ValueType,
  ValueType.Percentage | ValueType.Integer | ValueType.Float
>;
function NumberFormatters({ language }: { language: string }) {
  const formatters: Record<FormattableNumbers, Intl.NumberFormat> = {
    [ValueType.Percentage]: new Intl.NumberFormat(language, {
      style: 'percent',
      minimumFractionDigits: 2,
    }),
    [ValueType.Float]: new Intl.NumberFormat(language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    [ValueType.Integer]: new Intl.NumberFormat(language, {
      maximumFractionDigits: 0,
    }),
  };

  function format(type: FormattableNumbers, value: number) {
    return formatters[type].format(value);
  }

  return { format };
}

export default NumberFormatters;
