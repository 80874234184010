import { css, FlattenSimpleInterpolation } from 'styled-components';

/* !!! Important: If possible use breakpoints from breakpoinsStencil file (for consistency) !!! */

export const zoom400Query = 'screen and (max-width: 320px)';
export const mobileQuery = 'screen and (max-width: 480px)';
export const zoom300Query = 'screen and (max-width: 600px)';
export const tabletQuery = 'screen and (max-width: 1280px)';
export const laptopQuery = 'screen and (max-width: 1024px)';
export const desktopQuery = 'screen and (min-width: 1025px)';

export type FlattenStyles = (
  styles: TemplateStringsArray,
) => FlattenSimpleInterpolation;
interface MediaQueries {
  zoom400: FlattenStyles;
  mobile: FlattenStyles;
  zoom300: FlattenStyles;
  tablet: FlattenStyles;
  laptop: FlattenStyles;
  desktop: FlattenStyles;
}
const media: MediaQueries = {
  zoom400: (styles) => css`
    @media ${zoom400Query} {
      ${css(styles)};
    }
  `,
  mobile: (styles) => css`
    @media ${mobileQuery} {
      ${css(styles)};
    }
  `,
  zoom300: (styles) => css`
    @media ${zoom300Query} {
      ${css(styles)};
    }
  `,
  tablet: (styles) => css`
    @media ${tabletQuery} {
      ${css(styles)};
    }
  `,
  laptop: (styles) => css`
    @media ${laptopQuery} {
      ${css(styles)};
    }
  `,
  desktop: (styles) => css`
    @media ${desktopQuery} {
      ${css(styles)};
    }
  `,
};

export default media;
