import { useUserState } from '@clarity-website/common/UserProvider';
import ClarityClient from '@clarity-website/lib/clients/ClarityClient';
import {
  ActorType,
  GET_USER_GROUPS,
  GetUserGroupsInterface,
} from '@clarity-website/pages/search-reports/queries/user-permissions-queries';
import { oneDayInMs } from '@clarity-website/utils/cache-time-constants';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';

export const userGroupsQuery = 'userGroupsQuery';

const getUserGroups = async () => {
  return new ClarityClient().gql({
    request: GET_USER_GROUPS,
  });
};

export function useGetUserMemberships(): QueryObserverResult<
  GetUserGroupsInterface,
  ReadonlyArray<Error>
> {
  const { user: { login: userId = '' } = {} } = useUserState();
  return useQuery<GetUserGroupsInterface, ReadonlyArray<Error>>(
    [userGroupsQuery],
    () =>
      getUserGroups().then((res: GetUserGroupsInterface) => {
        if (res.getUserGroups) {
          return {
            ...res,
            getUserGroups: [
              ...res.getUserGroups,
              { actor: userId, type: ActorType.ALIAS },
            ],
          };
        }
        return res;
      }),
    { enabled: !!userId, staleTime: oneDayInMs },
  );
}
