import { WidgetMenuOptions } from '@clarity-widgets/components/metric/MetricWidget';
import { useCallback, useState } from 'react';

export enum WidgetView {
  chart = 'chart',
  table = 'table',
  chartAndTable = 'chart/table',
}

export default function useWidgetView(initialView: WidgetMenuOptions) {
  const [widgetView, _setWidgetView] = useState<WidgetMenuOptions>(initialView);

  const setWidgetView = useCallback(_setWidgetView, [_setWidgetView]);

  return { widgetView, setWidgetView };
}
