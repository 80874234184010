import { Workbox } from 'workbox-window';
// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

export function register() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    if (isLocalhost) {
      console.info('localhost');
      // This is running on localhost. Let's check if a service worker still exists or not.
      checkValidServiceWorker();

      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.
      navigator.serviceWorker.ready.then(() => {
        console.log(
          'This web app is being served cache-first by a service ' +
            'worker. To learn more, visit https://cra.link/PWA',
        );
      });
    } else {
      console.info('registering valid sw', swUrl);
      // Is not localhost. Just register service worker
      registerValidSW();
    }
  }
}

let refreshing = false;
const reloadPage = () => {
  if (refreshing) return;
  refreshing = true;
  window.location.reload();
};

const getRegistration = () => navigator.serviceWorker.ready;

export async function checkForSWUpdates() {
  try {
    if (!('serviceWorker' in navigator)) return;
    const registration = await getRegistration();
    await registration.update();
  } catch (error) {
    console.error('Update check failed:', error);
  }
}

async function registerValidSW() {
  const wb = new Workbox(swUrl);

  wb.addEventListener('waiting', () => {
    console.log('Service worker waiting to activate.');
    wb.messageSkipWaiting();
  });

  wb.addEventListener('installing', (e) => {
    if (e.isUpdate) {
      console.log('Service worker update found.');
    }
    console.log('Installing service-worker.js');
    wb.messageSkipWaiting();
  });

  wb.addEventListener('controlling', () => {
    console.log(
      'Service worker installed and controlling the app. Reloading page.',
    );
    reloadPage();
  });

  // other brwoser tab has taken over
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('Another tab has taken control of Clarity App. Reloading tab.');
    reloadPage();
  });

  document.addEventListener('visibilitychange', async () => {
    if (document.visibilityState === 'visible') {
      await checkForSWUpdates();
    }
  });

  wb.register().then(
    (registration) => {
      registration?.update();
    },
    (err) => {
      console.error('Service worker registration failed:', err);
    },
  );

  // Set up periodic update checks
  setInterval(() => {
    checkForSWUpdates();
  }, 60 * 60 * 1000); // Check every hour
}

function checkValidServiceWorker() {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW();
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.',
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
