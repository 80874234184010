import { PeriodType, TimespanComparison } from '@amzn/claritygqllambda';
import { DomainConfig } from '@amzn/pi-clarity-common/config/domain-configs';
import { generateSpecificDatesSelectionConfigs } from '@amzn/pi-clarity-common/timespan/constants';
import {
  DurationType,
  TemporalAdjuster,
  TimespanType,
  extractIdFromTimespan,
} from '@amzn/pi-clarity-common/timespan/timespan-config';
import {
  SpecificTimespanData,
  TimeUnit,
} from '@amzn/pi-clarity-common/timespan/types';
import {
  appendPopComparisonToLabel,
  extractPoPComparisonFromLabel,
} from '@clarity-website/reports/edit/timespan/commonUtils';
import { rebuildComparisons } from '@clarity-website/reports/edit/timespan/rollingDateUtils';
import { TimespanInterface } from '@clarity-website/reports/filters/filter-types';
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  differenceInYears,
  format,
  getISOWeek,
  isAfter,
  isBefore,
  startOfYear,
  subYears,
} from 'date-fns';
import parse from 'date-fns/parse';

export const timespanIdToSpecificDate = (
  timespanId: string,
): SpecificTimespanData => {
  const [
    ,
    ,
    ,
    durationTypeString,
    duration,
    temporalAdjusterKey,
    timespanComparisons,
  ] = timespanId.split(':');
  const unixStartTime = temporalAdjusterKey.match(/\d{10,}/gm) || []; // Extract epoch
  const start = new Date(
    new Date(parseInt(unixStartTime[0]!) * 1000).toLocaleString('en-US', {
      timeZone: 'UTC',
    }),
  );
  const parsedDuration = parseInt(duration) - 1;

  let timeUnit = TimeUnit.Days;
  let end: Date | undefined = undefined;
  const durationType = durationTypeString as DurationType;
  const comparisons = rebuildComparisons(timespanComparisons) ?? [];

  if (durationType === DurationType.Day) {
    timeUnit = TimeUnit.Days;
    end = addDays(start, parsedDuration);
  } else if (durationType === DurationType.Week) {
    timeUnit = TimeUnit.Weeks;
    end = addWeeks(start, parsedDuration);
  } else if (durationType === DurationType.Month) {
    timeUnit = TimeUnit.Months;
    end = addMonths(start, parsedDuration);
  } else if (durationType === DurationType.Quarter) {
    timeUnit = TimeUnit.Quarters;
    end = addQuarters(start, parsedDuration);
  } else if (durationType === DurationType.Year) {
    timeUnit = TimeUnit.Years;
    end = start;
  }

  return { timeUnit, start, end, comparisons };
};

const regExpByTimeUnit: { [key in TimeUnit]?: RegExp } = {
  [TimeUnit.Days]: /^\d{2}-\d{2}-\d{4}/, // MM-DD-YYYY
  [TimeUnit.Weeks]: /^\d{4}-Wk-\d{1,2}/, // YYYY-Wk-##
  [TimeUnit.Months]: /^\d{4}-\d{2}/, // YYYY-MM
  [TimeUnit.Quarters]: /^\d{4}-Q\d/, // YYYY-Q#
  [TimeUnit.Years]: /^\d{4}/, // YYYY
};

export const timespanLabelToSpecificTimespanData = (
  label: string,
): SpecificTimespanData => {
  let timeUnit = TimeUnit.Days;
  const [labelDates, labelComparisons = ''] = label.split(', ');
  const labelStartAndEnd = labelDates.split(' - ');
  const [labelDateStart, labelDateEnd = ''] = labelStartAndEnd;

  Object.entries(regExpByTimeUnit).some(([keyTimeUnit, regExp]) => {
    if (regExp.test(labelDateStart)) {
      timeUnit = keyTimeUnit as TimeUnit;
      return true;
    }
    return false;
  });

  const start = formattedDateToDate(labelDateStart, timeUnit);
  const end = formattedDateToDate(labelDateEnd, timeUnit) ?? start;
  const comparisons = extractPoPComparisonFromLabel(labelComparisons);
  return { timeUnit, start, end, comparisons };
};

const formatStringByTimeUnit: { [key in TimeUnit]?: string } = {
  [TimeUnit.Days]: 'MM-dd-yyyy', // MM-DD-YYYY
  [TimeUnit.Weeks]: "R-'Wk'-I", // YYYY-Wk-##
  [TimeUnit.Months]: 'yyyy-MM', // YYYY-MM
  [TimeUnit.Quarters]: 'yyyy-QQQ', // YYYY-Q#
  [TimeUnit.Years]: 'yyyy', // YYYY
};
export const formattedDateToDate = (
  formattedDate: string,
  timeUnit: TimeUnit,
): Date | undefined => {
  const formatString = formatStringByTimeUnit[timeUnit];
  const date = parse(formattedDate, formatString ?? '', new Date());
  return date?.getTime() ? date : undefined;
};

const buildNameForDayRange = (start: Date, duration: number): string => {
  if (duration <= 1) {
    return format(start, 'MM-dd-yyyy');
  }
  const end = addDays(start, duration - 1);
  return `${format(start, 'MM-dd-yyyy')} - ${format(end, 'MM-dd-yyyy')}`;
};

const buildNameForWeekRange = (start: Date, duration: number): string => {
  const startThursday = addDays(start, 4);
  if (duration <= 1) {
    return `${format(start, 'yyyy')}-Wk-${getISOWeek(startThursday)}`;
  }
  const end = addWeeks(start, duration - 1);
  const endThursday = addDays(end, 4);
  return `${format(start, 'yyyy')}-Wk-${getISOWeek(startThursday)} - ${format(
    end,
    'yyyy',
  )}-Wk-${getISOWeek(endThursday)}`;
};

const buildNameForMonthRange = (start: Date, duration: number): string => {
  if (duration <= 1) {
    return format(start, 'yyyy-MM');
  }
  const end = addMonths(start, duration - 1);
  return `${format(start, 'yyyy-MM')} - ${format(end, 'yyyy-MM')}`;
};

const buildNameForQuarterRange = (start: Date, duration: number): string => {
  if (duration <= 1) {
    return format(start, "yyyy-'Q'Q");
  }
  const end = addQuarters(start, duration - 1);
  return `${format(start, "yyyy-'Q'Q")} - ${format(end, "yyyy-'Q'Q")}`;
};

const buildNameForYearRange = (start: Date, duration: number): string => {
  if (duration <= 1) {
    return format(start, 'yyyy');
  }
  const end = addYears(start, duration - 1);
  return `${format(start, 'yyyy')} - ${format(end, 'yyyy')}`;
};

export const buildNameForSpecificDateRange = (
  start: Date,
  duration: number,
  type: TimeUnit,
): string => {
  if (type === TimeUnit.Days) {
    return buildNameForDayRange(start, duration);
  }
  if (type === TimeUnit.Weeks) {
    return buildNameForWeekRange(start, duration);
  }
  if (type === TimeUnit.Months) {
    return buildNameForMonthRange(start, duration);
  }
  if (type === TimeUnit.Quarters) {
    return buildNameForQuarterRange(start, duration);
  }
  return buildNameForYearRange(start, duration);
};

export const buildNameForSpecificDate = (
  date: Date,
  type: TimeUnit,
): string => {
  const duration = 1;
  if (type === TimeUnit.Days) {
    return buildNameForDayRange(date, duration);
  }
  if (type === TimeUnit.Weeks) {
    return buildNameForWeekRange(date, duration);
  }
  if (type === TimeUnit.Months) {
    return buildNameForMonthRange(date, duration);
  }
  if (type === TimeUnit.Quarters) {
    return buildNameForQuarterRange(date, duration);
  }
  return buildNameForYearRange(date, duration);
};

export const generateSpecificTimespanSchema = (
  {
    start,
    end,
    type: timeUnit,
  }: {
    start: Date;
    end: Date;
    type: TimeUnit;
  },
  domain: string,
  domainConfig?: DomainConfig,
  comparisons?: TimespanComparison[],
  isSparkchartActive?: boolean,
): TimespanInterface => {
  const dateDiffMap: {
    [key: string]: (
      dateLeft: Date | number,
      dateRight: Date | number,
    ) => number;
  } = {
    Days: differenceInDays,
    Weeks: differenceInWeeks,
    Months: differenceInMonths,
    Quarters: differenceInQuarters,
    Years: differenceInYears,
  };
  const value = dateDiffMap[timeUnit]
    ? dateDiffMap[timeUnit](end, start) + 1
    : 1;
  const argument = Date.UTC(
    start.getFullYear(),
    start.getMonth(),
    start.getDate(),
  );
  const epoch = argument / 1000;

  const timespan = generateSpecificDatesSelectionConfigs(
    domain,
    domainConfig,
  )?.[timeUnit]?.timespanSchemaFn(value, epoch, 'UTC', comparisons);

  if (!timespan) {
    throw new Error(
      `Specific timespan is undefined timeUnit:${timeUnit} argument:${argument.toString()} value:${value} comparisons:${comparisons}`,
    );
  }
  const id = extractIdFromTimespan(timespan);
  const name = buildNameForSpecificDateRange(start, value, timeUnit);
  const nameWithComparisons = appendPopComparisonToLabel(name, comparisons);

  return {
    ...timespan,
    id,
    name: nameWithComparisons,
    domains: [domain],
    isSparkchartActive: !!isSparkchartActive,
  };
};

export const buildSpecificTimespanFromId = (
  timespanId: string,
  domain: string,
): TimespanInterface => {
  const [
    type,
    expression,
    periodType,
    durationType,
    duration,
    temporalAdjusterKey,
  ] = timespanId.split(':');
  const parent = `${durationType}s`;
  const unixStartTime = temporalAdjusterKey.match(/\d{10,}/gm) || [];
  const start = new Date(
    new Date(parseInt(unixStartTime[0]!) * 1000).toLocaleString('en-US', {
      timeZone: 'UTC',
    }),
  );
  const parsedDuration = parseInt(duration);

  const name = buildNameForSpecificDateRange(
    start,
    parsedDuration,
    parent as TimeUnit,
  );

  const rawTemporalAdjusters = temporalAdjusterKey.split('#');
  const temporalAdjusters = rawTemporalAdjusters.reduce(
    (acc: TemporalAdjuster[], next) => {
      const [adjusterFunction, ...rest] = next.split('^');
      return [
        ...acc,
        {
          function: adjusterFunction,
          arguments: rest[0] ? rest[0].split(',') : [],
        } as TemporalAdjuster,
      ];
    },
    [],
  );

  return {
    id: timespanId,
    name,
    type: type as TimespanType,
    expression,
    periodType: periodType as PeriodType,
    durationType: durationType as DurationType,
    duration: parsedDuration,
    temporalAdjusters,
    domains: [domain],
  };
};

export const isDisabledForStart = (date: Date, endDate?: Date) => {
  let disabled = false;
  const twoYearsAgo = subYears(startOfYear(new Date()), 2);
  if (endDate) {
    disabled = isAfter(date, endDate);
  }
  return disabled || isBefore(new Date(), date) || isAfter(twoYearsAgo, date);
};

export const isDisabledForEnd = (date: Date, startDate?: Date) => {
  let disabled = false;
  const twoYearsAgo = subYears(startOfYear(new Date()), 2);
  if (startDate) {
    disabled = isBefore(date, startDate);
  }
  return disabled || isBefore(new Date(), date) || isAfter(twoYearsAgo, date);
};

export const getDayRangeText = (selectedValue: {
  start: Date | undefined;
  end: Date | undefined;
}) => {
  if (selectedValue.start && selectedValue.end) {
    return `Selected ${
      differenceInDays(selectedValue.end, selectedValue.start) + 1
    } days`;
  } else if (selectedValue.start || selectedValue.end) {
    return 'Selected 1 day';
  }
};

export const getWeekRangeText = (selectedValue: {
  start: Date | undefined;
  end: Date | undefined;
}) => {
  if (selectedValue.start && selectedValue.end) {
    return `Selected ${
      differenceInWeeks(selectedValue.end, selectedValue.start) + 1
    } weeks`;
  } else if (selectedValue.start || selectedValue.end) {
    return 'Selected 1 week';
  }
};

export const getMonthRangeText = (selectedValue: {
  start: Date | undefined;
  end: Date | undefined;
}) => {
  if (selectedValue.start && selectedValue.end) {
    return `Selected ${
      differenceInMonths(selectedValue.end, selectedValue.start) + 1
    } months`;
  } else if (selectedValue.start || selectedValue.end) {
    return 'Selected 1 month';
  }
};

export const getQuarterRangeText = (selectedValue: {
  start: Date | undefined;
  end: Date | undefined;
}) => {
  if (selectedValue.start && selectedValue.end) {
    return `Selected ${
      differenceInQuarters(selectedValue.end, selectedValue.start) + 1
    } quarters`;
  } else if (selectedValue.start || selectedValue.end) {
    return 'Selected 1 quarter';
  }
};

export const getRangeText = (
  selected: TimeUnit,
  selectedValue: {
    start: Date | undefined;
    end: Date | undefined;
  },
) => {
  if (selected === TimeUnit.Days) {
    return getDayRangeText(selectedValue);
  }
  if (selected === TimeUnit.Weeks) {
    return getWeekRangeText(selectedValue);
  }
  if (selected === TimeUnit.Months) {
    return getMonthRangeText(selectedValue);
  }
  return selected === TimeUnit.Quarters
    ? getQuarterRangeText(selectedValue)
    : selectedValue.start && 'Selected 1 year';
};
