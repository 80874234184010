import { MessageBannerLink } from '@amzn/stencil-react-components/message-banner';
import { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

export default function ReactMarkdownParser({ content }: { content: string }) {
  return (
    <ReactMarkdown
      plugins={[gfm]}
      renderers={{
        link: ({ href, children }: { href: string; children: ReactNode }) => (
          <MessageBannerLink href={href} target="_blank" rel="noreferrer">
            {children}
          </MessageBannerLink>
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );
}
