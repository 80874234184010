import { mobileQuery, tabletQuery } from '@clarity-website/utils/breakpoints';
import { ReactChildren } from '@clarity-website/utils/utility-types';
import {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

interface MediaQueryResult {
  tablet?: boolean;
  mobile?: boolean;
}

const MediaQueryContext = createContext<MediaQueryResult | null>(null);

const tabletWatcher = window.matchMedia(tabletQuery);
const mobileWatcher = window.matchMedia(mobileQuery);

const getValue = (): MediaQueryResult => {
  return {
    tablet: tabletWatcher.matches,
    mobile: mobileWatcher.matches,
  };
};

interface Props {
  children: ReactChildren;
}

// Todo: Migrate this to use the react-media library
function MediaQueryProvider({ children }: Props): ReactElement {
  const [value, setValue] = useState<MediaQueryResult>(getValue);
  useEffect((): (() => void) => {
    const mediaChangeHandler = (): void => setValue(getValue);
    tabletWatcher.addListener(mediaChangeHandler);
    mobileWatcher.addListener(mediaChangeHandler);
    return (): void => {
      tabletWatcher.removeListener(mediaChangeHandler);
      mobileWatcher.removeListener(mediaChangeHandler);
    };
  }, []);

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
}

function useMedia(): MediaQueryResult {
  const context = useContext(MediaQueryContext);
  if (!context) {
    throw new Error('useMedia must be used within a MediaQueryProvider.');
  }
  return context;
}

export { MediaQueryProvider, useMedia };
