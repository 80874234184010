import { atomWithStorage, createJSONStorage } from 'jotai/utils';

export interface ViewAsState {
  targetUser: string; // The userId of the user we are viewing as
}

export const VIEW_AS_SESSION_STORAGE_KEY = 'view-as-state';
export const VIEW_AS_HEADER = 'x-view-as-user-id';

export const viewAsStateAtom = atomWithStorage<ViewAsState | null>(
  VIEW_AS_SESSION_STORAGE_KEY,
  null,
  createJSONStorage(() => sessionStorage),
);

function getStateFromStorage(): ViewAsState | null {
  const raw = sessionStorage.getItem(VIEW_AS_SESSION_STORAGE_KEY);
  return raw ? JSON.parse(raw) : null;
}

// Gets headers for View As
// Cannot use hooks or react context - not always called from react context
export function getViewAsHeaders(): Record<string, string> {
  const viewAsState = getStateFromStorage();
  if (!viewAsState) {
    return {};
  }

  return {
    [VIEW_AS_HEADER]: viewAsState.targetUser,
  };
}
