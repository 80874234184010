export const PRIMARY_LEADER_LOGIN_ATTRIBUTE_NAME = 'Primary leader login';
export const PRIMARY_LEADERS_DIRECTS_ATTRIBUTE_NAME =
  "Primary leader's directs";

const ODM_LEADER_METRIC_DICTIONARY_PROD: { [key: string]: string } = {
  M_1647367982_73: 'Unregretted attrition %',
  M_1647367982_71: 'Regretted attrition %',
  M_1647367982_67: 'Transfer out %',
};

const ODM_LEADER_METRIC_DICTIONARY_PREPROD: { [key: string]: string } = {
  M_1647367982_73: 'Unregretted attrition %',
  M_1647367982_71: 'Regretted attrition %',
  M_1647367982_67: 'Transfer out %',
};

const ODM_LEADER_METRIC_DICTIONARY_GAMMA: { [key: string]: string } = {
  M_1647367982_73: 'Unregretted attrition %',
  M_1647367982_71: 'Regretted attrition %',
  M_1647367982_67: 'Transfer out %',
};

const ODM_LEADER_METRIC_DICTIONARY_BETA: { [key: string]: string } = {
  M1693430575: 'Unregretted attrition %',
  M_1647367982_71: 'Regretted attrition %',
  M_1647367982_67: 'Transfer out %',
};

const ODM_LEADER_ATTRIBUTE_DICTIONARY_PROD: { [key: string]: string } = {
  A_1647367982_3: PRIMARY_LEADERS_DIRECTS_ATTRIBUTE_NAME,
  A_1647367982_4: PRIMARY_LEADER_LOGIN_ATTRIBUTE_NAME,
  A_1647367982_9: 'Job level name',
  A_1647367982_11: 'Job family name',
  A_1647367982_10: 'Job tech/non tech/sde code',
  A_1647367982_21: 'Tenure group',
  A_1647367982_27: 'Performance overall rating short name',
  A_1647367982_13: 'Geo region name',
  A_1647367982_22: 'Job level dwell time group',
};
const ODM_LEADER_ATTRIBUTE_DICTIONARY_PREPROD: { [key: string]: string } = {
  A_1647367982_3: PRIMARY_LEADERS_DIRECTS_ATTRIBUTE_NAME,
  A_1647367982_4: PRIMARY_LEADER_LOGIN_ATTRIBUTE_NAME,
  A_1647367982_9: 'Job level name',
  A_1647367982_11: 'Job family name',
  A_1647367982_10: 'Job tech/non tech/sde code',
  A_1647367982_21: 'Tenure group',
  A_1647367982_27: 'Performance overall rating short name',
  A_1647367982_13: 'Geo region name',
  A_1647367982_22: 'Job level dwell time group',
};
const ODM_LEADER_ATTRIBUTE_DICTIONARY_GAMMA: { [key: string]: string } = {
  A_1647367982_3: PRIMARY_LEADERS_DIRECTS_ATTRIBUTE_NAME,
  A_1647367982_4: PRIMARY_LEADER_LOGIN_ATTRIBUTE_NAME,
  A_1647367982_9: 'Job level name',
  A_1647367982_11: 'Job family name',
  A_1647367982_10: 'Job tech/non tech/sde code',
  A_1647367982_21: 'Tenure group',
  A_1647367982_27: 'Performance overall rating short name',
  A_1647367982_13: 'Geo region name',
  A_1647367982_22: 'Job level dwell time group',
};
const ODM_LEADER_ATTRIBUTE_DICTIONARY_BETA: { [key: string]: string } = {
  A_1647367982_5: PRIMARY_LEADERS_DIRECTS_ATTRIBUTE_NAME,
  A_1647367982_6: PRIMARY_LEADER_LOGIN_ATTRIBUTE_NAME,
  A_1647367982_11: 'Job level name',
  A_1647367982_13: 'Job family name',
  A_1647367982_12: 'Job tech/non tech/sde code',
  A_1647367982_21: 'Tenure group', // TODO - WHAT IS THIS IN BETA?
  A_1647367982_30: 'Performance overall rating short name', // This is different than in prod
  A_1647367982_15: 'Geo region name',
  A_1647367982_24: 'Job level dwell time group',
};

const LEADER_ODM_STAGE_VALUES = {
  ODM_LEADER_METRIC_DICTIONARY_PROD,
  ODM_LEADER_METRIC_DICTIONARY_PREPROD,
  ODM_LEADER_METRIC_DICTIONARY_GAMMA,
  ODM_LEADER_METRIC_DICTIONARY_BETA,
  ODM_LEADER_ATTRIBUTE_DICTIONARY_PROD,
  ODM_LEADER_ATTRIBUTE_DICTIONARY_PREPROD,
  ODM_LEADER_ATTRIBUTE_DICTIONARY_GAMMA,
  ODM_LEADER_ATTRIBUTE_DICTIONARY_BETA,
};

export default LEADER_ODM_STAGE_VALUES;
