import { DisplayStyleType, ValueType } from '@amzn/claritygqllambda';
import {
  ColumnMetadataInterface,
  MetricStatus,
  ReportFilter,
  ReportTemplateInterface,
  RowInterface,
} from '@clarity-website/reports/report-types';

export enum WidgetType {
  Attendance_Single = 'Attendance_Single',
  On_Premise_Single = 'On_Premise_Single',
  Headcount_Single = 'Headcount_Single',
  Attrition_Single = 'Attrition_Single',
  Engage_Single = 'Engage_Single',
  Attendance_Multi = 'Attendance_Multi',
  On_Premise_Multi = 'On_Premise_Multi',
  Headcount_Multi = 'Headcount_Multi',
  Attrition_Multi = 'Attrition_Multi',
  Engage_Multi = 'Engage_Multi',
  Attendance_Business_Line_Single = 'Attendance_Business_Line_Single',
  On_Premise_Business_Line_Single = 'On_Premise_Business_Line_Single',
  Headcount_Business_Line_Single = 'Headcount_Business_Line_Single',
  Attrition_Business_Line_Single = 'Attrition_Business_Line_Single',
  Engage_Business_Line_Single = 'Engage_Business_Line_Single',
}

export const WidgetMetricDictionary: Map<WidgetType | undefined, string> =
  new Map([
    [WidgetType.Attendance_Single, 'attendance'],
    [WidgetType.On_Premise_Single, 'on-premise'],
    [WidgetType.Headcount_Single, 'headcount'],
    [WidgetType.Attrition_Single, 'attrition'],
    [WidgetType.Engage_Single, 'engage'],
    [WidgetType.Attendance_Multi, 'attendance'],
    [WidgetType.On_Premise_Multi, 'on-premise'],
    [WidgetType.Headcount_Multi, 'headcount'],
    [WidgetType.Attrition_Multi, 'attrition'],
    [WidgetType.Engage_Multi, 'engage'],
    [WidgetType.Attendance_Business_Line_Single, 'attendance'],
    [WidgetType.On_Premise_Business_Line_Single, 'on-premise'],
    [WidgetType.Headcount_Business_Line_Single, 'headcount'],
    [WidgetType.Attrition_Business_Line_Single, 'attrition'],
    [WidgetType.Engage_Business_Line_Single, 'engage'],
  ]);

export const WidgetTypeGroupNamePrefix: { [widgetType: string]: string } = {
  [WidgetType.Attendance_Single]: 'Attd - ',
  [WidgetType.On_Premise_Single]: 'OnPrem - ',
  [WidgetType.Headcount_Single]: 'HC - ',
  [WidgetType.Attrition_Single]: 'Attr - ',
  [WidgetType.Engage_Single]: 'Eng - ',
  [WidgetType.Attendance_Multi]: 'Attd - ',
  [WidgetType.On_Premise_Multi]: 'OnPrem - ',
  [WidgetType.Headcount_Multi]: 'HC - ',
  [WidgetType.Attrition_Multi]: 'Attr - ',
  [WidgetType.Engage_Multi]: 'Eng - ',
  [WidgetType.Attendance_Business_Line_Single]: 'Attd - ',
  [WidgetType.Attrition_Business_Line_Single]: 'Attr - ',
  [WidgetType.On_Premise_Business_Line_Single]: 'OnPrem - ',
  [WidgetType.Headcount_Business_Line_Single]: 'HC - ',
  [WidgetType.Engage_Business_Line_Single]: 'Eng - ',
};

export const LONGEST_WIDGET_GROUP_NAME_PREFIX: number = (() =>
  Object.values(WidgetTypeGroupNamePrefix).reduce(
    (maxLength, prefix) => Math.max(maxLength, prefix.length),
    Number.NEGATIVE_INFINITY,
  ))();

export interface CellInterface {
  metricId?: string;
  value?: string | null;
  valueType: ValueType;
  precision?: number;
  timespanKey?: string;
}

export interface WidgetTableCellInterface extends CellInterface {
  debugValue?: string | null;
  odm?: boolean;
  isGroupingAttribute?: boolean;
  title?: string;
  key?: string;
}

export enum GroupingAttributes {
  SITE = 'Site',
  METRICS = 'Metrics',
  BUSINESS = 'Business',
  REGION = 'Region',
  COUNTRY = 'Country',
  GEO = 'Geo',
}

export enum FilterEnum {
  SITE = 'A0006',
}

export enum ResultStatus {
  Available = 'Available',
  Pending = 'Pending',
  Error = 'Error',
  SizeError = 'SizeError',
  NoAccessError = 'NoAccessError',
  OrphanedResourcesError = 'OrphanedResourcesError',
  CustomError = 'CustomError',
  RateLimitError = 'RateLimitError',
}

export interface DataInterface {
  id: string;
  link?: string;
  errorMessage?: string | null;
  rows: RowInterface[];
  resultStatus: ResultStatus;
  updatedTime?: number;
  columnMetadata: ColumnMetadataInterface[];
  accessStatus?: AccessStatus;
}

export interface AccessStatus {
  lockedMetrics?: string[];
  hasObfuscatedMetrics: boolean;
  fullAccess: boolean;
}

export interface GetWidgets {
  template: ReportTemplateInterface;
  data: DataInterface;
  metricStatuses: MetricStatus[];
}

export interface GetDataInterface {
  loading: boolean;
  error: Error;
  getWidgets: GetWidgets[];
}

export type WidgetsTuple = [ReportTemplateInterface, DataInterface][];

export type WidgetTable = {
  createdTime: number;
  displayStyle: DisplayStyleType;
  filters: ReportFilter[];
  id: string;
  isActive: boolean;
  name: string;
  updatedTime: number;
  url?: string | null;
  version: number;
  widgetType: WidgetType;
};

export type GoalData = {
  metricId: string;
  value: string;
  displayValue: string;
};
