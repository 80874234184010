import { FilteredAttributesConfig } from '@amzn/pi-clarity-common/config/domain-configs';
import useDomainConfigs from '@clarity-website/config/useDomainConfigs';
import { useMemo } from 'react';

export type LeaderAttribute = {
  domain: string;
  primaryLeaderAttrId: string | undefined;
  primaryLeaderInDirectsAttrId: string | undefined;
  directsAttrId: string | undefined;
  directManagerAttrId: string | undefined;
  simReportsToAttributeIds: string[];
  allLeaderAttributeIds: string[];
};

function generateLeaderAttributes(
  filteredAttributesConfig?: FilteredAttributesConfig,
) {
  const allLeaderAttributeIds = filteredAttributesConfig
    ? Object.values(filteredAttributesConfig).flat()
    : [];

  return {
    primaryLeaderAttrId: filteredAttributesConfig?.primaryLeaderAttrId,
    primaryLeaderInDirectsAttrId:
      filteredAttributesConfig?.primaryLeaderInDirectsAttrId,
    directManagerAttrId:
      filteredAttributesConfig?.directManagerAttrId ||
      filteredAttributesConfig?.directManagerAttrIdGQL,
    directsAttrId: filteredAttributesConfig?.directsAttrId,
    simReportsToAttributeIds:
      filteredAttributesConfig?.simReportsToAttributeIds || new Array<string>(),
    allLeaderAttributeIds,
  };
}

export default function useGetLeaderAttributeIds(domain: string) {
  const { data: domainConfigs } = useDomainConfigs();
  const filteredAttributesConfig = useMemo(
    () => domainConfigs?.filteredAttributes[domain],
    [domain, domainConfigs?.filteredAttributes],
  );

  return useMemo(() => {
    return generateLeaderAttributes(filteredAttributesConfig);
  }, [filteredAttributesConfig]);
}

export function useGetLeaderAttributeIdsForMultiDomains(
  domains: string[],
): LeaderAttribute[] {
  const { data: domainConfigs } = useDomainConfigs();
  return useMemo(
    () =>
      domains.map((domain) => {
        const filteredAttributesConfig =
          domainConfigs?.filteredAttributes[domain];
        return {
          domain,
          ...generateLeaderAttributes(filteredAttributesConfig),
        };
      }),
    [domains, domainConfigs?.filteredAttributes],
  );
}

/**
 * This method retrieves the leader attribute ids of the aggregate domain if the domain is a row level domain
 * Else it's an identity function that returns the domain's leader id
 * @param domain
 */
export function useGetAggregateLeaderAttributeIds(domain: string) {
  const { data: domainConfigs } = useDomainConfigs();
  const aggDomain =
    domainConfigs?.config[domain]?.aggregateDomainMapping || domain;
  return useGetLeaderAttributeIds(aggDomain);
}
