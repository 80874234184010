import stageConfig, { AppConfig } from '@clarity-website/config/stage-config';
import { getOriginUrl } from '@clarity-website/utils/hrsc-general-utils';
import { useQuery } from '@tanstack/react-query';

export const CONFIG_PATH = '/settings.json';

const configKey = 'appConfigKey';

const NODE_ENV = process.env.NODE_ENV;
const IS_TEST_OR_DEV = NODE_ENV === 'test' || NODE_ENV === 'development';

function getStageAndRegion(origin: string): { stage: string; region: string } {
  const stageToRegion: Record<string, string> = {
    beta: 'us-west-2',
    gamma: 'us-east-1',
    preprod: 'us-east-1',
    prod: 'us-east-1',
  };

  let [lowerStages = ''] = origin.match(/(beta|gamma|preprod)/g) || [];

  if (IS_TEST_OR_DEV && process.env.STAGE) {
    lowerStages = process.env.STAGE;
  }

  if (!origin || lowerStages) {
    return {
      stage: lowerStages,
      region: stageToRegion[lowerStages],
    };
  }
  return {
    stage: 'prod',
    region: stageToRegion['prod'],
  };
}

export const getAppConfig = (): AppConfig => {
  // For MFE, look at host applications public path via this variable
  // __webpack_public_path__ Wont be defined in test env
  // https://webpack.js.org/guides/public-path/
  // https://code.amazon.com/packages/PXTAppSolutionDomainMFEPOC/blobs/604f6cdc7ae98c4475ca1cebccb710e552322f1d/--/packages/frontend/src/utils/util-functions.ts#L24
  const { origin, hostname, port } = new URL(getOriginUrl());
  const { stage, region } = getStageAndRegion(origin);
  const config = stageConfig[stage][region];

  if (IS_TEST_OR_DEV) {
    /**
     * In development, this informs Cognito of proper urls & domain
     * for oauth flow.
     **/
    const url = `https://${hostname}${port && `:${port}`}/`;
    config.Auth.oauth.redirectSignIn = url;
    config.Auth.oauth.redirectSignOut = url;
    config.Auth.cookieStorage.domain = hostname;
  }
  return config;
};

export default function useAppConfig() {
  return useQuery([configKey], () => getAppConfig(), {
    staleTime: Infinity,
  });
}
