import { Auth } from '@aws-amplify/auth';
import { Amplify, Hub, Logger } from '@aws-amplify/core';
import {
  UserState,
  currentUserStateAtom,
} from '@clarity-website/common/userProviderAtoms';
import { AppConfig } from '@clarity-website/config/stage-config';
import useAppConfig from '@clarity-website/config/useAppConfig';
import objectStorageService from '@clarity-website/utils/object-storage-service';
import theme from '@clarity-website/utils/theme';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useAtom } from 'jotai';
import { intersection } from 'ramda';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';

const SIGNOUT_LOCATION = 'signoutEventLocation';
export const OBJECT_STORAGE_PREFIX = 'authenticate';

export const saveSignOutLocation = () => {
  console.info(
    `Signout event occurred. Saving location ${window.location.href}`,
  );
  objectStorageService(OBJECT_STORAGE_PREFIX).setObject(SIGNOUT_LOCATION, {
    location: window.location.href,
    epoch: new Date().getTime(),
  });
};

const getPreviousLocation = () => {
  const currentUrl = new URL(window.location.toString());
  const state = currentUrl.searchParams.get('state') || '';
  // format of custom state is [amazon-state]-[user-state-in-hex]
  const userState = state.split('-')[1];
  if (userState) {
    const previousUrl = Buffer.from(userState, 'hex').toString().trim();
    const fullUrl = previousUrl ? new URL(previousUrl) : null;
    if (fullUrl) {
      return `${fullUrl.pathname}${fullUrl.hash}${fullUrl.search}`;
    }
  }
};

const getLastKnownSignOutLocation = ():
  | { location: string; epoch: number }
  | null
  | undefined =>
  objectStorageService(OBJECT_STORAGE_PREFIX).getObject(SIGNOUT_LOCATION);

/**
 * Amplify emits a sign out event under certain errors that are returned by Cognito.
 * Any signout event that is emitted causes a browser redirect that lands the user back at the home page.
 *
 * Because Clarity does not have an explicit sign out action for the user, this method takes a heuristic approach to determine whether
 * a redirect was caused by the Amplify SDK. The heuristic is based a suitable timegap between the signout event and when the reauth occurs.
 * If it's set too high, then the user might accidentally be redirected to the signout location if the user hard refreshes the page.
 *
 *
 * See @Amplify.Auth.cleanUpInvalidSession() for details of exceptions and redirect
 * @param lastSignoutEpoch
 */
const hasRecentlyEncounteredSignOutEvent = (lastSignoutEpoch: number) => {
  const maxMsGap = 15000; // 15 seconds in ms, chosen based off some redirect begin/end times that occurred on a few engineers machines
  const msDiff = new Date().getTime() - lastSignoutEpoch;
  return msDiff <= maxMsGap;
};

interface UserProviderInterface {
  children: React.ReactNode;
}

function UserProvider({ children }: UserProviderInterface): ReactElement {
  const history = useHistory();
  const [state, setState] = useAtom(currentUserStateAtom);
  const { data: config } = useAppConfig();

  const { loading } = state || {};
  const [previousPath, setPreviousPath] = useState('');

  useEffect(() => {
    const previousURL = getPreviousLocation();
    if (previousURL) {
      setPreviousPath(previousURL);
    }
  }, []);

  const setSession = useCallback(
    (session: CognitoUserSession, config: AppConfig): void => {
      const payload = session.getIdToken().decodePayload();
      const groups = JSON.parse(payload['custom:groups']);
      setState({
        loading: false,
        stage: config.Stage,
        user: {
          firstName: payload.given_name,
          lastName: payload.family_name,
          firstLoginDate: new Date(Number(payload.identities[0].dateCreated)),
          // employeeId: payload.employee_number, // TODO add this when we have cognito integration figured out
          login: payload.identities[0].userId,
          country: payload['custom:country'],
          site: payload['custom:site'],
          jobTitle: payload['custom:job_title'],
          jobLevel: payload['custom:job_level'],
          groups,
          adGroups: payload['custom:ad_groups'],
          posixGroups: payload['custom:posix_groups'],
          hasMaintenanceUserAccess:
            intersection(groups, config.MaintenancePortal.userGroup).length > 0,
          hasViewAsUserAccess:
            config.ViewAs.enabled &&
            intersection(groups, config.ViewAs.ldapGroups).length > 0,
        },
      });
    },
    [setState],
  );

  const hasRunEffectRef = useRef(false);
  useEffect((): void => {
    if (!config) return;
    // If listener registers more than 1 time, you'll get multiple sign-in events
    if (!hasRunEffectRef.current) {
      hasRunEffectRef.current = true;
      // This needs to register before 'authenticate' is called
      Hub.listen('auth', (data) => {
        console.info('auth', data.payload, window.location.href);
        switch (data.payload.event) {
          case 'signIn': {
            // Sign In successful, update session within state
            Auth.currentSession()
              .then((session): void => {
                const lastKnownSignOutLocation = getLastKnownSignOutLocation();
                if (
                  lastKnownSignOutLocation &&
                  hasRecentlyEncounteredSignOutEvent(
                    lastKnownSignOutLocation.epoch,
                  )
                ) {
                  const fullUrl = new URL(lastKnownSignOutLocation.location);
                  const lastKnownPath = `${fullUrl.pathname}${fullUrl.hash}${fullUrl.search}`;
                  console.info('signIn lastKnownLocation', lastKnownPath);
                  history.replace(lastKnownPath);
                } else if (previousPath) {
                  console.info('signIn previousPath', previousPath);
                  history.replace(previousPath);
                }
                setSession(session, config);
              })
              .catch((err) => console.log(err));
            break;
          }
          case 'signOut':
          case 'oAuthSignOut': {
            saveSignOutLocation();
            break;
          }
        }
      });

      Logger.LOG_LEVEL = config.logLevel || 'ERROR';
      (async function authenticate(config: AppConfig): Promise<void> {
        try {
          Amplify.configure(config);
          console.info('auth', 'gettingCurrentSession');
          const session = await Auth.currentSession();
          setSession(session, config);
        } catch (e) {
          console.info('auth', 'signing in');
          // No active session, begin sign in unless we are processing callback
          Auth.federatedSignIn({
            customProvider: '',
            customState: window.location.href,
          });
        }
      })(config);
    }
  }, [setState, config, history, setSession, previousPath]);

  return (
    //React actually crashes if we don't wrap children in a fragment as it claims it's an invalid React element.
    //eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loading ? (
        <BarLoader loading color={theme.color.primary60} width="100%" />
      ) : (
        children
      )}
    </>
  );
}

export function useUserState(): UserState {
  const [user] = useAtom(currentUserStateAtom);

  return user;
}

export default UserProvider;
