export const LOCAL_STORAGE_WIDTHS: Record<string, Record<string, number>> = {
  // beta test
  '2f0ac744-e4d1-4cf1-95a7-69c261839dfd': {
    A_1647367982_6: 102,
    A_1647367982_5: 272,
    A1690074705: 24,
    metrics: 56,
    'm_20240201#0': 102,
    'm_20240301#0': 102,
    'm_20240401#0': 102,
    'm_20240501#0': 102,
    'm_20240601#0': 102,
    'm_20240701#0': 102,
    'm_20240801#0': 102,
  },

  // StrengtheningOrgs 9b6567d8-205e-4844-ac69-9786fe241665dl
  '0b7408f8-41f6-4343-973c-2b2ce5592fa9dl': {
    A_1647367982_100: 148,
    metrics: 495,
    'm_20240801#0': 102,
  },
  'ee7de97a-4f29-431f-b921-d0cf63401a03dl': {
    A_1647367982_3: 204,
    A_1647367982_4: 157,
    metrics: 467,
    'm_20240801#0': 102,
  },
  '93f31729-2a87-43e7-8ae6-a59f01855c49dl': {
    A_1647367982_100: 158,
    metrics: 605,
    'm_20231201#0': 116,
    'm_20240601#1': 102,
    'm_20240701#1': 102,
    'm_20240801#1': 85,
    'm_20240801_mom#1': 80,
  },
  '0e26dab3-5fc0-4eee-b579-31d996016365dl': {
    A_1647367982_100: 155,
    A_1647367982_9: 160,
    metrics: 348,
    'm_20240801#0': 104,
    'm_20240801_goal#0': 125,
    'm_20240801_delta#0': 109,
  },
  '948900c2-9731-473c-a288-9eb8d95c2916dl': {
    A_1647367982_100: 156,
    A1723697518: 171,
    A1723225133: 162,
    metrics: 282,
    'm_20231201#0': 126,
    'm_20240801#1': 102,
  },
  'ad892998-fea2-4cdb-94f9-931049d0b7a2dl': {
    A_1647367982_100: 153,
    metrics: 515,
    'm_20240801#0': 121,
    'm_20240801_goal#0': 120,
    'm_20240801_delta#0': 102,
  },
  'aa496a2e-b42a-42d9-b214-1314c2f2b3d3dl': {
    A91020428307: 198,
    timespan: 122,
    A1675481251: 221,
    A1723583972: 221,
    A1723583973: 145,
    A9802823208: 142,
    A91537015448: 172,
    A9762179967: 167,
    A91979039839: 261,
  },
  '2b89bf8f-573c-410b-ae39-4dd22288ed79dl': {
    A_1647367982_3: 202,
    A_1647367982_4: 153,
    metrics: 484,
    'm_20231201#0': 101,
    'm_20240601#1': 102,
    'm_20240701#1': 102,
    'm_20240801#1': 102,
    'm_20240801_mom#1': 80,
  },
  '499356eb-e03c-4042-b4da-76b2f3005df9dl': {
    A_1647367982_3: 204,
    A_1647367982_4: 155,
    metrics: 465,
    'm_20240801#0': 117,
    'm_20240801_goal#0': 119,
    'm_20240801_delta#0': 94,
  },
  '53ae9830-3315-42e5-b6b4-2810ac8389cadl': {
    A91020428307: 193,
    timespan: 122,
    A1675481251: 209,
    A1723583976: 227,
    A9802823208: 158,
    A91537015448: 151,
    A9762179967: 315,
    A91979039839: 318,
  },
  '24841c70-7c19-4f28-b838-aa41b6342099dl': {
    A_1647367982_3: 201,
    A_1647367982_4: 153,
    A_1647367982_9: 170,
    metrics: 315,
    'm_20240801#0': 107,
    'm_20240801_goal#0': 117,
    'm_20240801_delta#0': 97,
  },
  '8665ef11-c301-4352-993e-30b80f7d9733dl': {
    A_1647367982_3: 204,
    A_1647367982_4: 155,
    A1723697518: 166,
    A1723225133: 162,
    metrics: 257,
    'm_20231201#0': 111,
    'm_20240801#1': 102,
  },
  'c2c83653-a1fc-45d3-8770-789f63224e3edl': {
    A91020428307: 194,
    timespan: 122,
    A1675481251: 223,
    A1723583969: 211,
    A9802823208: 146,
    A91537015448: 158,
    A9762179967: 326,
    A91979039839: 277,
  },
  '6038297a-6dde-4e7c-baf3-6078184f631cdl': {
    A91020428307: 193,
    timespan: 122,
    A1675481251: 216,
    A1723583969: 213,
    A9802823208: 154,
    A91537015448: 162,
    A9762179967: 317,
    A91979039839: 296,
  },
  '79a88640-2a8f-490b-9722-33b814640e49dl': {
    A91020428307: 192,
    timespan: 122,
    A1675481251: 209,
    A1723583976: 231,
    A9802823208: 153,
    A91537015448: 151,
    A9762179967: 316,
    A91979039839: 306,
  },
  '6dfafb08-20c0-4fe2-b158-e26ea12f6198dl': {
    A91020428307: 196,
    timespan: 122,
    A1675481251: 222,
    A1723583974: 356,
    A1723583973: 158,
    A9802823208: 165,
    A91537015448: 160,
    A9762179967: 169,
    A91979039839: 102,
  },

  // StrengtheningOrgs All Amazon e5d8553d-0208-495b-bef8-cf278bc3f3c6DF
  '31910169-c880-4303-a86a-7c288f1d3c93DF': {
    metrics: 374,
  },
  '3942c539-979f-4289-981f-038200162117DF': {
    metrics: 374,
  },
  'e5ce8724-fcea-4cb5-9baf-65b2da75af5eDF': {
    metrics: 374,
  },
  'b2009fc0-e936-439c-a409-2e2baf4d0077DF': {
    metrics: 374,
  },
  'f53e789e-28fc-4599-b999-76fcecd102beDF': {
    metrics: 365,
  },
  'ec60731c-bf86-4e99-a3fc-22901041fbffDF': {
    metrics: 467,
  },
};

// templates where the GOAL column name should be renamed to BENCHMARK
export const BENCHMARK_COLUMN_NAME_TEMPLATES: string[] = [
  // beta test, report group id: 80d528a4-a9c4-4c0a-8d62-75fe55a441bc
  'a1f28f84-d308-43ac-becf-5b9baba27931',
  '7ada30dc-7967-484d-9c7f-38ca1f66b367',
  '9955eb78-efda-4ed5-aa26-6b6ec477e148',

  // Strengthening Orgs Standard Report, report Group Id: 9b6567d8-205e-4844-ac69-9786fe241665dl
  'ad892998-fea2-4cdb-94f9-931049d0b7a2dl',
  '24841c70-7c19-4f28-b838-aa41b6342099dl',
  '499356eb-e03c-4042-b4da-76b2f3005df9dl',
  '0e26dab3-5fc0-4eee-b579-31d996016365dl',
];
