import {
  ActionType,
  initialLayoutState,
  layoutReducer,
  LayoutStateInterface,
} from '@clarity-website/layout/layout-reducer';
import {
  createContext,
  Dispatch,
  ReactChild,
  useCallback,
  useContext,
  useReducer,
} from 'react';

const LayoutStateContext = createContext<LayoutStateInterface | null>(null);
const LayoutDispatchContext = createContext<Dispatch<ActionType> | null>(null);

interface LayoutProviderInterface {
  children: ReactChild;
}

function LayoutProvider({ children }: LayoutProviderInterface): any {
  const [layoutState, dispatch] = useReducer(layoutReducer, initialLayoutState);
  const dispatchCallback = useCallback(dispatch, [dispatch]);
  return (
    <LayoutStateContext.Provider value={layoutState}>
      <LayoutDispatchContext.Provider value={dispatchCallback}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

function useLayoutState(): LayoutStateInterface {
  const context = useContext(LayoutStateContext);
  if (!context) {
    throw new Error(
      'useLayoutState must be used within a LayoutStateProvider.',
    );
  }
  return context;
}

function useLayoutDispatch(): Dispatch<ActionType> {
  const context = useContext(LayoutDispatchContext);
  if (!context) {
    throw new Error(
      'useLayoutDispatch must be used within a LayoutDispatchProvider.',
    );
  }
  return context;
}

export { LayoutProvider, useLayoutDispatch, useLayoutState };
