import ClarityClient from '@clarity-website/lib/clients/ClarityClient';
import useClarityClient from '@clarity-website/lib/clients/useClarityClient';
import { USER_ROLES } from '@clarity-website/pages/home/onboarding/onboarding-types';
import { useQuery } from '@tanstack/react-query';
import gql from 'graphql-tag.macro';

const getRolesQuery = gql`
  query getRoles {
    getRoles
  }
`;

export const getRolesQueryKey = 'getRoles';

function useGetQuery(client: ClarityClient, useSuspense: boolean) {
  return useQuery(
    [getRolesQueryKey],
    () =>
      client
        .gql<{ getRoles: string[] }>({ request: getRolesQuery })
        .then(({ getRoles: roles = [] }) => {
          return {
            roles: roles || [],
            isHR: roles.includes(USER_ROLES.HR),
            isLeader: roles.includes(USER_ROLES.Leader),
            isManager: roles.includes(USER_ROLES.Manager),
          };
        }),
    {
      suspense: useSuspense,
    },
  );
}

const defaultRoles = {
  roles: [],
  isHR: false,
  isLeader: false,
  isManager: false,
};

export default function useGetRoles() {
  const client = useClarityClient();
  return useGetQuery(client, false);
}

export function useGetRolesWithSuspense() {
  const client = useClarityClient();
  const { data } = useGetQuery(client, true);
  return data || defaultRoles;
}
