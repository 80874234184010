import { ValueType } from '@amzn/claritygqllambda';
import { METRIC_ON_ROW_COLUMN_ID } from '@clarity-website/react-table-data-handler/common/constants';
import {
  ProcessedRowWithMetadata as ReportRow,
  ReportTableCell,
} from '@clarity-website/react-table-data-handler/common/types';
import { valueTypeCompare } from '@clarity-website/reports/table/ColumnActionsHelpers';
import { getSortingFromLocalStorage } from '@clarity-website/reports/table/tableSettingsPersistence';
import { ReportTemplate } from '@clarity-website/reports/useFetchRawReport';
import {
  ColumnSort,
  Row,
  SortingState,
  Updater,
  isFunction,
} from '@tanstack/react-table';

// overall suffix
export const COMPARATOR_ROW_SUFFIX = '-overall org]';
export const ALL = 'All';

export function getDefaultSorting(
  reportTemplate: ReportTemplate,
  pinnedColumns: string[],
) {
  const sortingStoredLocally = getSortingFromLocalStorage(reportTemplate);
  if (sortingStoredLocally) {
    return sortingStoredLocally;
  }
  /**
    If no sorting stored locally, pinned columns are sorted asc by default
    Do not automatically sort the metrics column
  */
  const sortedColumns = pinnedColumns.reduce<ColumnSort[]>((columnAcc, id) => {
    if (id !== METRIC_ON_ROW_COLUMN_ID) {
      return [
        ...columnAcc,
        {
          id,
          desc: false,
        },
      ];
    }
    return columnAcc;
  }, []);

  return sortedColumns;
}

export function getNewSortingState(
  newState: Updater<SortingState>,
  oldState: SortingState,
  pinnedColumns: string[],
) {
  /*
    Pinned columns can have sorting by multiple columns simultaneously;
    Data columns can have only one column sorted at a time
  */
  const newStateValue = isFunction(newState) ? newState(oldState) : newState;

  /*
    Separating pinned column sorting from data column sorting (last pinned column has sorting behavior as data columns)
  */
  const pinnedColumnsExceptLast = pinnedColumns.slice(0, -1);
  const [pinnedColumnsSort, dataColumnSort] = newStateValue.reduce<
    [SortingState, SortingState]
  >(
    (result, columnSort) => {
      if (pinnedColumnsExceptLast.includes(columnSort.id)) {
        return [[...result[0], columnSort], result[1]];
      }
      return [result[0], [...result[1], columnSort]];
    },
    [[], []],
  );

  /*
    If more than 1 data columns are passed in the sorting state, only the newest one should be preserved
  */
  const newDataColumnSorting =
    dataColumnSort.length < 2
      ? dataColumnSort
      : dataColumnSort.filter(
          (newColumnSort) =>
            !oldState.find(
              (oldColumnSort) => oldColumnSort.id === newColumnSort.id,
            ),
        );

  return [...pinnedColumnsSort, ...newDataColumnSorting];
}

/*
  Sorting functions in react-table assume 'asc' as base sorting direction.
  If the sorting direction is 'desc', it multiplies result of the sorting function by -1.
  For the rows, which sorting do not depend on sorting direction, we need to compensate that behavior.
*/
function getDescAdjuster(row: Row<ReportRow>, columnId: string) {
  const rowCells = row.getAllCells();
  const sortedColumn = rowCells.find(
    (cell) => cell.column.id === columnId,
  )?.column;
  return sortedColumn?.getIsSorted() === 'desc' ? -1 : 1;
}

export const defaultCompareFn = (
  rowA: Row<ReportRow>,
  rowB: Row<ReportRow>,
  columnId: string,
): number => {
  const aIsSubtotal = rowA.original.metadata?.isSubtotalRow;
  const bIsSubtotal = rowB.original.metadata?.isSubtotalRow;

  if (aIsSubtotal !== bIsSubtotal) {
    // Subtotal rows should be at the bottom of the group independent of the sorting direction
    const descAdjuster = getDescAdjuster(rowA, columnId);
    return (aIsSubtotal ? 1 : -1) * descAdjuster;
  }

  const a = rowA.getValue<ReportTableCell>(columnId);
  const b = rowB.getValue<ReportTableCell>(columnId);
  return valueTypeCompare(
    {
      value: a?.value?.toString() || '',
      valueType: a?.valueType || ValueType.String,
    },
    {
      value: b?.value?.toString() || '',
      valueType: b?.valueType || ValueType.String,
    },
  );
};

export const leaderCompareFn = (
  rowA: Row<ReportRow>,
  rowB: Row<ReportRow>,
  columnId: string,
): number => {
  /**
   * below keywords are values/suffixes for some of the cells in leader rows
   * the sorting logic is:
   * 1. subtotal to be surfaced to the top
   * 2. overall comparator rows next
   * 3. regular rows (that dont meet 1, 2) are then alphanumeric sorted
   *
   */
  const aIsSubtotal = rowA.original.metadata?.isSubtotalRow;
  const bIsSubtotal = rowB.original.metadata?.isSubtotalRow;

  if (aIsSubtotal !== bIsSubtotal) {
    // Subtotal rows should be at the top of the report independent of the sorting direction
    const descAdjuster = getDescAdjuster(rowA, columnId);
    return (aIsSubtotal ? -1 : 1) * descAdjuster;
  }

  const a = rowA.getValue<ReportTableCell>(columnId);
  const b = rowB.getValue<ReportTableCell>(columnId);
  const aValue = a?.value?.toString() || '';
  const bValue = b?.value?.toString() || '';

  if (aValue && bValue) {
    // overall comparator records
    if (aValue === ALL && bValue !== ALL) {
      return -1;
    }
    if (bValue === ALL && aValue !== ALL) {
      return 1;
    }
    if (
      aValue.endsWith(COMPARATOR_ROW_SUFFIX) &&
      !bValue.endsWith(COMPARATOR_ROW_SUFFIX)
    ) {
      return -1;
    }
    if (
      bValue.endsWith(COMPARATOR_ROW_SUFFIX) &&
      !aValue.endsWith(COMPARATOR_ROW_SUFFIX)
    ) {
      return 1;
    }
  }

  return valueTypeCompare(
    { value: aValue, valueType: a?.valueType || ValueType.String },
    { value: bValue, valueType: b?.valueType || ValueType.String },
  );
};
