import { Segment, XRay } from '@clarity-website/common/XRay';
import { FetchRequestParams } from '@clarity-website/lib/clients/ClientMethods';

export function trackResponseInSegment(res: Response, segment?: Segment) {
  if (!segment) {
    return;
  }

  // Set end time here in case of code related overhead
  segment.end_time = XRay.getEpochTime();
  segment.http.response = {
    status: res.status,
    content_length: Number(res.headers.get('Content-Length')),
  };
  // Content length is not visible in XRay UI so add content length as metadata also
  segment.metadata['content_length'] = res.headers.get('Content-Length') || '';
  segment.metadata['content_type'] = res.headers.get('Content-Type') || '';
  segment.metadata['request_id'] = res.headers.get('X-Amzn-Requestid') || '';
  segment.metadata['api_gateway_id'] = res.headers.get('X-Amz-Apigw-Id') || '';
}

export function createNetworkSubsegment(params: FetchRequestParams): Segment {
  const [url, query] = params.input.split('?');
  const isRelativeUrl = url.startsWith('/api');

  return XRay.createSubsegment(
    params.traceId || '',
    'Frontend::Fetch',
    '',
    {
      request: {
        url: isRelativeUrl ? window.location.host + url : url,
        method: params.init?.method || 'GET',
      },
    },
    {
      query: query || '',
    },
  );
}
