const enum REGION_CODES {
  APAC = 'APAC',
  EU = 'EU',
  LATAM = 'LATAM',
  MENA = 'MENA',
  NA = 'NA',
  SSA = 'SSA',
}

export const getRegionFromCountry = (country: string) => {
  if (isAPAC(country)) return REGION_CODES.APAC;
  if (isEU(country)) return REGION_CODES.EU;
  if (isLATAM(country)) return REGION_CODES.LATAM;
  if (isMENA(country)) return REGION_CODES.MENA;
  if (isNA(country)) return REGION_CODES.NA;
  if (isSSA(country)) return REGION_CODES.SSA;
  return '';
};

const APACCountryCodes = new Set([
  'IND',
  'JPN',
  'CHN',
  'AUS',
  'SGP',
  'KOR',
  'TWN',
  'HKG',
  'THA',
  'VNM',
  'IDN',
  'MYS',
  'NZL',
  'PHL',
]);
const isAPAC = (country: string): boolean => {
  return APACCountryCodes.has(country);
};

const EUCountryCodes = new Set([
  'FRA',
  'GBR',
  'IRL',
  'ITA',
  'ESP',
  'NLD',
  'POL',
  'PRT',
  'ROU',
  'SVK',
  'SVN',
  'CHE',
  'CZE',
  'HUN',
  'LUX',
  'BEL',
  'DNK',
  'FIN',
  'GRC',
  'MLT',
  'NOR',
  'SWE',
  'EST',
  'LVA',
  'LTU',
]);
const isEU = (country: string): boolean => {
  return EUCountryCodes.has(country);
};

const LATAMCountryCodes = new Set([
  'ARG',
  'BOL',
  'BRA',
  'CHL',
  'COL',
  'CRI',
  'CUB',
  'DOM',
  'ECU',
  'SLV',
  'GTM',
  'GUY',
  'HTI',
  'HND',
  'JAM',
  'NIC',
  'PAN',
  'PRY',
  'PER',
  'PRI',
  'SUR',
  'URY',
  'VEN',
]);
const isLATAM = (country: string): boolean => {
  return LATAMCountryCodes.has(country);
};

const MENACountryCodes = new Set([
  'EGY',
  'ISR',
  'TUR',
  'QAT',
  'SAU',
  'ARE',
  'OMN',
  'KWT',
  'BHR',
  'LBN',
  'JOD',
  'PSE',
  'YEM',
  'SYR',
  'IRQ',
  'IRN',
  'TUN',
  'LBY',
  'EGY',
  'QAT',
  'SAU',
  'ARE',
  'OMN',
  'KWT',
  'BHR',
  'LBN',
  'JOD',
  'PSE',
  'YEM',
  'SYR',
  'IRQ',
  'IRN',
  'TUN',
  'LBY',
]);
const isMENA = (country: string): boolean => {
  return MENACountryCodes.has(country);
};

const NACountryCodes = new Set(['USA', 'CAN', 'MEX']);
const isNA = (country: string): boolean => {
  return NACountryCodes.has(country);
};

const SSACountryCodes = new Set(['ZAF', 'MUS']);
const isSSA = (country: string): boolean => {
  return SSACountryCodes.has(country);
};

// list of legacy domains
// remove domains from this list after they are migrated
// to CMM so metrics are recorded accurately
const clarityManagedDomains = new Set([
  'location',
  'pi_business_review',
  'connections',
  'people_engine_interview_row',
  'people_engine_requisition',
  'people_engine_requisition_row',
  'everybody_row',
  'people_engine_recruiting',
  'peoplesoft_query',
  'people_engine_recruiting_row',
  'people_engine_interview',
  'organization',
  'everybody',
  'location_row',
  'organization_row',
  'talent_acquisition',
  'rto',
  'rto_row',
]);

// if there is a domain present, record if it is managed by Clarity
export const isClarityManagedDomain = (domain?: string) => {
  if (!domain) return;
  return clarityManagedDomains.has(domain);
};
