import {
  DatasourceInfo,
  DisplayStyleType,
  MetricStatusType,
  PeriodType,
  ValueType,
} from '@amzn/claritygqllambda';
import {
  TimespanSchema,
  extractIdFromTimespan,
  timespanIdsToDisplayname,
} from '@amzn/pi-clarity-common/timespan/timespan-config';
import {
  IconAlert,
  IconPadlock,
  IconPadlockFill,
} from '@amzn/stencil-react-components/icons';
import { PERIOD_TYPE_OFFSET } from '@clarity-website/reports/CommonTableMethodsConstants';
import { GROUPING_ATTRIBUTE } from '@clarity-website/reports/report-constants';
import {
  ColumnMetadataInterface,
  MaskValues,
} from '@clarity-website/reports/report-types';
import NumberFormatters from '@clarity-website/utils/number-formatters';
import { isNumber } from '@clarity-website/utils/type-utils';
import { fromUnixTime, lastDayOfMonth } from 'date-fns';
import format from 'date-fns/format';
import keygen from 'uuid/v4';

const PERIOD_TYPE_TO_AS_OF_DATE_FN_MAP: Record<
  PeriodType,
  (datasourceInfo: DatasourceInfo) => number | undefined
> = {
  [PeriodType.Daily]: (datasourceInfo) => datasourceInfo.lastAsOfDateDaily,
  [PeriodType.Intraday]: (datasourceInfo) =>
    datasourceInfo.lastAsOfDateIntraday,
  [PeriodType.Weekly]: (datasourceInfo) => datasourceInfo.lastAsOfDateWeekly,
  [PeriodType.Monthly]: (datasourceInfo) => datasourceInfo.lastAsOfDatedMonthly,
  [PeriodType.Quarterly]: (datasourceInfo) =>
    datasourceInfo.lastAsOfDateQuarterly,
  [PeriodType.Ttm]: (datasourceInfo) => datasourceInfo.lastAsOfDateTtm,
  [PeriodType.T3m]: (datasourceInfo) => datasourceInfo.lastAsOfDateT3m,
  [PeriodType.T6m]: (datasourceInfo) => datasourceInfo.lastAsOfDateT6m,
  [PeriodType.Yearly]: (datasourceInfo) => datasourceInfo.lastAsOfDateYearly,
  [PeriodType.Pm]: (datasourceInfo) => datasourceInfo.lastAsOfDatePm,
  [PeriodType.Py]: (datasourceInfo) => datasourceInfo.lastAsOfDatePy,
  [PeriodType.Pq]: (datasourceInfo) => datasourceInfo.lastAsOfDatePq,
  [PeriodType.Ytd]: (datasourceInfo) => datasourceInfo.lastAsOfDateYearly,
};

const numberFormatters = NumberFormatters({
  language: window.navigator.language || 'en-US',
});
export default function CommonTableMethods() {
  const isValueConvertibleToNumber = (
    value: JSX.Element | string | null | undefined,
    valueType: ValueType,
  ): value is string => {
    if (
      typeof value !== 'string' ||
      valueType === ValueType.String ||
      valueType === ValueType.Missing ||
      !value ||
      value === MaskValues.NA ||
      value === MaskValues.Locked ||
      value === MaskValues.PRIVACY_LOCK
    ) {
      return false;
    }
    return true;
  };
  function formatCellValue(
    value: string | null | undefined,
    valueType: ValueType,
  ): string {
    if (!isValueConvertibleToNumber(value, valueType)) {
      return value || '';
    }
    if (valueType === ValueType.Integer) {
      return numberFormatters.format(valueType, Math.round(parseFloat(value)));
    }
    if (valueType === ValueType.Percentage || valueType === ValueType.Float) {
      return numberFormatters.format(valueType, parseFloat(value));
    }
    if (valueType === ValueType.BasisPoints) {
      const floatValue = parseFloat(value);
      return `${floatValue >= 0 ? '+' : ''}${Math.round(
        floatValue * 10000,
      )} bps`;
    }
    return value;
  }

  const truncateNumber = (n: number) => Number(n.toFixed(2));

  function parseValueFromValueType(
    value: string | null | undefined,
    valueType: ValueType,
  ): number | null {
    if (!isValueConvertibleToNumber(value, valueType)) {
      return null;
    }
    if (typeof value === 'string') {
      if (valueType === ValueType.Percentage) {
        return naNGuard(truncateNumber(parseFloat(value) * 100));
      }
      if (valueType === ValueType.Float) {
        return naNGuard(truncateNumber(parseFloat(value)));
      }
      if (valueType === ValueType.BasisPoints) {
        return naNGuard(Math.round(parseFloat(value) * 10000));
      }
      if (valueType === ValueType.Integer) {
        return naNGuard(Math.round(parseFloat(value)));
      }
    }
    return null;
  }

  const naNGuard = (n: number) => {
    return isNumber(n) ? n : null;
  };

  const getGroupingColumns = (cols: ColumnMetadataInterface[]) =>
    cols.filter((col) => col.group === GROUPING_ATTRIBUTE);

  const getGroupingIndexes = (cols: ColumnMetadataInterface[]) => [
    ...getGroupingColumns(cols).keys(),
  ];

  function formatColumns(
    columns: ColumnMetadataInterface[],
    utcOffsetMin: number,
    displayStyle: DisplayStyleType,
    selectedTimespans: TimespanSchema[],
    domain?: string,
  ): ColumnMetadataInterface[] {
    return columns.map((col) => {
      const { timespanIndex, periodType, timespanKey, title } = col;
      if (timespanIndex !== undefined && timespanIndex !== null) {
        const timespanId = extractIdFromTimespan(
          selectedTimespans[timespanIndex],
        );
        const timespanGroup =
          timespanIdsToDisplayname[timespanId] || timespanId;
        const dateTimeLabel = formatDateTimeLabel(
          utcOffsetMin,
          selectedTimespans[timespanIndex].periodType,
          timespanKey,
          domain,
        );
        return {
          ...col,
          key: keygen(),
          periodType,
          timespanKey,
          timespanGroup,
          group: dateTimeLabel,
          title:
            displayStyle === DisplayStyleType.MetricOnColumn
              ? title
              : dateTimeLabel,
        };
      }
      return { ...col, key: keygen() };
    });
  }

  /**
   * Determine period type based on cell value
   * @param timespan timespan value
   * @returns PeriodType
   */
  function getPeriodTypeByPrefix(timespan: string) {
    const [prefix] = timespan.split('_');
    switch (prefix) {
      case 'm':
        return PeriodType.Monthly;
      case 'i':
        return PeriodType.Intraday;
      case 'w':
        return PeriodType.Weekly;
      case 'd':
        return PeriodType.Daily;
      case 'y':
        return PeriodType.Yearly;
      case 'ytd':
        return PeriodType.Ytd;
      case 'ttm':
        return PeriodType.Ttm;
      case 'q':
        return PeriodType.Quarterly;
      case 'pq':
        return PeriodType.Pq;
      default:
        throw new Error('Not supported');
    }
  }

  function buildDateFromString(year: string, month: string, day = '1') {
    return new Date(
      Number.parseInt(year, 10),
      Number.parseInt(month, 10) - 1,
      Number.parseInt(day, 10),
      0,
      0,
      0,
      0,
    );
  }

  function getFormattedAsOfDate(
    datasourceInfoList: DatasourceInfo[],
    periodType: PeriodType,
  ): string {
    const asOfDateFn = PERIOD_TYPE_TO_AS_OF_DATE_FN_MAP[periodType];
    if (asOfDateFn) {
      const maxAsOfDateFromDatasource = Math.max(
        ...(datasourceInfoList?.map((datasourceInfo) => {
          const lastAsOfDate = asOfDateFn(datasourceInfo);
          return lastAsOfDate !== undefined ? lastAsOfDate : 0;
        }) || []),
      );

      const asOfDate =
        maxAsOfDateFromDatasource > 0
          ? new Date(fromUnixTime(maxAsOfDateFromDatasource))
              .toISOString()
              .split('T')[0]
          : undefined;
      return asOfDate ? ` - ${asOfDate}` : '';
    }
    return '';
  }

  function formatDateTimeLabel(
    utcOffsetMin: number,
    periodType: PeriodType,
    absoluteTimespan: string | null | undefined,
    timespanLabel?: string,
    datasourceInfoList?: DatasourceInfo[],
    domain?: string,
  ): string {
    if (!absoluteTimespan) {
      return absoluteTimespan || '';
    }
    const isCurrentTimespan = timespanLabel?.includes('Current');
    const asOfDateFormattedDate = isCurrentTimespan
      ? getFormattedAsOfDate(datasourceInfoList || [], periodType)
      : '';

    /*
      Intraday time in Clarity is stored as UTC, whereas other timespans are stored with respects to the Site's local timezone
      Requirement is to only format intraday hours with respect to the local offset that the client browser is on.
      Intraday label comes in e.g. like 18:00, 01:00
     */
    if (periodType === PeriodType.Intraday) {
      // i_20200902_22 -> 15:00 (depending on local offset)
      const utcHr = Number.parseInt(
        absoluteTimespan.substring(absoluteTimespan.length - 2),
      );
      const utcMin = 0;
      const date = new Date();
      date.setHours(utcHr);
      date.setMinutes(utcMin - utcOffsetMin);
      return format(date, 'HH:mm');
    }
    if (periodType === PeriodType.Daily) {
      // d_20200901 -> 09/01/2020
      const year = absoluteTimespan.substring(2, 6);
      const month = absoluteTimespan.substring(6, 8);
      const day = absoluteTimespan.substring(8);
      if (domain && ['rto', 'rto_row'].includes(domain)) {
        return `Daily ${year}-${month}-${day}`;
      }
      return `${month}-${day}-${year}`;
    }
    if (periodType === PeriodType.Weekly) {
      // w_20200830_36 -> 2020-Wk-36
      let year = absoluteTimespan.substring(2, 6);
      const month = absoluteTimespan.substring(6, 8);
      const week = absoluteTimespan.substring(
        absoluteTimespan.lastIndexOf('_') + 1,
      );
      const day = absoluteTimespan.substring(8, 10);
      if (domain && ['rto', 'rto_row'].includes(domain)) {
        const weekendDate = new Date(
          parseInt(year),
          parseInt(month) - 1,
          parseInt(day),
        );
        weekendDate.setDate(weekendDate.getDate() + 6);
        return `WkEnd ${format(weekendDate, 'yyyy-MM-dd')}`;
      }
      if (month === '12' && week === '1') {
        year = (parseInt(year) + 1).toString();
      }
      return `${year}-Wk-${week}${asOfDateFormattedDate}`;
    }
    if (periodType === PeriodType.Monthly) {
      // m_20200501 -> 2020-05
      const year = absoluteTimespan.substring(2, 6);
      const month = absoluteTimespan.substring(6, 8);

      return `${year}-${month}${asOfDateFormattedDate}`;
    }
    if (periodType === PeriodType.Quarterly) {
      // q_20190701_3 -> 2019-07-01-2019-09-30
      const year = absoluteTimespan.substring(2, 6);
      const month = absoluteTimespan.substring(6, 8);
      return getQuarterDateLabelTimespan(
        year,
        month,
        asOfDateFormattedDate,
        isCurrentTimespan,
      );
    }
    if (periodType === PeriodType.Yearly) {
      // y_20170101 -> 2017
      const year = absoluteTimespan.substring(2, 6);
      return `${year}-01-01${asOfDateFormattedDate}`;
    }
    if (periodType === PeriodType.Ytd) {
      // ytd_20170701 -> 01/20-07/20
      const year = absoluteTimespan.substring(6, 8);
      const month = absoluteTimespan.substring(8, 10);
      return `01/${year}-${month}/${year}`;
    }
    if (
      periodType === PeriodType.Ttm ||
      periodType === PeriodType.T6m ||
      periodType === PeriodType.T3m
    ) {
      // ttm_20170701 -> 08/19-07/20
      const endYear = absoluteTimespan.substring(4, 8);
      const endMonth = absoluteTimespan.substring(8, 10);
      const endDate = buildDateFromString(endYear, endMonth);
      const startDate = new Date(endDate);
      startDate.setMonth(startDate.getMonth() - PERIOD_TYPE_OFFSET[periodType]);
      return `${format(startDate, 'MM/yy')}-${format(endDate, 'MM/yy')}`;
    }
    if (periodType === PeriodType.Pm) {
      // pm_20170701 -> 2017-07
      const year = absoluteTimespan.substring(3, 7);
      const month = absoluteTimespan.substring(7, 9);
      const date = buildDateFromString(year, month);
      return isCurrentTimespan
        ? `${format(date, 'yyyy-MM-dd')}${asOfDateFormattedDate}`
        : `${format(date, 'yyyy-MM')}`;
    }
    if (periodType === PeriodType.Pq) {
      // current timespan: pq_20190701_3 -> 2019-07-01-2019-09-11 (As of date)
      // non-current timespan: pq_20190701_3 -> 2019-07-01-2019-09-30
      const year = absoluteTimespan.substring(3, 7);
      const month = absoluteTimespan.substring(7, 9);
      return getQuarterDateLabelTimespan(
        year,
        month,
        asOfDateFormattedDate,
        isCurrentTimespan,
      );
    }
    if (periodType === PeriodType.Py) {
      // py_20210301 -> 2020-04-01 - 2021-03-31
      // py_20230301 -> 2022-04-01 - 2023-09-21
      const endYear = parseInt(absoluteTimespan.substring(3, 7), 10);
      const startDate = buildDateFromString(`${endYear - 1}`, '04', '01');

      return `${format(startDate, 'yyyy-MM-dd')}${asOfDateFormattedDate}`;
    }
    return absoluteTimespan;
  }

  function getDisplayComponent(
    value: string | null | undefined,
    valueType: ValueType | undefined,
    defaultValue = '',
  ) {
    if (value === MaskValues.PRIVACY_LOCK) {
      return <IconPadlockFill aria-hidden={true} />;
    }
    if (valueType === ValueType.Lock || value === MaskValues.Locked) {
      return <IconPadlock aria-hidden={true} />;
    }
    if (valueType === ValueType.Missing || value === MetricStatusType.Missing) {
      return <IconAlert aria-hidden={true} />;
    }
    if (valueType === ValueType.String || !valueType) {
      return value || defaultValue;
    }
    const formattedResult = formatCellValue(value, valueType) || defaultValue;
    if (formattedResult.includes('NaN')) {
      return value || defaultValue;
    }
    return formattedResult || defaultValue;
  }

  function getQuarterDateLabelTimespan(
    year: string,
    month: string,
    asOfDateFormattedDate: string,
    isCurrentTimespan?: boolean,
  ) {
    const formattedStartDateOfQuarter = `${year}-${month}-01`;
    const newMonth = parseInt(month) + 2; // End of quarter month will never exceeds 12
    const endDateOfQuarter = lastDayOfMonth(new Date(`${year}-${newMonth}-02`));

    const formattedEndDateOfQuarter = format(endDateOfQuarter, 'yyyy-MM-dd');
    return isCurrentTimespan
      ? `${formattedStartDateOfQuarter}${asOfDateFormattedDate}`
      : `${formattedStartDateOfQuarter} - ${formattedEndDateOfQuarter}`;
  }

  return Object.freeze({
    getDisplayComponent,
    formatCellValue,
    getGroupingIndexes,
    formatColumns,
    formatDateTimeLabel,
    parseValueFromValueType,
    isValueConvertibleToNumber,
    getPeriodTypeByPrefix,
  });
}
